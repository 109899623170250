/* eslint-disable no-template-curly-in-string */

import {
  AddYouTubeVideoInput,
  AnswerDto,
  AssessmentAccessType,
  AssetDto,
  AssetLabelDto,
  CertificateDto,
  CommentDto,
  ConnectLabelsToQuestionsInput,
  ConnectSuppliersToGroupsInput,
  CSRDDocumentDto,
  CSRDImpactRowDto,
  DashboardItemDto,
  DeepModuleDto,
  DeepQuestionnaireDto,
  FolderDto,
  GroupDto,
  ImportMatrixInput,
  InsertMatrixColumnInput,
  InsertOrUpdateMatrixCellInput,
  MatrixAccessType,
  MatrixColumnDto,
  MatrixDataDto,
  MatrixDto,
  ModuleDto,
  NewAnswerInput,
  NewAssetLabelInput,
  NewDashboardItemInput,
  NewMatrixInput,
  NewModuleInput,
  NewQuestionInput,
  NewQuestionLabelInput,
  NewQuestionnaireInput,
  NewRfpInput,
  NewSupplierGroupInput,
  QuestionDto,
  QuestionLabelDto,
  QuestionLabelWithQuestionsDto,
  QuestionnaireDto,
  RfpDto,
  RfpResponseAnalysisStatus,
  RfpResponseDto,
  RfpResponseEvaluationStatus,
  ScoreSettingGroupDto,
  SelectWinnerResponseInput,
  SupplierGroupDto,
  ToggleLabelOnAssetsInput,
  UnformatedQuestionWithLabelsDto,
  UpdateAssetLabelInput,
  UpdateCertificateInput,
  UpdateCSRDImpactRowInput,
  UpdateMatrixColumnInput,
  UpdateMatrixInput,
  UpdateModuleInput,
  UpdateQuestionInput,
  UpdateQuestionLabelInput,
  UpdateQuestionnaireInput,
  UpdateRfpInput,
  UpdateYouTubeVideoInput,
} from "@pulsemarket/api-client";
import {
  AssetType,
  AttributeModelType,
  QuestionImportExportDto,
  QuestionParentModelType,
  ReportPageSettings,
  RFPType,
  TokenType,
} from "@pulsemarket/constants";
import { LocalProductOrService } from "components/CompanyInfo/forms/ProductsAndServices/ProductsAndServices";

import {
  AssetSearchOptions,
  AttachmentMode,
  AttributeFilter,
  AttributeLookupTableGroupDto,
  AttributeSchemaQuery,
  AttributeTypeDto,
  CloneAssetsInput,
  CloneAttachmentsInput,
  CloneRfxParams,
  CombinedInvitationRecipientInput,
  CombinedInvitationTargetDto,
  CommentReferenceType,
  CompanyDetailsDto,
  CompanyDto,
  CompanyFilter,
  CompanyListDto,
  CompanyUpdateDto,
  ContainerType,
  CreateDataRoomInput,
  CreateDataRoomInvitationInput,
  CreateScoreSettingsInput,
  DataRoomDetailsDto,
  DataRoomDto,
  DataRoomInvitationDto,
  DisonnectSuppliersFromGroupsInput,
  Feedback,
  FolderItemDto,
  FolderListDto,
  FolderTreeFilters,
  GroupDetailsDto,
  GroupFilesDto,
  GroupFilesFilters,
  GroupInvitationStatus,
  GroupSearchOptions,
  IncomingRfpDto,
  LoginInput,
  LookupTableDataDto,
  MatrixRowDto,
  MediaItemDto,
  MessageDetailsDto,
  NewAttributeTypeInput,
  NewCertificateInput,
  NewCommentInput,
  NewFolder,
  NewGroup,
  NewImageInput,
  NewPostInput,
  NewRfpResponse,
  NewSupplierContractInput,
  NewSupplierInput,
  NewSupplierProductInput,
  NewUserCompanyRole,
  NotificationsResponse,
  PaginatedDto,
  Pair,
  PassportInvitationDto,
  PassportInvitationFilter,
  PassportItemDto,
  PassportTypeDto,
  PathDto,
  PostDto,
  PostsQueryResult,
  PostWithReferencesDto,
  ProductOrService,
  QuestionFilter,
  QuestionLabelFilter,
  ReactionDetailsDto,
  ReceivedResponseFilters,
  RegistrationInput,
  RfpFilters,
  RfpInvitation,
  RfpInvitationFilters,
  RfpResponseDetailedScores,
  RfpResponseFilters,
  RfpWithWeightsDto,
  Role,
  ScoreAnswerInput,
  ScoreSettingDto,
  StandardizedQuestionnaireDto,
  SupplierContractDto,
  SupplierDto,
  SupplierFilters,
  SupplierGroupFilter,
  SupplierListItemDto,
  SupplierProductDetailsDto,
  SupplierProductDto,
  SupplierProductFilter,
  UpdateAnswerInput,
  UpdateAttributeTypeInput,
  UpdateCommentInput,
  UpdateDataRoomInput,
  UpdateEvaluationTypeInput,
  UpdateGroupInput,
  UpdatePostInput,
  UpdateRfpResponseInput,
  UpdateScoreSettingsInput,
  UpdateSupplierContractInput,
  UpdateSupplierGroupInput,
  UpdateSupplierInput,
  UpdateSupplierProductInput,
  UpdateWeightsInModulesInput,
  UploadAsset,
  User,
  UserCompanyRole,
  UserDto,
  UserPreviewDto,
} from "../model";
import {
  deleteAPI,
  downloadZip,
  fileGET,
  getJSON,
  postJSON,
  putJSON,
  uploadAPI,
} from "./manager";

// RFPs
export async function getRFPs(search?: RfpFilters): Promise<RfpDto[]> {
  const endpoint = `/api/rfp/list`;
  return await getJSON<RfpDto[], RfpFilters>(endpoint, "/api/rfp/list", search);
}

export async function getIncomingRfps(
  filters?: RfpResponseFilters
): Promise<IncomingRfpDto[]> {
  const endpoint = `/api/rfp/incoming`;

  return await getJSON<IncomingRfpDto[], RfpResponseFilters>(
    endpoint,
    "/api/rfp/incoming",
    filters
  );
}

export async function getInvitationsByRFP(
  rfpId: string,
  filters?: RfpInvitationFilters
): Promise<RfpInvitation[]> {
  const endpoint = `/api/rfp/invitations/rfp/${rfpId}`;

  return await getJSON<RfpInvitation[], RfpInvitationFilters>(
    endpoint,
    "/api/rfp/invitations/rfp/${rfpId}",
    filters
  );
}

export async function getRfpInvitation(
  invitationId: string
): Promise<RfpInvitation> {
  const endpoint = `/api/rfp/invitations/invitation/${invitationId}`;

  return getJSON<RfpInvitation>(
    endpoint,
    "/api/rfp/invitations/invitation/${invitationId}"
  );
}

export async function getRfpInvitationByRfpId(
  rpfId: string
): Promise<RfpInvitation> {
  const endpoint = `/api/rfp/invitations/received/rfp/${rpfId}`;

  return getJSON<RfpInvitation>(
    endpoint,
    "/api/rfp/invitations/received/rfp/${rpfId}"
  );
}

export async function postRFP(
  rfp: NewRfpInput,
  cloneRfxParams?: CloneRfxParams
): Promise<RfpDto> {
  const endpoint = `/api/rfp`;
  return await postJSON(endpoint, "/api/rfp", {
    body: {
      newRfp: rfp,
      cloneRfxParams,
    },
  });
}

export async function getRFP(rfpId: string): Promise<RfpDto> {
  const endpoint = `/api/rfp/${rfpId}`;
  return await getJSON(endpoint, "/api/rfp/${rfpId}");
}

export async function getRfpByInvitationId(
  rfpInvitationId: string
): Promise<{ id: string; name: string; type: RFPType }> {
  const endpoint = `/api/rfp/invitation/${rfpInvitationId}`;
  return await getJSON(endpoint, "/api/rfp/invitation/${rfpInvitationId}");
}

export async function getRFPWeights(rfpId: string): Promise<RfpWithWeightsDto> {
  const endpoint = `/api/rfp/${rfpId}/weights`;
  return await getJSON(endpoint, "/api/rfp/${rfpId}/weights");
}

export async function sendRFP(rfpId: string): Promise<RfpDto> {
  const endpoint = `/api/rfp/${rfpId}/send`;
  return await postJSON(endpoint, "/api/rfp/${rfpId}/send");
}

export async function reviewRfp(rfpId: string): Promise<RfpDto> {
  const endpoint = `/api/rfp/${rfpId}/review`;
  return await postJSON(endpoint, "/api/rfp/${rfpId}/review");
}

export async function editRfp(rfpId: string): Promise<RfpDto> {
  const endpoint = `/api/rfp/${rfpId}/edit`;
  return await postJSON(endpoint, "/api/rfp/${rfpId}/edit");
}

export async function startAnalyticalReview(rfpId: string): Promise<RfpDto> {
  const endpoint = `/api/rfp/${rfpId}/analyticalReview`;
  return await postJSON(endpoint, "/api/rfp/${rfpId}/analyticalReview");
}

export async function markDocumentToBeEvaluated(
  rfpId: string
): Promise<RfpDto> {
  const endpoint = `/api/rfp/${rfpId}/evaluate`;
  return await postJSON(endpoint, "/api/rfp/${rfpId}/evaluate");
}

export async function updateRFP(
  rfpId: string,
  updatedRFP: UpdateRfpInput
): Promise<RfpDto> {
  const endpoint = `/api/rfp/${rfpId}`;
  return await putJSON(endpoint, "/api/rfp/${rfpId}", { rfp: updatedRFP });
}

export async function deleteRFPAttachment(
  assetId: string
): Promise<QuestionDto | QuestionnaireDto | ModuleDto> {
  const endpoint = `/api/rfp/attachment/${assetId}`;
  return await deleteAPI(endpoint, "/api/rfp/attachment/${assetId}");
}

export async function updateWeightsInModules({
  moduleWeights,
  rfpId,
}: UpdateWeightsInModulesInput & {
  rfpId: string;
  companyId: string;
}): Promise<ModuleDto[]> {
  const endpoint = `/api/rfp/${rfpId}/weights`;
  return await putJSON(endpoint, "/api/rfp/${rfpId}/weights", {
    moduleWeights,
  });
}

export async function updateRfpEvaluationType({
  evaluationType,
  rfpId,
}: UpdateEvaluationTypeInput & {
  rfpId: string;
}): Promise<RfpDto> {
  const endpoint = `/api/rfp/${rfpId}/evaluationType`;
  return await putJSON(endpoint, "/api/rfp/${rfpId}/evaluationType", {
    evaluationType,
  });
}

export async function getRfpScoreSettingGroups({
  rfpId,
}: {
  rfpId: string;
}): Promise<ScoreSettingGroupDto[]> {
  const endpoint = `/api/rfp/${rfpId}/score_setting_groups`;
  return await getJSON(endpoint, "/api/rfp/${rfpId}/score_setting_groups");
}

export async function createRfpScoreSettingGroup({
  newScoreSettingsGroup,
}: {
  newScoreSettingsGroup: CreateScoreSettingsInput;
}): Promise<ScoreSettingGroupDto> {
  const endpoint = `/api/score_settings/`;
  return await postJSON(
    endpoint,
    "/api/score_settings/",
    newScoreSettingsGroup
  );
}

export async function editScoreSettingGroup({
  scoreSettingsGroupId: id,
  scoreSettings,
  isDefault,
  title,
}: UpdateScoreSettingsInput): Promise<ScoreSettingGroupDto> {
  const endpoint = `/api/score_settings/${id}`;
  return await putJSON(endpoint, "/api/score_settings/${id}", {
    title,
    scoreSettings,
    isDefault,
  });
}

export async function getRfpScoreSettings({
  rfpId,
}: {
  rfpId: string;
}): Promise<ScoreSettingDto[]> {
  const endpoint = `/api/rfp/${rfpId}/score_settings`;
  return await getJSON(endpoint, "/api/rfp/${rfpId}/score_settings");
}

export async function deleteScoreSettingsGroup(
  scoreSettingsGroupId: string
): Promise<void> {
  const endpoint = `/api/score_settings/${scoreSettingsGroupId}`;
  return await deleteAPI(
    endpoint,
    "/api/score_settings/${scoreSettingsGroupId}"
  );
}

export async function setScoreGroupOnRfp({
  scoreSettingsGroupId,
  rfpId,
}: {
  rfpId: string;
  scoreSettingsGroupId: string;
}): Promise<ScoreSettingGroupDto> {
  const endpoint = `/api/rfp/${rfpId}/score_setting_group/${scoreSettingsGroupId}`;
  return await postJSON(
    endpoint,
    "/api/rfp/${rfpId}/score_setting_group/${scoreSettingsGroupId}"
  );
}

export async function deleteRFP(rfpId: string): Promise<RfpDto> {
  const endpoint = `/api/rfp/${rfpId}`;
  return await deleteAPI(endpoint, "/api/rfp/${rfpId}");
}

// RFP Modules

export async function getRFPModules(rfpId: string): Promise<DeepModuleDto[]> {
  const endpoint = `/api/rfp/${rfpId}/modules`;
  return await getJSON(endpoint, "/api/rfp/${rfpId}/modules");
}

export async function addModuleToRFP(
  rfpId: string,
  module: NewModuleInput
): Promise<ModuleDto> {
  const endpoint = `/api/rfp/${rfpId}/modules`;

  return await postJSON(endpoint, "/api/rfp/${rfpId}/modules", { module });
}

export async function updateModule(
  moduleId: string,
  module: UpdateModuleInput
): Promise<ModuleDto> {
  const endpoint = `/api/modules/${moduleId}`;

  return await putJSON(endpoint, "/api/modules/${moduleId}", { module });
}

export async function deleteModule(moduleId: string): Promise<void> {
  const endpoint = `/api/modules/${moduleId}`;

  return await deleteAPI(endpoint, "/api/modules/${moduleId}");
}

export async function uploadModuleAttachment(
  moduleId: string,
  files: File[],
  mode: AttachmentMode
): Promise<ModuleDto> {
  const endpoint = `/api/modules/${moduleId}/upload`;

  return await uploadAPI(endpoint, "/api/modules/${moduleId}/upload", files, {
    mode,
  });
}

export async function cloneAssetsToModule(
  moduleId: string,
  data: {
    assetIds: string[];
    mode: AttachmentMode;
  }
): Promise<ModuleDto> {
  const endpoint = `/api/modules/${moduleId}/clone`;

  return postJSON(endpoint, "/api/modules/${moduleId}/clone", data);
}

// Questionnaires

export async function addQuestionnaireToModule(
  moduleId: string,
  questionnaire: NewQuestionnaireInput
): Promise<DeepQuestionnaireDto> {
  const endpoint = `/api/modules/${moduleId}/questionnaires`;
  return await postJSON(endpoint, "/api/modules/${moduleId}/questionnaires", {
    questionnaire,
  });
}

export async function updateQuestionnaire(
  questionnaireId: string,
  questionnaire: UpdateQuestionnaireInput
): Promise<QuestionnaireDto> {
  const endpoint = `/api/questionnaires/${questionnaireId}`;

  return await putJSON(endpoint, "/api/questionnaires/${questionnaireId}", {
    questionnaire,
  });
}

export async function deleteQuestionnaire(questionnaireId: string) {
  const endpoint = `/api/questionnaires/${questionnaireId}`;

  return await deleteAPI(endpoint, "/api/questionnaires/${questionnaireId}");
}

export async function uploadQuestionnaireAttachment(
  questionnaireId: string,
  files: File[],
  mode: AttachmentMode
): Promise<QuestionnaireDto> {
  const endpoint = `/api/questionnaires/${questionnaireId}/upload`;

  return await uploadAPI(
    endpoint,
    "/api/questionnaires/${questionnaireId}/upload",
    files,
    { mode }
  );
}

export async function cloneAssetsToQuestionnaire(
  questionnaireId: string,
  data: {
    assetIds: string[];
    mode: AttachmentMode;
  }
): Promise<QuestionnaireDto> {
  const endpoint = `/api/questionnaires/${questionnaireId}/clone`;

  return postJSON(
    endpoint,
    "/api/questionnaires/${questionnaireId}/clone",
    data
  );
}

// Questions

export async function getQuestions({
  filters,
}: {
  filters: QuestionFilter;
}): Promise<UnformatedQuestionWithLabelsDto[]> {
  const endpoint = `/api/questions`;
  return await getJSON(endpoint, "/api/questions", filters);
}

export async function addQuestionToQuestionnaire(
  questionnaireId: string,
  question: NewQuestionInput
): Promise<QuestionDto> {
  const endpoint = `/api/questionnaires/${questionnaireId}/question`;

  return await postJSON(
    endpoint,
    "/api/questionnaires/${questionnaireId}/question",
    { question }
  );
}

export async function addQuestionsToQuestionnaire(
  questionnaireId: string,
  questions: QuestionImportExportDto[]
): Promise<void> {
  const endpoint = `/api/questionnaires/${questionnaireId}/questions`;

  return await postJSON(
    endpoint,
    "/api/questionnaires/${questionnaireId}/questions",
    { questions }
  );
}

export async function updateQuestion(
  questionId: string,
  question: UpdateQuestionInput
): Promise<QuestionDto> {
  const endpoint = `/api/questions/${questionId}`;

  return await putJSON(endpoint, "/api/questions/${questionId}", { question });
}

export async function deleteQuestion(questionId: string) {
  const endpoint = `/api/questions/${questionId}`;

  return await deleteAPI(endpoint, "/api/questions/${questionId}");
}

export async function uploadQuestionAttachment(
  questionId: string,
  files: File[],
  mode: AttachmentMode
): Promise<QuestionDto> {
  const endpoint = `/api/questions/${questionId}/upload`;

  return await uploadAPI(
    endpoint,
    "/api/questions/${questionId}/upload",
    files,
    { mode }
  );
}

export async function cloneAssetsToQuestion(
  questionId: string,
  data: {
    assetIds: string[];
    mode: AttachmentMode;
  }
): Promise<QuestionDto> {
  const endpoint = `/api/questions/${questionId}/clone`;

  return postJSON(endpoint, "/api/questions/${questionId}/clone", data);
}

// RFP Response
export async function getRFPresponsesByRfpId(
  rfpId: string,
  filters?: ReceivedResponseFilters
): Promise<{ responses: RfpResponseDto[]; winnerResponseId?: string }> {
  const endpoint = `/api/rfp/${rfpId}/responses`;
  return await getJSON(endpoint, "/api/rfp/${rfpId}/responses", filters);
}

export async function selectWinnerResponse(
  rfpId: string,
  body: SelectWinnerResponseInput
): Promise<string | void> {
  const endpoint = `/api/rfp/${rfpId}/selectWinner`;
  return await putJSON<SelectWinnerResponseInput>(
    endpoint,
    "/api/rfp/${rfpId}/selectWinner",
    body
  );
}

export async function getRFPresponse(
  rfpResponseId: string
): Promise<RfpResponseDto> {
  const endpoint = `/api/responses/${rfpResponseId}`;
  return await getJSON(endpoint, "/api/responses/${rfpResponseId}");
}

export async function getRFPresponseEvaluationDetails(
  rfpResponseId: string
): Promise<RfpResponseDetailedScores> {
  const endpoint = `/api/responses/${rfpResponseId}/evaluation`;
  return await getJSON(endpoint, "/api/responses/${rfpResponseId}/evaluation");
}

export async function createRFPResponse(
  rfpResponse: NewRfpResponse
): Promise<RfpResponseDto> {
  const endpoint = `/api/responses/`;
  return await postJSON<{ rfpResponse: NewRfpResponse }>(
    endpoint,
    "/api/responses/",
    {
      rfpResponse,
    }
  );
}

export async function editRFPResponse(
  rfpResponse: UpdateRfpResponseInput & { id: string }
): Promise<RfpResponseDto> {
  const endpoint = `/api/responses/${rfpResponse.id}`;
  return await putJSON(endpoint, "/api/responses/${rfpResponse.id}", {
    rfpResponse,
  });
}

export async function setResponseEvaluationStatus({
  responseId,
  evaluationStatus,
}: {
  responseId: string;
  evaluationStatus: RfpResponseEvaluationStatus;
}): Promise<RfpResponseDto> {
  const endpoint = `/api/responses/${responseId}`;

  return await putJSON(endpoint, "/api/responses/${responseId}", {
    rfpResponse: { evaluationStatus },
  });
}

export async function setResponseAnalysisStatus({
  responseId,
  analysisStatus,
}: {
  responseId: string;
  analysisStatus: RfpResponseAnalysisStatus;
}): Promise<RfpResponseDto> {
  const endpoint = `/api/responses/${responseId}`;

  return await putJSON(endpoint, "/api/responses/${responseId}", {
    rfpResponse: { analysisStatus },
  });
}

// Answer

export async function getAnswer(answerId: string): Promise<AnswerDto> {
  const endpoint = `/api/answers/${answerId}`;
  return getJSON(endpoint, "/api/answers/answerId");
}

export async function getAnswersByRfxId(rfxId: string): Promise<AnswerDto[]> {
  const endpoint = `/api/answers/rfx/${rfxId}/answers`;
  return await getJSON(endpoint, "/api/answers/rfx/${rfxId}/answers");
}

export async function getRFPModuleQuestionnaireAnswers(
  companyId: string, // The writer of the answer
  questionnaireId: string,
  rfpResponseId: string
): Promise<AnswerDto[]> {
  const endpoint = `/api/answers/companies/${companyId}/questionnaire/${questionnaireId}/rfpResponse/${rfpResponseId}`;
  return await getJSON(
    endpoint,
    "/api/answers/companies/${companyId}/questionnaire/${questionnaireId}/rfpResponse/${rfpResponseId}"
  );
}
export async function getAnswersByRFPResponseId(
  rfpResponseId: string
): Promise<AnswerDto[]> {
  const endpoint = `/api/responses/${rfpResponseId}/answers`;
  return await getJSON(endpoint, "/api/responses/${rfpResponseId}/answers");
}

export async function addAnswerToQuestion(
  rfpResponseId: string,
  answer: NewAnswerInput
): Promise<AnswerDto> {
  const endpoint = `/api/responses/${rfpResponseId}/answers`;
  return await postJSON(endpoint, "/api/responses/${rfpResponseId}/answers", {
    answer,
  });
}

export async function updateAnswer(
  answerId: string,
  answer: UpdateAnswerInput
): Promise<AnswerDto> {
  const endpoint = `/api/answers/${answerId}`;

  return await putJSON(endpoint, "/api/answers/${answerId}", { answer });
}

export async function deleteAnswer(answerId: string) {
  const endpoint = `/api/answers/${answerId}`;
  return await deleteAPI(endpoint, "/api/answers/${answerId}");
}

export async function addScoreToAnswer({
  answerId,
  score,
}: {
  answerId: string;
  score: number;
}): Promise<AnswerDto> {
  const endpoint = `/api/answers/${answerId}/score`;

  return await postJSON<ScoreAnswerInput>(
    endpoint,
    "/api/answers/${answerId}/score",
    { score }
  );
}

export async function cloneAssetsToAnswer(
  answerId: string,
  data: {
    rfpResponseId: string;
    assetIds: string[];
    choiceId?: string;
  }
): Promise<AnswerDto> {
  const endpoint = `/api/answers/${answerId}/attachment/clone`;

  return await postJSON(endpoint, "/api/answers/${answerId}/attachment/clone", {
    answerAttachment: data,
  });
}

export async function uploadAssetToAnswer(
  answerId: string,
  files: File[],
  choiceId?: string
): Promise<AnswerDto> {
  const endpoint = `/api/answers/${answerId}/attachment/upload`;
  return await uploadAPI(
    endpoint,
    "/api/answers/${answerId}/attachment/upload",
    files,
    {
      answerAttachment: {
        choiceId,
      },
    }
  );
}

export async function deleteAnswerAttachment(
  answerId: string,
  assetId: string
): Promise<AnswerDto> {
  const endpoint = `/api/answers/${answerId}/attachment/${assetId}`;
  return await deleteAPI(
    endpoint,
    "/api/answers/${answerId}/attachment/${assetId}"
  );
}

//Auth

export async function register(
  params: RegistrationInput
): Promise<{ userId: string; companyId: string }> {
  const endpoint = `/api/auth/register`;
  return await postJSON<RegistrationInput>(
    endpoint,
    "/api/auth/register",
    params
  );
}

export async function logIn({
  params,
}: {
  params: LoginInput;
}): Promise<{ userId: string; companyId: string }> {
  const endpoint = `/api/auth/login`;
  return await postJSON<LoginInput>(endpoint, "/api/auth/login", params);
}

export async function forgotPassword(email: string): Promise<{
  email: string;
}> {
  const endpoint = `/api/auth/forgot_password`;
  return await postJSON(endpoint, "/api/auth/forgot_password", { email });
}

export async function resetPassword({
  password,
  token,
}: {
  password: string;
  token: string;
}): Promise<{
  email: string;
}> {
  const endpoint = `/api/auth/reset_password`;
  return await postJSON(endpoint, "/api/auth/reset_password", {
    password,
    token,
  });
}

export async function logOut() {
  const endpoint = `/api/auth/logout`;
  return await postJSON<LoginInput>(endpoint, "/api/auth/logout");
}

export async function getInviterCompany(
  companyMemberInvitationToken: string
): Promise<CompanyDto> {
  const endpoint = `/api/auth/invitation/${companyMemberInvitationToken}`;
  return await getJSON(
    endpoint,
    "/api/auth/invitation/${companyMemberInvitationToken}"
  );
}

export async function renewToken(): Promise<{
  userId: string;
  companyId: string;
}> {
  const endpoint = `/api/auth/token`;
  return await postJSON(endpoint, "/api/auth/token");
}

export async function verifyToken({
  token,
  type,
}: {
  token: string;
  type: TokenType;
}): Promise<{
  userId: string;
  companyId: string;
}> {
  const endpoint = `/api/auth/verify_token`;
  return await postJSON(endpoint, "/api/auth/verify_token", { token, type });
}

export async function verifyEmail({ token }: { token: string }): Promise<{
  userId: string;
  companyId: string;
}> {
  const endpoint = `/api/auth/verify_email`;
  return await postJSON(endpoint, "/api/auth/verify_email", { token });
}

export async function resendVerifyEmail(email: string): Promise<{
  email: string;
}> {
  const endpoint = `/api/auth/send_verification_email`;
  return await postJSON(endpoint, "/api/auth/send_verification_email", {
    email,
  });
}

// User
export async function getUser(userId: string): Promise<UserDto> {
  const endpoint = `/api/users/${userId}`;
  return await getJSON(endpoint, "/api/users/${userId}");
}

export async function inviteCompanyMembers(
  companyId: string,
  invitationEmailList: string[]
) {
  const endpoint = `/api/companies/${companyId}/invitations`;
  return await postJSON<{ invitationEmailList: string[] }>(
    endpoint,
    "/api/companies/${companyId}/invitations",
    {
      invitationEmailList,
    }
  );
}

export async function resendMemberInvitation({
  companyId,
  invitationId,
}: {
  companyId: string;
  invitationId: string;
}) {
  const endpoint = `/api/companies/${companyId}/invitations/${invitationId}`;
  return await postJSON(
    endpoint,
    "/api/companies/${companyId}/invitations/${invitationId}",
    {}
  );
}

export async function getCompanyMembers(companyId: string) {
  const endpoint = `/api/users/${companyId}/users`;
  return await getJSON<UserDto[]>(endpoint, "/api/users/${companyId}/users");
}

export async function getUserCompanies(userId: string): Promise<string[]> {
  const endpoint = `/api/users/${userId}/companies`;
  const response = await getJSON<string[]>(
    endpoint,
    "/api/users/${userId}/companies"
  );
  return response;
}

export async function updateSelf(user: Partial<User>): Promise<UserDto> {
  const endpoint = `/api/users/update`;
  return await putJSON(endpoint, "/api/users/update", { user });
}

export async function switchCompany({
  activeCompanyId,
}: {
  activeCompanyId: string;
}): Promise<UserDto> {
  const endpoint = `/api/users/company/switch`;
  return await putJSON(endpoint, "/api/users/company/switch", {
    body: { activeCompanyId },
  });
}

export async function connectToCompany(
  companyMemeberInvitationToken: string,
  companyId: string
): Promise<boolean> {
  const endpoint = `/api/users/connectCompanyMemberInvitation/${companyMemeberInvitationToken}`;
  return await putJSON(
    endpoint,
    "/api/users/connectCompanyMemberInvitation/${companyMemeberInvitationToken}",
    { companyId }
  );
}

export async function connectToRFP(
  rfpInvitationToken: string,
  companyId: string
): Promise<boolean> {
  const endpoint = `/api/users/connectRFPInvitation/${rfpInvitationToken}`;
  return await putJSON(
    endpoint,
    "/api/users/connectRFPInvitation/${rfpInvitationToken}",
    { companyId }
  );
}

export async function connectToGroup(
  groupInvitationToken: string,
  companyId: string
): Promise<boolean> {
  const endpoint = `/api/users/connectGroupInvitation/${groupInvitationToken}`;
  return await putJSON(
    endpoint,
    "/api/users/connectGroupInvitation/${groupInvitationToken}",
    { companyId }
  );
}

export async function getCompany(id: string): Promise<CompanyDetailsDto> {
  const endpoint = `/api/companies/${id}`;
  return await getJSON(endpoint, "/api/companies/${id}");
}

export async function createCompany(companyName: string): Promise<CompanyDto> {
  const endpoint = `/api/companies/create`;
  return await postJSON(endpoint, "/api/companies/create", { companyName });
}

// Asset Repository
export async function getAssetRepositoryAllFolders(
  companyId: string
): Promise<FolderDto> {
  const endpoint = `/api/companies/${companyId}/folders`;
  return await getJSON(endpoint, "/api/companies/${companyId}/folders");
}

export async function getAssetRepositoryFolderPath(
  companyId: string,
  folderId: string
): Promise<PathDto[]> {
  const endpoint = `/api/companies/${companyId}/folders/${folderId}/path`;
  return await getJSON(
    endpoint,
    "/api/companies/${companyId}/folders/${folderId}/path"
  );
}

export async function searchAssets(
  companyId: string,
  searchOptions: AssetSearchOptions
): Promise<FolderItemDto[]> {
  const endpoint = `/api/companies/${companyId}/folders/search`;
  return await postJSON<{ searchOptions: AssetSearchOptions }, FolderItemDto[]>(
    endpoint,
    "/api/companies/${companyId}/folders/search",
    { searchOptions }
  );
}

export async function searchDataRoomAssets(
  companyId: string,
  searchOptions: AssetSearchOptions
): Promise<FolderItemDto[]> {
  const endpoint = `/api/companies/${companyId}/datarooms/folders`;
  return await postJSON<{ searchOptions: AssetSearchOptions }, FolderItemDto[]>(
    endpoint,
    "/api/companies/${companyId}/datarooms/folders",
    { searchOptions }
  );
}

// Assets

export async function getAssets(companyId: string): Promise<AssetDto[]> {
  const endpoint = `/api/assets/${companyId}/""`;
  return await getJSON(endpoint, '/api/assets/${companyId}/""');
}

export async function getAssetBlobUrls(
  assetIds: string[],
  openInNewTab?: boolean,
  fileNamePrefix?: string
): Promise<{ assetId: string; blobUrl: string }[]> {
  const endpoint = `/api/companies/assets/download`;
  return await postJSON(endpoint, "/api/companies/assets/download", {
    assetIds,
    openInNewTab,
    fileNamePrefix,
  });
}

export async function uploadAsset(
  companyId: string,
  folderId: string,
  files: File[]
): Promise<string> {
  const endpoint = `/api/companies/${companyId}/folders/${folderId}/upload`;
  return await uploadAPI(
    endpoint,
    "/api/companies/${companyId}/folders/${folderId}/upload",
    files
  );
}

export async function cloneAssetsToFolder({
  assetIds,
  folderId,
  companyId,
}: {
  assetIds: string[];
  folderId: string;
  companyId: string;
}): Promise<FolderItemDto> {
  const endpoint = `/api/companies/${companyId}/folders/clone/${folderId}`;
  return await postJSON(
    endpoint,
    "/api/companies/${companyId}/folders/clone/${folderId}",
    {
      assetIds,
    }
  );
}

export async function putAsset(asset: UploadAsset): Promise<UploadAsset> {
  const endpoint = `/api/companies/${asset.companyId}/folders/
  ${asset.id || ""}`;
  return await putJSON(
    endpoint,
    '/api/companies/${asset.companyId}/folders/${asset.id || ""}',
    asset
  );
}

export async function moveAsset(
  companyId: string,
  assetId: string,
  newFolderId: string
) {
  const endpoint = `/api/companies/${companyId}/assets/${assetId}/move/${newFolderId}`;
  return await putJSON(
    endpoint,
    "/api/companies/${companyId}/assets/${assetId}/move/${newFolderId}",
    { assetId, newFolderId }
  );
}

export async function changeAssetType(
  companyId: string,
  assetId: string,
  newType: AssetType
) {
  const endpoint = `/api/companies/${companyId}/assets/${assetId}/type/${newType}`;
  return await putJSON<FolderDto>(
    endpoint,
    "/api/companies/${companyId}/assets/${assetId}/type/${newType}"
  );
}

export async function renameAsset(
  companyId: string,
  assetId: string,
  newName: string
) {
  const endpoint = `/api/companies/${companyId}/assets/${assetId}/rename/${newName}`;
  return await putJSON<FolderDto>(
    endpoint,
    "/api/companies/${companyId}/assets/${assetId}/rename/${newName}"
  );
}

export async function deleteAsset(
  companyId: string,
  assetId: string,
  containerType: ContainerType
) {
  const endpoint = `/api/companies/${companyId}/assets/${assetId}/${containerType}`;
  return await deleteAPI(
    endpoint,
    "/api/companies/${companyId}/assets/${assetId}/${containerType}"
  );
}

// Folders
export async function getAllFolders(
  companyId: string,
  filters?: FolderTreeFilters
): Promise<FolderListDto> {
  const endpoint = `/api/companies/${companyId}/folders`;
  return await getJSON(
    endpoint,
    "/api/companies/${companyId}/folders",
    filters
  );
}

export async function postFolder(
  companyId: string,
  parentFolderId: string | "null",
  newFolder: NewFolder
) {
  const endpoint = `/api/companies/${companyId}/folders/${parentFolderId}`;
  return await postJSON<{ newFolder: NewFolder }>(
    endpoint,
    "/api/companies/${companyId}/folders/${parentFolderId}",
    { newFolder }
  );
}

export async function renameFolder(
  companyId: string,
  folderId: string,
  newName: string
) {
  const endpoint = `/api/companies/${companyId}/folders/${folderId}/rename/${newName}`;
  return await putJSON<FolderDto>(
    endpoint,
    "/api/companies/${companyId}/folders/${folderId}/rename/${newName}"
  );
}

export async function deleteFolder(folderId: string) {
  const endpoint = `/api/companies/folders/${folderId}`;
  return await deleteAPI(endpoint, "/api/companies/folders/${folderId}");
}

/** Would be much cleaner if we stored the container details on the asset table */
export async function download(
  companyId: string,
  folderIds: string[],
  assetIds: string[],
  zipFileName: string,
  context: {
    dataRoomId?: string;
    contractId?: string;
    rfpId?: string;
    postId?: string;
    groupId?: string;
  },
  open?: boolean
) {
  const endpoint = `/api/companies/downloadzip`;
  await downloadZip(
    endpoint,
    "/api/companies/downloadzip",
    {
      companyId,
      folderIds,
      assetIds,
      zipFileName,
      ...context,
    },
    open
  );
}

// Asset labels
export async function getAssetLabels(
  companyId: string
): Promise<AssetLabelDto[]> {
  const endpoint = `/api/companies/${companyId}/labels`;
  const questionnaires = await getJSON<AssetLabelDto[]>(
    endpoint,
    "/api/companies/${companyId}/labels"
  );
  return questionnaires;
}

export async function createAssetLabel(
  companyId: string,
  name: string,
  color: string
) {
  const endpoint = `/api/companies/${companyId}/labels`;
  return await postJSON<{ label: NewAssetLabelInput }>(
    endpoint,
    "/api/companies/${companyId}/labels",
    {
      label: {
        color,
        companyId,
        name,
      },
    }
  );
}

export async function updateAssetLabel(
  companyId: string,
  assetLabelId: string,
  input: UpdateAssetLabelInput
) {
  const endpoint = `/api/companies/${companyId}/labels/${assetLabelId}`;
  return await putJSON<{ label: UpdateAssetLabelInput }>(
    endpoint,
    "/api/companies/${companyId}/labels/${assetLabelId}",
    {
      label: input,
    }
  );
}

export async function deleteAssetLabel(
  companyId: string,
  assetLabelId: string
) {
  const endpoint = `/api/companies/${companyId}/labels/${assetLabelId}`;
  return await deleteAPI(
    endpoint,
    "/api/companies/${companyId}/labels/${assetLabelId}"
  );
}

export async function assignLabelsToAssets(
  companyId: string,
  assetLabelId: string,
  input: ToggleLabelOnAssetsInput
) {
  const endpoint = `/api/companies/${companyId}/labels/${assetLabelId}/add`;
  return await putJSON<{ label: ToggleLabelOnAssetsInput }>(
    endpoint,
    "/api/companies/${companyId}/labels/${assetLabelId}/add",
    {
      label: input,
    }
  );
}

export async function removeLabelFromAssets(
  companyId: string,
  assetLabelId: string,
  input: ToggleLabelOnAssetsInput
) {
  const endpoint = `/api/companies/${companyId}/labels/${assetLabelId}/remove`;
  return await putJSON<{ label: ToggleLabelOnAssetsInput }>(
    endpoint,
    "/api/companies/${companyId}/labels/${assetLabelId}/remove",
    {
      label: input,
    }
  );
}

export async function getStandardizedQuestionnaires(
  rfpType?: RFPType
): Promise<StandardizedQuestionnaireDto[]> {
  const endpoint = `/api/standardized_questionnaires/`;

  const questionnaires = await getJSON<StandardizedQuestionnaireDto[]>(
    endpoint,
    "/api/standardized_questionnaires/",
    { type: rfpType }
  );
  return questionnaires;
}

export async function moveFolder(
  companyId: string,
  folderId: string,
  newParentId: string | null
) {
  const endpoint = `/api/companies/${companyId}/folders/${folderId}/move/${newParentId}`;
  return await putJSON(
    endpoint,
    "/api/companies/${companyId}/folders/${folderId}/move/${newParentId}"
  );
}

export async function signInOrSignup() {
  await postJSON(`/`, "/", {});
}

export async function testApi() {
  return await getJSON("/api/health", "/api/health");
}
export async function testAssetsApi() {
  return await getJSON("/api/assets", "/api/assets");
}

//Company

export async function updateCompanyInfo(
  companyId: string,
  updatedCompany: CompanyUpdateDto
): Promise<CompanyDto> {
  const endpoint = `/api/companies/${companyId}`;
  return await putJSON(endpoint, "/api/companies/${companyId}", {
    company: updatedCompany,
  });
}

export async function getCompanies(
  filters: CompanyFilter
): Promise<CompanyDto[]> {
  const endpoint = `/api/companies`;
  return await getJSON<CompanyDto[], CompanyFilter>(
    endpoint,
    "/api/companies",
    filters
  );
}

export async function getCompanyListCompanies(
  filters: CompanyFilter,
  companyListFilters: { companyId: string; companyListId: string }
): Promise<CompanyDto[]> {
  const endpoint = `/api/companies/${companyListFilters.companyId}/lists/${companyListFilters.companyListId}`;
  //return await getJSON<CompanyListDto>(endpoint);
  return await getJSON<CompanyDto[], CompanyFilter>(
    endpoint,
    "/api/companies/${companyListFilters.companyId}/lists/${companyListFilters.companyListId}",
    filters
  );
}

// Data room
export async function getDataRoom(
  dataRoomId: string
): Promise<DataRoomDetailsDto> {
  const endpoint = `/api/companies/datarooms/${dataRoomId}`;
  return await getJSON(endpoint, "/api/companies/datarooms/${dataRoomId}");
}

export async function getDataRoomVideos(
  dataRoomId: string
): Promise<MediaItemDto[]> {
  const endpoint = `/api/companies/datarooms/${dataRoomId}/videos`;
  return await getJSON(
    endpoint,
    "/api/companies/datarooms/${dataRoomId}/videos"
  );
}

export async function addVideoToDataRoom({
  companyId,
  dataRoomId,
  name,
  reference,
}: AddYouTubeVideoInput & {
  companyId: string;
  dataRoomId: string;
}): Promise<MediaItemDto> {
  const endpoint = `/api/companies/${companyId}/${dataRoomId}/video`;
  return await postJSON<AddYouTubeVideoInput>(
    endpoint,
    "/api/companies/${companyId}/${dataRoomId}/video",
    { name, reference }
  );
}

export async function updateDataRoomVideo({
  companyId,
  dataRoomId,
  name,
  videoId,
}: UpdateYouTubeVideoInput & {
  companyId: string;
  dataRoomId: string;
  videoId: string;
}): Promise<MediaItemDto> {
  const endpoint = `/api/companies/${companyId}/${dataRoomId}/video/${videoId}`;
  return await putJSON<UpdateYouTubeVideoInput>(
    endpoint,
    "/api/companies/${companyId}/${dataRoomId}/video/${videoId}",
    { name }
  );
}

export async function deleteVideoFromDataRoom({
  mediaId,
  dataRoomId,
}: {
  mediaId: string;
  dataRoomId: string;
}): Promise<MediaItemDto> {
  const endpoint = `/api/companies/datarooms/${dataRoomId}/${mediaId}`;
  return await deleteAPI(
    endpoint,
    "/api/companies/datarooms/${dataRoomId}/${mediaId}"
  );
}

export async function getDataRooms(
  companyId: string,
  filters?: {
    createdAfter?: string | null;
    createdBefore?: string | null;
    search?: string | null;
  }
): Promise<DataRoomDto[]> {
  const endpoint = `/api/companies/${companyId}/datarooms`;
  return await getJSON(
    endpoint,
    "/api/companies/${companyId}/datarooms`",
    filters
  );
}

export async function createDataRoom(
  companyId: string,
  dataRoom: CreateDataRoomInput
): Promise<DataRoomDto> {
  const endpoint = `/api/companies/${companyId}/datarooms`;
  return await postJSON<CreateDataRoomInput>(
    endpoint,
    "/api/companies/${companyId}/datarooms",
    dataRoom
  );
}

export async function deleteDataRoom(
  companyId: string,
  dataRoomId: string
): Promise<DataRoomDto> {
  const endpoint = `/api/companies/${companyId}/datarooms/${dataRoomId}`;
  return await deleteAPI(
    endpoint,
    "/api/companies/${companyId}/datarooms/${dataRoomId}"
  );
}

export async function updateDataRoom(
  companyId: string,
  dataRoomId: string,
  data: UpdateDataRoomInput
): Promise<DataRoomDto> {
  const endpoint = `/api/companies/${companyId}/datarooms/${dataRoomId}`;
  return await putJSON<UpdateDataRoomInput>(
    endpoint,
    "/api/companies/${companyId}/datarooms/${dataRoomId}",
    data
  );
}

export async function uploadAssetToDataRoom({
  companyId,
  files,
  folderId,
}: {
  companyId: string;
  files: File[];
  folderId: string;
}): Promise<AnswerDto> {
  const endpoint = `/api/companies/${companyId}/datarooms/folders/${folderId}/upload`;
  return await uploadAPI(
    endpoint,
    "/api/companies/${companyId}/datarooms/folders/${folderId}/upload",
    files
  );
}

export async function cloneAssetsToDataRoom({
  companyId,
  folderId,
  assetIds,
  folderIds,
}: {
  companyId: string;
  folderId: string;
} & CloneAssetsInput): Promise<AnswerDto> {
  const endpoint = `/api/companies/${companyId}/datarooms/folders/${folderId}/clone`;
  return await postJSON<CloneAssetsInput>(
    endpoint,
    "/api/companies/${companyId}/datarooms/folders/${folderId}/clone",
    {
      assetIds,
      folderIds,
    }
  );
}

export async function recordDataRoomVisit(
  dataRoomId: string
): Promise<DataRoomDto> {
  const endpoint = `/api/companies/dataRoomVisit/${dataRoomId}`;
  return await postJSON(
    endpoint,
    "/api/companies/dataRoomVisit/${dataRoomId}",
    {}
  );
}

export async function getDataRoomInvitations(
  dataRoomId: string
): Promise<DataRoomInvitationDto[]> {
  const endpoint = `/api/companies/datarooms/${dataRoomId}/invitations`;
  return await getJSON(
    endpoint,
    "/api/companies/datarooms/${dataRoomId}/invitations"
  );
}

export async function sendDataRoomInvitations(
  dataRoomId: string,
  data: CreateDataRoomInvitationInput
): Promise<{ count: number }> {
  const endpoint = `/api/companies/datarooms/${dataRoomId}/invitations`;
  return await putJSON<CreateDataRoomInvitationInput>(
    endpoint,
    "/api/companies/datarooms/${dataRoomId}/invitations",
    data
  );
}

// Data room invitations

// Company list

// Not used anywhere
// export async function getCompanyLists(
//   companyId: string
// ): Promise<CompanyListDto[]> {
//   const endpoint = `/api/companies/${companyId}/lists`;
//   return await getJSON(endpoint);
// }

export async function getCompanyList(
  companyId: string,
  companyListId: string
): Promise<CompanyListDto> {
  const endpoint = `/api/companies/${companyId}/lists/${companyListId}`;
  return await getJSON(
    endpoint,
    "/api/companies/${companyId}/lists/${companyListId}"
  );
}
export async function renameCompanyList(
  companyId: string,
  companyListId: string,
  newName: string
): Promise<CompanyListDto> {
  const endpoint = `/api/companies/${companyId}/lists/${companyListId}/rename/${newName}`;
  return await putJSON(
    endpoint,
    "/api/companies/${companyId}/lists/${companyListId}/rename/${newName}"
  );
}
export async function deleteCompanyList(
  companyId: string,
  companyListId: string
) {
  const endpoint = `/api/companies/${companyId}/lists/${companyListId}`;
  return await deleteAPI(
    endpoint,
    "/api/companies/${companyId}/lists/${companyListId}"
  );
}

// Company list Management
export async function postCompanyList(
  companyId: string,
  listName: string
): Promise<CompanyListDto> {
  const endpoint = `/api/companies/${companyId}/lists`;
  return await postJSON<
    { companyList: { name: string; companyIds: string[] } },
    CompanyListDto
  >(endpoint, "/api/companies/${companyId}/lists", {
    companyList: { name: listName, companyIds: [] },
  });
}

export async function postCompanyListCompany(
  companyId: string,
  listId: string,
  companyListCompanyId: string
): Promise<CompanyListDto> {
  const endpoint = `/api/companies/${companyId}/lists/${listId}/${companyListCompanyId}`;
  return await postJSON<{}, CompanyListDto>(
    endpoint,
    "/api/companies/${companyId}/lists/${listId}/${companyListCompanyId}",
    {}
  );
}

export async function deleteCompanyListCompany(
  companyId: string,
  listId: string,
  companyListCompanyId: string
): Promise<CompanyListDto> {
  const endpoint = `/api/companies/${companyId}/lists/${listId}/${companyListCompanyId}`;
  return await deleteAPI(
    endpoint,
    "/api/companies/${companyId}/lists/${listId}/${companyListCompanyId}"
  );
}

// Passports
export async function getPassportTypes(): Promise<PassportTypeDto[]> {
  return await getJSON("/api/passports/types", "/api/passports/types");
}

export async function getMyRecentPassports(params: {
  offset?: number;
  limit?: number;
}): Promise<PassportItemDto[]> {
  const endpoint = `/api/passports`;
  return await getJSON<PassportItemDto[]>(endpoint, "/api/passports", params);
}

export async function getSentPassportInvitations(
  params: PassportInvitationFilter & {
    offset?: number;
    limit?: number;
  }
): Promise<PassportInvitationDto[]> {
  const endpoint = `/api/passports/invitations/sent`;
  return await getJSON<PassportInvitationDto[]>(
    endpoint,
    "/api/passports/invitations/sent",
    params
  );
}

export async function inviteToTakePassport(
  rfpId: string,
  recipients: CombinedInvitationRecipientInput[]
): Promise<void> {
  const endpoint = `/api/passports/${rfpId}`;
  await postJSON(endpoint, "/api/passports/${rfpId}", { recipients });
}

export async function resendPassportInviation(
  rfpInvitationId: string
): Promise<void> {
  const endpoint = `/api/passports/invitation/${rfpInvitationId}/resend`;
  await postJSON(
    endpoint,
    "/api/passports/invitation/${rfpInvitationId}/resend"
  );
}

// Others

export async function getCountries(): Promise<Pair[]> {
  return await getJSON("/api/countries", "/api/countries");
}

export async function getIndustries(): Promise<Pair[]> {
  return await getJSON("/api/industries", "/api/industries");
}

export async function getSectors(): Promise<Pair[]> {
  return await getJSON("/api/sectors", "/api/sectors");
}

export async function getSpecialties(): Promise<Pair[]> {
  return await getJSON("/api/specialties", "/api/specialties");
}
export async function getZones(): Promise<Pair[]> {
  return await getJSON("/api/zones", "/api/zones");
}
export async function getEmployeeNumbers(): Promise<Pair[]> {
  return await getJSON("/api/employeesNumber", "/api/employeesNumber");
}

// Products and Services

export async function createProductOrService(
  companyId: string,
  productOrService: LocalProductOrService
): Promise<ProductOrService> {
  const endpoint = `/api/companies/${companyId}/products`;
  return postJSON(endpoint, "/api/companies/${companyId}/products", {
    product: productOrService,
  });
}

export async function deleteProductOrService(
  companyId: string,
  productOrServiceId: string
) {
  const endpoint = `/api/companies/${companyId}/products/${productOrServiceId}`;
  return await deleteAPI(
    endpoint,
    "/api/companies/${companyId}/products/${productOrServiceId}"
  );
}

export async function updateProductsAndServices(
  companyId: string,
  producstAndServices: LocalProductOrService[]
): Promise<ProductOrService[]> {
  const endpoint = `/api/companies/${companyId}/products/all`;
  return await postJSON(endpoint, "/api/companies/${companyId}/products/all", {
    products: producstAndServices,
  });
}

//Feedback
export async function sendFeedBack(feedback: Feedback) {
  const endpoint = "/api/mailbox";
  return await postJSON(endpoint, "/api/mailbox", { input: feedback });
}

//Payment
export async function stripePayment(
  passportId: string,
  companyId: string,
  successUrl: string,
  cancelUrl: string
): Promise<{ sessionId: string }> {
  const endpoint = `/api/passports/${companyId}/${passportId}/pay`;
  return await postJSON(
    endpoint,
    "/api/passports/${companyId}/${passportId}/pay",
    { successUrl, cancelUrl }
  );
}

export async function recordPayment(
  sessionId: string,
  companyId: string
): Promise<Boolean> {
  const endpoint = `/api/passports/recordPayment/${sessionId}/${companyId}`;
  return await getJSON(
    endpoint,
    "/api/passports/recordPayment/${sessionId}/${companyId}"
  );
}

export async function togglePassportVisibility(
  passportId: string,
  show: boolean
): Promise<Boolean> {
  const endpoint = `/api/passports/${passportId}/show`;
  return await postJSON(endpoint, "/api/passports/${passportId}/show", {
    show,
  });
}

export async function getFileItem(
  companyId: string,
  blobId: string,
  containerType: ContainerType
): Promise<Blob> {
  const endpoint = `/api/companies/${companyId}/assets/${blobId}/getfile/${containerType}`;
  return await fileGET(
    endpoint,
    "/api/companies/${companyId}/assets/${blobId}/getfile/${containerType}"
  );
}

//ENV STRIPE KEY
export async function getStripeKey(): Promise<{ stripeKey: string }> {
  const endpoint = "/api/passports/stripeKey/publishableKey";
  return await getJSON(endpoint, "/api/passports/stripeKey/publishableKey");
}

// Role combinations

export async function getUserRolesByCompanyId(
  companyId: string
): Promise<UserCompanyRole[]> {
  const endpoint = `/api/roles/companies/${companyId}`;
  return await getJSON(endpoint, "/api/roles/companies/${companyId}");
}

export async function getCompanyRolesByUserId(
  userId: string
): Promise<UserCompanyRole[]> {
  const endpoint = `/api/roles/users/${userId}`;
  return await getJSON(endpoint, "/api/roles/users/${userId}");
}

export async function getUserCompanyRole(
  companyId: string,
  userId: string
): Promise<UserCompanyRole> {
  const endpoint = `/api/roles/companies/${companyId}/users/${userId}`;
  return await getJSON(
    endpoint,
    "/api/roles/companies/${companyId}/users/${userId}"
  );
}

export async function createUserCompanyRole(
  userCompanyRole: NewUserCompanyRole
): Promise<UserCompanyRole> {
  const endpoint = `/api/roles`;
  return await postJSON<{ userCompanyRole: NewUserCompanyRole }>(
    endpoint,
    "/api/roles",
    {
      userCompanyRole,
    }
  );
}

export async function putUserCompanyRole(
  userCompanyRole: UserCompanyRole
): Promise<RfpResponseDto> {
  const endpoint = `/api/roles`;
  return await putJSON<{ userCompanyRole: UserCompanyRole }>(
    endpoint,
    "/api/roles",
    {
      userCompanyRole,
    }
  );
}

export async function deleteUserCompanyRole(companyId: string, userId: string) {
  const endpoint = `/api/roles/companies/${companyId}/users/${userId}`;
  return await deleteAPI(
    endpoint,
    "/api/roles/companies/${companyId}/users/${userId}"
  );
}

export async function getRoles(): Promise<Role[]> {
  const endpoint = `/api/roles/all`;
  return await getJSON(endpoint, "/api/roles/all");
}

export async function getFeatureFlag(featureName: string): Promise<boolean> {
  const endpoint = `/api/feature_flags/${featureName}`;
  return await getJSON(endpoint, "/api/feature_flags/${featureName}");
}

// Groups
export async function getOwnGroups(params: {
  offset: number;
  limit: number;
}): Promise<GroupDto[]> {
  const endpoint = `/api/groups/own`;
  return await getJSON<GroupDto[]>(endpoint, "/api/groups/own", params);
}

export async function searchGroups(params: {
  query: GroupSearchOptions;
  offset: number;
  limit: number;
}): Promise<GroupDto[]> {
  const endpoint = `/api/groups`;
  return await getJSON<GroupDto[]>(endpoint, "/api/groups", params);
}

export async function getGroupByInvitationId(
  groupInvitationId: string
): Promise<GroupDetailsDto> {
  const endpoint = `/api/groups/invitation/${groupInvitationId}`;
  return await getJSON<GroupDetailsDto>(
    endpoint,
    "/api/groups/invitation/${groupInvitationId}"
  );
}

export async function getGroup(groupId: string): Promise<GroupDetailsDto> {
  const endpoint = `/api/groups/group/${groupId}`;
  return await getJSON<GroupDetailsDto>(
    endpoint,
    "/api/groups/group/${groupId}"
  );
}

export async function getGroupFiles(
  groupId: string,
  filters?: GroupFilesFilters
): Promise<GroupFilesDto> {
  const endpoint = `/api/groups/group/${groupId}/files`;
  return await getJSON<GroupFilesDto>(
    endpoint,
    "/api/groups/group/${groupId}/files",
    filters
  );
}

export async function getGroupInvitations(
  groupId: string
): Promise<CombinedInvitationTargetDto[]> {
  const endpoint = `/api/groups/group/${groupId}/invitations`;
  return await getJSON<CombinedInvitationTargetDto[]>(
    endpoint,
    "/api/groups/group/${groupId}/invitations"
  );
}

export async function getUserPreviewsBasedOnGroupInvitation(
  companyId: string
): Promise<UserPreviewDto[]> {
  const endpoint = `/api/groups/group/members/${companyId}`;
  return await getJSON<UserPreviewDto[]>(
    endpoint,
    "/api/groups/group/members/${companyId}"
  );
}

export async function createGroup(group: NewGroup): Promise<GroupDetailsDto> {
  const endpoint = `/api/groups/`;
  return await postJSON<{ group: NewGroup }>(endpoint, "/api/groups/", {
    group,
  });
}

export async function getGroupsImMeber(params: {
  offset: number;
  limit: number;
}): Promise<GroupDto[]> {
  const endpoint = `/api/groups/member`;
  return await getJSON<GroupDto[]>(endpoint, "/api/groups/member", params);
}

export async function updateGroup(
  groupId: string,
  updateGroup: UpdateGroupInput
): Promise<GroupDetailsDto> {
  const endpoint = `/api/groups/${groupId}`;
  return await putJSON<UpdateGroupInput>(
    endpoint,
    "/api/groups/${groupId}",
    updateGroup
  );
}

export async function deleteGroup(groupId: string): Promise<void> {
  const endpoint = `/api/groups/${groupId}`;
  return await deleteAPI(endpoint, "/api/groups/${groupId}");
}

export async function inviteGroupMembers(
  groupId: string,
  recipients: CombinedInvitationRecipientInput[]
): Promise<void> {
  const endpoint = `/api/groups/${groupId}`;
  await postJSON(endpoint, "/api/groups/${groupId}", { recipients });
}

export async function requestAccess(groupId: string): Promise<GroupDetailsDto> {
  const endpoint = `/api/groups/${groupId}/requestAccess`;
  return await postJSON(endpoint, "/api/groups/${groupId}/requestAccess");
}

export async function setGroupInvitationStatus({
  groupInvitationId,
  status,
}: {
  groupInvitationId: string;
  status: GroupInvitationStatus;
}): Promise<void> {
  const endpoint = `/api/groups/${groupInvitationId}/status/${status}`;
  await postJSON(endpoint, "/api/groups/${groupInvitationId}/status/${status}");
}

export async function uploadVideoToGroup(
  groupId: string,
  params: AddYouTubeVideoInput
): Promise<MediaItemDto> {
  const endpoint = `/api/groups/${groupId}/video/upload`;
  return await postJSON(
    endpoint,
    "/api/groups/${groupId}/video/upload",
    params
  );
}

export async function updateGroupVideo(
  mediaItemId: string,
  params: UpdateYouTubeVideoInput
): Promise<MediaItemDto> {
  const endpoint = `/api/groups/video/${mediaItemId}`;
  return await putJSON(endpoint, "/api/groups/video/${mediaItemId}", params);
}

export async function deleteGroupVideo(
  mediaItemId: string
): Promise<MediaItemDto> {
  const endpoint = `/api/groups/video/${mediaItemId}`;
  return await deleteAPI(endpoint, "/api/groups/video/${mediaItemId}");
}

export async function uploadGroupAttachment(
  groupId: string,
  files: File[],
  mode: AttachmentMode
): Promise<GroupFilesDto> {
  const endpoint = `/api/groups/${groupId}/upload`;

  return await uploadAPI(endpoint, "/api/groups/${groupId}/upload", files, {
    mode,
  });
}

export async function cloneAssetsToGroup(
  groupId: string,
  data: {
    assetIds: string[];
    mode: AttachmentMode;
  }
): Promise<GroupFilesDto> {
  const endpoint = `/api/groups/${groupId}/clone`;

  return postJSON(endpoint, "/api/groups/${groupId}/clone", data);
}

export async function deleteGroupAttachment(
  assetId: string
): Promise<GroupFilesDto> {
  const endpoint = `/api/groups/attachment/${assetId}`;
  return await deleteAPI(endpoint, "/api/groups/attachment/${assetId}");
}

// Posts
export async function getPost(postId: string): Promise<PostDto> {
  const endpoint = `/api/posts/${postId}`;
  return await getJSON<PostDto>(endpoint, "/api/posts/${postId}");
}

export async function getGroupPosts(
  groupId: string,
  params: {
    limit: number;
    cursorId?: string;
    isApproved?: boolean;
    isDraft?: boolean;
  }
): Promise<PostsQueryResult> {
  const endpoint = `/api/posts/group/${groupId}`;
  return await getJSON<PostsQueryResult>(
    endpoint,
    "/api/posts/group/${groupId}",
    params
  );
}

export async function createPost(
  groupId: string,
  post: NewPostInput
): Promise<PostWithReferencesDto> {
  const endpoint = `/api/posts/${groupId}`;
  return await postJSON(endpoint, "/api/posts/${groupId}", post);
}

export async function updatePost(
  postId: string,
  post: UpdatePostInput
): Promise<PostWithReferencesDto> {
  const endpoint = `/api/posts/${postId}`;
  return await putJSON(endpoint, "/api/posts/${postId}", post);
}

export async function deletePost(postId: string): Promise<void> {
  const endpoint = `/api/posts/${postId}`;
  return await deleteAPI(endpoint, "/api/posts/${postId}");
}

export async function likePost(postId: string): Promise<void> {
  const endpoint = `/api/posts/like/${postId}`;
  return await postJSON(endpoint, "/api/posts/like/${postId}");
}

export async function uploadPostAttachment(
  postId: string,
  files: File[],
  mode: AttachmentMode
): Promise<ModuleDto> {
  const endpoint = `/api/posts/${postId}/upload`;

  return await uploadAPI(endpoint, "/api/posts/${postId}/upload", files, {
    mode,
  });
}

export async function cloneAssetsToPost(
  postId: string,
  data: {
    assetIds: string[];
    mode: AttachmentMode;
  }
): Promise<ModuleDto> {
  const endpoint = `/api/posts/${postId}/clone`;

  return postJSON(endpoint, "/api/posts/${postId}/clone", data);
}

export async function deletePostAttachment(assetId: string): Promise<PostDto> {
  const endpoint = `/api/posts/attachment/${assetId}`;
  return await deleteAPI(endpoint, "/api/posts/attachment/${assetId}");
}

export async function uploadVideoToPost(
  postId: string,
  params: AddYouTubeVideoInput
): Promise<MediaItemDto> {
  const endpoint = `/api/posts/${postId}/video/upload`;
  return await postJSON(endpoint, "/api/posts/${postId}/video/upload", params);
}

export async function updatePostVideo(
  mediaItemId: string,
  params: UpdateYouTubeVideoInput
): Promise<MediaItemDto> {
  const endpoint = `/api/posts/video/${mediaItemId}`;
  return await putJSON(endpoint, "/api/posts/video/${mediaItemId}", params);
}

export async function deletePostVideo(
  mediaItemId: string
): Promise<MediaItemDto> {
  const endpoint = `/api/posts/video/${mediaItemId}`;
  return await deleteAPI(endpoint, "/api/posts/video/${mediaItemId}");
}

// Comments
export async function addComment(
  comment: NewCommentInput
): Promise<CommentDto> {
  const endpoint = `/api/comments/${comment.referenceType}/${comment.referenceId}`;
  return await postJSON(
    endpoint,
    "/api/comments/${comment.referenceType}/${comment.referenceId}",
    { text: comment.text }
  );
}

export async function updateComment(
  commentId: string,
  comment: UpdateCommentInput
): Promise<CommentDto> {
  const endpoint = `/api/comments/${commentId}`;
  return await putJSON(endpoint, "/api/comments/${commentId}", comment);
}

export async function getCommentsByReferenceId(
  referenceId: string,
  params: {
    limit: number;
    cursorId?: string;
  },
  referenceType: CommentReferenceType
): Promise<CommentDto[]> {
  const endpoint = `/api/comments/${referenceType}/${referenceId}`;
  return await getJSON(
    endpoint,
    "/api/comments/${referenceType}/${referenceId}",
    params
  );
}

export async function deleteComment(commentId: string): Promise<void> {
  const endpoint = `/api/comments/${commentId}`;
  return await deleteAPI(endpoint, "/api/comments/${commentId}");
}

export async function sendAnswerMessages(
  commentIds: string[]
): Promise<CommentDto[]> {
  const endpoint = `/api/comments/messages/send`;
  return await postJSON(endpoint, "/api/comments/messages/send", {
    commentIds,
  });
}

export async function getMessageDetails(
  commentId: string
): Promise<MessageDetailsDto> {
  const endpoint = `/api/comments/details/${commentId}`;
  return await getJSON(endpoint, "/api/comments/details/${commentId}");
}

export async function uploadCommentAttachment(
  commentId: string,
  files: File[],
  mode: AttachmentMode
): Promise<CommentDto> {
  const endpoint = `/api/comments/${commentId}/upload`;

  return await uploadAPI(endpoint, "/api/comments/${commentId}/upload", files, {
    mode,
  });
}

export async function cloneAssetsToComment(
  commentId: string,
  data: {
    assetIds: string[];
  }
): Promise<CommentDto> {
  const endpoint = `/api/comments/${commentId}/clone`;

  return postJSON(endpoint, "/api/comments/${commentId}/clone", data);
}

export async function deleteCommentAttachment(
  assetId: string
): Promise<CommentDto> {
  const endpoint = `/api/comments/attachment/${assetId}`;
  return await deleteAPI(endpoint, "/api/comments/attachment/${assetId}");
}

// Reactions
export async function getReactionDetailsForPost(
  postId: string
): Promise<ReactionDetailsDto[]> {
  const endpoint = `/api/reactions/post/${postId}`;
  return await getJSON(endpoint, "/api/reactions/post/${postId}");
}

// Supplier list

export async function createSupplier(
  data: NewSupplierInput
): Promise<SupplierDto> {
  const endpoint = "/api/suppliers/";
  return await postJSON(endpoint, "/api/suppliers/", data);
}

export async function updateSupplier(
  id: string,
  data: UpdateSupplierInput
): Promise<SupplierDto> {
  const endpoint = `/api/suppliers/${id}`;
  return await putJSON(endpoint, "/api/suppliers/${id}", data);
}

export async function getMySuppliers(params: {
  offset: number;
  limit: number;
  filters?: SupplierFilters;
}): Promise<PaginatedDto<SupplierListItemDto>> {
  const endpoint = `/api/suppliers/`;
  return await getJSON(endpoint, "/api/suppliers/", params);
}

export async function getMySupplier(id: string): Promise<SupplierDto> {
  const endpoint = `/api/suppliers/${id}`;
  return await getJSON(endpoint, "/api/suppliers/${id}");
}

export async function getSupplierAttachments(
  supplierId: string
): Promise<AssetDto[]> {
  const endpoint = `/api/suppliers/${supplierId}/attachments`;
  return await getJSON(endpoint, "/api/suppliers/${supplierId}/attachments");
}

export async function cloneAttachmentsToSupplier({
  supplierId,
  assetIds,
}: {
  supplierId: string;
  assetIds: string[];
}): Promise<AssetDto[]> {
  const endpoint = `/api/suppliers/${supplierId}/clone`;
  return await postJSON<CloneAttachmentsInput>(
    endpoint,
    "/api/suppliers/${supplierId}/clone",
    { assetIds }
  );
}

export async function uploadAttachmentToSupplier({
  files,
  supplierId,
}: {
  files: File[];
  supplierId: string;
}): Promise<AssetDto[]> {
  const endpoint = `/api/suppliers/${supplierId}/upload`;
  return await uploadAPI(
    endpoint,
    "/api/suppliers/${supplierId}/upload",
    files
  );
}

export async function uploadSuppliersCsv({
  file,
}: {
  file: File;
}): Promise<number> {
  const endpoint = `/api/suppliers/createmany/`;
  return (await uploadAPI(
    endpoint,
    "/api/suppliers/createmany/",
    file
  )) as number;
}

export async function deleteSupplier(id: string): Promise<void> {
  const endpoint = `/api/suppliers/${id}`;
  return await deleteAPI(endpoint, "/api/suppliers/${id}");
}

export async function getSupplierContracts(
  supplierId: string
): Promise<SupplierContractDto[]> {
  const endpoint = `/api/suppliers/${supplierId}/contracts/`;
  return await getJSON(endpoint, "/api/suppliers/${supplierId}/contracts/");
}

export async function getSupplierContract(
  supplierId: string
): Promise<SupplierContractDto> {
  const endpoint = `/api/suppliers/contract/${supplierId}`;
  return await getJSON(endpoint, "/api/suppliers/contract/${supplierId}");
}

export async function createSupplierContract(
  data: NewSupplierContractInput
): Promise<SupplierContractDto> {
  const endpoint = "/api/suppliers/contract/";
  return await postJSON(endpoint, "/api/suppliers/contract/", data);
}

export async function updateSupplierContract(
  contractId: string,
  data: UpdateSupplierContractInput
): Promise<SupplierContractDto> {
  const endpoint = `/api/suppliers/contract/${contractId}`;
  return await putJSON(endpoint, "/api/suppliers/contract/${contractId}", data);
}

export async function deleteSupplierContract(
  contractId: string
): Promise<void> {
  const endpoint = `/api/suppliers/contract/${contractId}`;
  return await deleteAPI(endpoint, "/api/suppliers/contract/${contractId}");
}

export async function uploadAttachmentToContract({
  files,
  contractId,
}: {
  files: File[];
  contractId: string;
}): Promise<AnswerDto> {
  const endpoint = `/api/suppliers/contract/${contractId}/upload`;
  return await uploadAPI(
    endpoint,
    "/api/suppliers/contract/${contractId}/upload",
    files
  );
}

export async function cloneAttachmentsToContract({
  assetIds,
  contractId,
}: {
  contractId: string;
  assetIds: string[];
}): Promise<{}> {
  const endpoint = `/api/suppliers/contract/${contractId}/clone`;
  return await postJSON<CloneAttachmentsInput>(
    endpoint,
    "/api/suppliers/contract/${contractId}/clone",
    { assetIds }
  );
}

export async function deleteContractAttachment({
  assetId,
  contractId,
}: {
  assetId: string;
  contractId: string;
}): Promise<AnswerDto> {
  const endpoint = `/api/suppliers/contract/${contractId}/attachments/${assetId}`;
  return await deleteAPI(
    endpoint,
    "/api/suppliers/contract/${contractId}/attachments/${assetId}"
  );
}

/*
/// Supplier Products ///
*/

export async function getSupplierProduct(
  productId: string
): Promise<SupplierProductDetailsDto> {
  const endpoint = `/api/products/${productId}`;
  return await getJSON(endpoint, "/api/products/${productId}");
}

export async function getSupplierProducts(params: {
  offset: number;
  limit: number;
  filters?: Omit<SupplierProductFilter, "attributes"> & {
    attributes?: AttributeFilter[];
  };
}): Promise<PaginatedDto<SupplierProductDto>> {
  const endpoint = `/api/products`;
  return await getJSON(endpoint, "/api/products", params);
}

export async function createSupplierProduct(
  data: NewSupplierProductInput
): Promise<SupplierDto> {
  const endpoint = "/api/products/";
  return await postJSON(endpoint, "/api/products/", data);
}

export async function updateSupplierProduct(
  data: UpdateSupplierProductInput
): Promise<SupplierDto> {
  const endpoint = `/api/products/${data.supplierProductId}`;
  return await putJSON(endpoint, "/api/products/${id}", data);
}

export async function cloneAttachmentsToSupplierProduct({
  supplierProductId,
  assetIds,
}: {
  supplierProductId: string;
  assetIds: string[];
}): Promise<AssetDto[]> {
  const endpoint = `/api/products/${supplierProductId}/clone`;
  return await postJSON<CloneAttachmentsInput>(
    endpoint,
    "/api/products/${supplierProductId}/clone",
    { assetIds }
  );
}

export async function uploadAttachmentToSupplierProduct({
  files,
  supplierProductId,
}: {
  files: File[];
  supplierProductId: string;
}): Promise<AssetDto[]> {
  const endpoint = `/api/products/${supplierProductId}/upload`;
  return await uploadAPI(
    endpoint,
    "/api/products/${supplierProductId}/upload",
    files
  );
}

/*
/// SupplierGroups ///
*/

export async function getSuppliergroups(params: {
  offset: number;
  limit: number;
  filters?: SupplierGroupFilter;
}): Promise<PaginatedDto<SupplierGroupDto>> {
  const endpoint = `/api/suppliergroup`;
  return await getJSON(endpoint, "/api/suppliergroup", params);
}
export async function createSupplierGroup(
  data: NewSupplierGroupInput
): Promise<SupplierGroupDto> {
  const endpoint = "/api/suppliergroup/";
  return await postJSON(endpoint, "/api/suppliergroup/", data);
}

export async function connectSuppliersToGroups(
  data: ConnectSuppliersToGroupsInput
): Promise<void> {
  const endpoint = "/api/suppliergroup/connect_to_groups";
  return await putJSON(endpoint, "/api/suppliergroup/connect_to_groups", data);
}

export async function disconnectSuppliersFromGroups(
  data: DisonnectSuppliersFromGroupsInput
): Promise<void> {
  const endpoint = "/api/suppliergroup/disconnect_from_groups";
  return await putJSON(
    endpoint,
    "/api/suppliergroup/disconnect_from_groups",
    data
  );
}

export async function renameSupplierGroup({
  data,
  supplierGroupId,
}: {
  data: UpdateSupplierGroupInput;
  supplierGroupId: string;
}): Promise<SupplierGroupDto> {
  const endpoint = `/api/suppliergroup/rename/${supplierGroupId}`;
  return await putJSON(
    endpoint,
    "/api/suppliergroup/rename/${supplierGroupId}",
    data
  );
}

export async function deleteSupplierGroup(
  supplierGroupId: string
): Promise<void> {
  const endpoint = `/api/suppliergroup/${supplierGroupId}`;
  return await deleteAPI(endpoint, "/api/suppliergroup/${supplierGroupId}");
}

/*
/// Attributes ///
*/

export async function getAttributeSchema(
  modelType: AttributeModelType,
  query?: AttributeSchemaQuery
): Promise<AttributeTypeDto[]> {
  const endpoint = `/api/attributes/type/${modelType}`;
  return await getJSON(endpoint, "/api/attributes/type/${modelType}", query);
}

export async function getAttributeType(
  attributeTypeId: string
): Promise<AttributeTypeDto> {
  const endpoint = `/api/attributes/id/${attributeTypeId}`;
  return await getJSON(endpoint, "/api/attributes/id/${attributeTypeId}");
}

export async function createAttributeType(
  data: NewAttributeTypeInput
): Promise<AttributeTypeDto> {
  const endpoint = "/api/attributes/";
  return await postJSON(endpoint, "/api/attributes/", data);
}

export async function updateAttributeType({
  attributeTypeId,
  data,
}: {
  attributeTypeId: string;
  data: UpdateAttributeTypeInput;
}): Promise<AttributeTypeDto> {
  const endpoint = `/api/attributes/${attributeTypeId}`;
  return await putJSON(endpoint, "/api/attributes/${attributeTypeId}", data);
}

export async function deleteAttributeType(
  attributeTypeId: string
): Promise<void> {
  const endpoint = `/api/attributes/${attributeTypeId}`;
  return await deleteAPI(endpoint, "/api/attributes/${attributeTypeId}");
}

//// Lookup Tables ////

export async function getLookupTableGroups(
  modelType?: AttributeModelType
): Promise<AttributeLookupTableGroupDto[]> {
  const endpoint = `/api/attributes/lookup_tables/groups/${modelType || ""}`;
  return await getJSON(
    endpoint,
    '/api/lookup_tables/groups/${modelType || ""}'
  );
}

export async function getLookupTable(
  lookupTableId: string
): Promise<LookupTableDataDto> {
  const endpoint = `/api/attributes/lookup_tables/id/${lookupTableId}`;
  return await getJSON(endpoint, "/api/lookup_tables/id/${lookupTableId}");
}

/*
/// CERTIFICATES ///
*/
export async function getCertificateByAssetId(
  assetId: string
): Promise<CertificateDto> {
  const endpoint = `/api/certificates/assetId/${assetId}`;
  return await getJSON(endpoint, "/api/certificates/id/${assetId}");
}

export async function createCertificate(
  data: NewCertificateInput
): Promise<CertificateDto> {
  const endpoint = "/api/certificates/";
  return await postJSON(endpoint, "/api/certificates/", data);
}

export async function updateCertificate({
  certificateId,
  data,
}: {
  certificateId: string;
  data: UpdateCertificateInput;
}): Promise<CertificateDto> {
  const endpoint = `/api/certificates/${certificateId}`;
  return await putJSON(endpoint, "/api/certificates/${certificateId}", data);
}

export async function deleteCertificate(certificateId: string): Promise<void> {
  const endpoint = `/api/certificates/${certificateId}`;
  return await deleteAPI(endpoint, "/api/certificates/${certificateId}");
}

/*
/// NOTIFICATIONS ///
*/

export async function getNotifications(params: {
  isRead?: boolean;
  limit?: number;
  cursorId?: string;
}): Promise<NotificationsResponse> {
  const endpoint = `/api/notifications/`;
  return await getJSON(endpoint, "/api/notifications/", params);
}

export async function readNotification(
  notificationId: string,
  isRead: boolean
): Promise<void> {
  const endpoint = `/api/notifications/${notificationId}`;
  return await postJSON(endpoint, "/api/notifications/${notificationId}", {
    isRead,
  });
}

export async function deleteNotification(
  notificationId: string
): Promise<void> {
  const endpoint = `/api/notifications/${notificationId}`;
  return await deleteAPI(endpoint, "/api/notifications/${notificationId}");
}

export async function uploadImage({
  uploadImageProps,
  file,
}: {
  uploadImageProps: NewImageInput;
  file: File;
}): Promise<string> {
  const endpoint = `/api/images/`;

  return await uploadAPI(endpoint, "/api/images/", file, {
    imageDetails: { ...uploadImageProps },
  });
}

//// REPORTS ////

export async function getRfpExcelReport({
  rfpId,
}: {
  rfpId: string;
}): Promise<Blob> {
  const endpoint = `/api/reports/report_type/rfx_excel/rfx/${rfpId}`;
  return await fileGET(
    endpoint,
    "/api/reports/report_type/rfx_excel/rfx/${rfpId}"
  );
}

export async function getRfpResponseExcelReport({
  rfpId,
  rfpResponseId,
}: {
  rfpId: string;
  rfpResponseId?: string;
}): Promise<Blob> {
  const endpoint = `/api/reports/report_type/rfx_excel/rfx/${rfpId}/response/${
    rfpResponseId || ""
  }`;
  return await fileGET(
    endpoint,
    '/api/reports/report_type/rfx_excel/rfx/${rfpId}/response/${rfpResponseId || ""}'
  );
}

export async function getRfpResponseWordReport({
  rfpId,
  pageSettings,
}: {
  rfpId: string;
  pageSettings: ReportPageSettings;
}): Promise<Blob> {
  const endpoint = `/api/reports/report_type/rfx_word/rfx/${rfpId}`;
  return await fileGET(
    endpoint,
    "/api/reports/report_type/rfx_word/rfx/${rfpId}",
    pageSettings
  );
}

export async function getSupplierExcelReport({
  filters,
}: {
  filters?: SupplierFilters;
}): Promise<Blob> {
  const endpoint = `/api/reports/report_type/supplier`;
  return await fileGET(endpoint, "/api/reports/report_type/supplier", {
    ...filters,
  });
}

export async function getProductsExcelReport({
  filters,
}: {
  filters?: SupplierProductFilter;
}): Promise<Blob> {
  const endpoint = `/api/reports/report_type/products`;
  return await fileGET(endpoint, "/api/reports/report_type/products", {
    ...filters,
  });
}

export async function getQuestionsExcelReport({
  parentModelType,
  parentId,
}: {
  parentModelType?: QuestionParentModelType;
  parentId?: string;
}): Promise<Blob> {
  const endpoint =
    parentModelType && parentId
      ? `/api/reports/report_type/questions/${parentModelType}/${parentId}`
      : `/api/reports/report_type/questions`;
  return await fileGET(endpoint, "/api/reports/report_type/questions");
}

export async function getRfxJsonExport({
  rfxId,
}: {
  rfxId: string;
}): Promise<Blob> {
  const endpoint = `/api/reports/report_type/rfx/${rfxId}`;
  return await fileGET(endpoint, "/api/reports/report_type/rfx/${rfxId}");
}

export async function importRfxJson({
  rfxInput,
}: {
  rfxInput: JSON;
}): Promise<RfpDto> {
  const endpoint = `/api/rfp/import/json`;
  return await postJSON(endpoint, "/api/rfp/import/json", { body: rfxInput });
}

//TODO: This is a complete mess. Refactor or remove ASAP
export async function getLookupTableWordReport({
  lookupTableId,
}: {
  lookupTableId: string;
}): Promise<Blob> {
  const endpoint = `/api/attributes/report/word_report/table/${lookupTableId}`;
  return await fileGET(
    endpoint,
    "/api/attributes/report/word_report/table/${lookupTableId}"
  );
}

export async function getLookupTableExcelReport({
  lookupTableId,
}: {
  lookupTableId: string;
}): Promise<Blob> {
  const endpoint = `/api/attributes/report/excel_report/table/${lookupTableId}`;
  return await fileGET(
    endpoint,
    "/api/attributes/report/excel_report/table/${lookupTableId}"
  );
}

//// MATRIX ////

export async function getMatrices(params: {
  offset: number;
  limit: number;
  accessType: MatrixAccessType;
}): Promise<PaginatedDto<MatrixDto>> {
  const endpoint = `/api/matrices`;
  return await getJSON(endpoint, "/api/matrices", params);
}

export async function getMatrix(matrixId: string): Promise<MatrixDto> {
  const endpoint = `/api/matrices/${matrixId}`;
  return await getJSON(endpoint, "/api/matrices/${matrixId}");
}

export async function createMatrix({
  newMatrix,
}: {
  newMatrix: NewMatrixInput;
}): Promise<MatrixDto> {
  const endpoint = `/api/matrices/`;
  return await postJSON(endpoint, "/api/matrices/", newMatrix);
}

export async function updateMatrix({
  matrixId,
  ...matrixData
}: UpdateMatrixInput): Promise<MatrixDto> {
  const endpoint = `/api/matrices/${matrixId}`;
  return await putJSON(endpoint, "/api/matrices/${matrixId}", matrixData);
}

export async function deleteMatrix({
  matrixId,
  forceDelete,
}: {
  matrixId: string;
  forceDelete?: boolean;
}): Promise<void> {
  const endpoint = `/api/matrices/${matrixId + (forceDelete ? "/true" : "")}`;
  return await deleteAPI(endpoint, "/api/matrices/${matrixId}");
}

export async function importMatrixData({
  matrixId,
  data,
}: {
  matrixId?: string;
  data: ImportMatrixInput;
}): Promise<MatrixDto> {
  const endpoint = `/api/matrices/import/${matrixId || ""}`;
  return await postJSON(
    endpoint,
    '/api/matrices/import/${matrixId || ""}',
    data
  );
}

///// MATRIX COLUMNS /////
export async function createMatrixColumn({
  newMatrixColumn,
}: {
  newMatrixColumn: InsertMatrixColumnInput;
}): Promise<MatrixColumnDto> {
  const endpoint = `/api/matrices/column`;
  return await postJSON(endpoint, "/api/matrices/column", newMatrixColumn);
}

export async function updateMatrixColumn({
  id,
  ...data
}: UpdateMatrixColumnInput): Promise<MatrixColumnDto> {
  const endpoint = `/api/matrices/column/${id}`;
  return await putJSON(
    endpoint,
    "/api/matrices/column/${matrixColumnId}",
    data
  );
}

export async function deleteMatrixColumns({
  matrixColumnIds,
  forceDelete,
}: {
  matrixColumnIds: string[];
  forceDelete?: boolean;
}): Promise<void> {
  const endpoint = `/api/matrices/column/delete`;
  return await postJSON(endpoint, "/api/matrices/column/delete", {
    matrixColumnIds,
    forceDelete,
  });
}

///// MATRIX ROWS /////
export async function insertMatrixRowByAnswerId({
  answerId,
}: {
  answerId: string;
}): Promise<MatrixRowDto> {
  const endpoint = `/api/matrices/row/answer/${answerId}`;
  return await postJSON(endpoint, "/api/matrices/row/answer/${answerId}");
}

export async function insertMatrixRowByMatrixId({
  matrixId,
}: {
  matrixId: string;
}): Promise<MatrixRowDto> {
  const endpoint = `/api/matrices/row/matrix/${matrixId}`;
  return await postJSON(endpoint, "/api/matrices/row/matrix/${matrixId}");
}

export async function updateMatrixRow({
  matrixRowId,
  data,
}: {
  matrixRowId: string;
  data: InsertOrUpdateMatrixCellInput[];
}): Promise<MatrixRowDto> {
  const endpoint = `/api/matrices/row/${matrixRowId}`;
  return await putJSON(endpoint, "/api/matrices/row/${matrixRowId}", data);
}

export async function deleteMatrixRows(matrixRowIds: string[]): Promise<void> {
  const endpoint = `/api/matrices/row/delete`;
  return await postJSON(endpoint, "/api/matrices/row/delete", {
    matrixRowIds,
  });
}
///// MATRIX CELLS /////

export async function getMatrixCellsByAnswerId(
  answerId: string
): Promise<MatrixDataDto> {
  const endpoint = `/api/matrices/cells/answer/${answerId}`;
  return await getJSON(endpoint, "/api/matrices/cells/answer/${answerId}");
}

export async function getMatrixCellsByMatrixId(
  matrixId: string
): Promise<MatrixDataDto> {
  const endpoint = `/api/matrices/cells/matrix/${matrixId}`;
  return await getJSON(endpoint, "/api/matrices/cells/matrix/${matrixId}");
}

export async function getMatrixDataExcelReport({
  matrixIds,
  templateAssetId,
}: {
  matrixIds: string[];
  templateAssetId?: string;
}): Promise<Blob> {
  const endpoint = `/api/reports/report_type/matrix_data/${
    templateAssetId ? `${templateAssetId}` : ""
  }`;
  return await fileGET(
    endpoint,
    "/api/reports/report_type/matrix_data/matrix/${filters.matrixId}",
    { matrixIds }
  );
}

//TODO: optional matrixId param
export async function uploadTemplateFileToMatrix({
  file,
  matrixId,
}: {
  file: File;
  matrixId?: string;
}): Promise<AssetDto> {
  const endpoint = `/api/matrices/report_template/upload/${matrixId}/`;
  return await uploadAPI(
    endpoint,
    "/api/matrices/report_template/upload/${matrixId}/",
    file
  );
}

//TODO: optional matrixId param
export async function cloneTemplateFileToMatrix({
  matrixId,
  assetIds,
}: {
  matrixId?: string;
  assetIds: string[];
}): Promise<AssetDto[]> {
  const endpoint = `/api/matrices/report_template/clone/${matrixId}`;
  return await postJSON<CloneAttachmentsInput>(
    endpoint,
    "/api/matrices/report_template/clone/${matrixId}",
    { assetIds }
  );
}

export async function getCSRDImpactRows(): Promise<CSRDImpactRowDto[]> {
  const endpoint = `/api/csrd/impact_rows`;
  return await getJSON(endpoint, "/api/csrd/impact_rows");
}

export async function createCSRDImpactRow(): Promise<CSRDImpactRowDto> {
  const endpoint = "/api/csrd/impact_rows/";
  return await postJSON(endpoint, "/api/csrd/impact_rows/");
}

export async function updateCSRDImpactRow({
  rowId,
  data,
}: {
  rowId: string;
  data: UpdateCSRDImpactRowInput;
}): Promise<CSRDImpactRowDto> {
  const endpoint = `/api/csrd/impact_rows/${rowId}`;
  return await putJSON(endpoint, "/api/csrd/impact_rows/${rowId}", data);
}

export async function deleteCSRDImpactRow(rowId: string): Promise<void> {
  const endpoint = `/api/csrd/impact_rows/${rowId}`;
  return await deleteAPI(endpoint, "/api/csrd/impact_rows/${rowId}");
}

export async function getCsrdDocuments(
  assessmentAccessType: AssessmentAccessType
): Promise<CSRDDocumentDto[]> {
  const endpoint = `/api/csrd/documents/${assessmentAccessType}`;
  return await getJSON(endpoint, "/api/csrd/documents/${assessmentAccessType}");
}

export async function getCSRDMaterialityReport(): Promise<Blob> {
  const endpoint = `/api/reports/report_type/csrd/impacts`;
  return await fileGET(endpoint, "/api/reports/report_type/csrd/impacts");
}

export async function getCsrdFolders(): Promise<FolderDto[]> {
  const endpoint = `/api/csrd/folders`;
  return await getJSON(endpoint, "/api/csrd/folders");
}

///// QUESTION LABLES /////

export async function getQuestionLabels(): Promise<QuestionLabelDto[]> {
  const endpoint = `/api/question_labels`;
  return await getJSON(endpoint, "/api/question_labels");
}

export async function createQuestionLabel({
  data,
}: {
  data: NewQuestionLabelInput;
}): Promise<QuestionLabelDto> {
  const endpoint = `/api/question_labels`;
  return await postJSON(endpoint, "/api/question_labels", data);
}

export async function updateQuestionLabel({
  labelId,
  label,
}: {
  labelId: string;
  label: UpdateQuestionLabelInput;
}): Promise<QuestionLabelDto> {
  const endpoint = `/api/question_labels/update/${labelId}`;
  return await putJSON(endpoint, "/api/question_labels/update/${labelId}", {
    label,
  });
}

export async function deleteQuestionLabel(labelId: string): Promise<void> {
  const endpoint = `/api/question_labels/${labelId}`;
  return await deleteAPI(endpoint, "/api/question_labels/${labelId}");
}

export async function getQuestionLabelWithRelatedQuestions({
  labelId,
  filters,
}: {
  labelId: string;
  filters?: QuestionLabelFilter;
}): Promise<QuestionLabelWithQuestionsDto> {
  const endpoint = `/api/question_labels/${labelId}`;
  return await getJSON(endpoint, "/api/question_labels/${labelId}", filters);
}

export async function connectQuestionsToLabels(
  data: ConnectLabelsToQuestionsInput
): Promise<void> {
  const endpoint = "/api/question_labels/connect_questions";
  return await putJSON(
    endpoint,
    "/api/question_labels/connect_questions",
    data
  );
}

export async function disconnectQuestionsFromLabel(
  data: ConnectLabelsToQuestionsInput
): Promise<void> {
  const endpoint = "/api/question_labels/disconnect_questions";
  return await putJSON(
    endpoint,
    "/api/question_labels//disconnect_questions",
    data
  );
}

///// DASHBOARD /////
export async function getDashboardItems(): Promise<DashboardItemDto[]> {
  const endpoint = `/api/dashboard`;
  return await getJSON(endpoint, "/api/dashboard");
}

export async function addDashboardItem({
  data,
}: {
  data: NewDashboardItemInput;
}): Promise<DashboardItemDto> {
  const endpoint = `/api/dashboard`;
  return await postJSON(endpoint, "/api/dashboard", data);
}

export async function deleteDashboardItem(
  dashboardItemId: string
): Promise<void> {
  const endpoint = `/api/dashboard/${dashboardItemId}`;
  return await deleteAPI(endpoint, "/api/dashboard/${dashboardItemId}");
}
