import React, { useMemo } from "react";

import { Grid2 } from "@mui/material";
import PassportCard from "components/PassportCard";
import { passportDetail } from "shared/constants/passportsDetails";
import { PassportDto } from "shared/model";

type PassportsListProps = {
  passports: PassportDto[];
  isOwner: boolean;
  companyId: string;
};

export default function PassportsList({
  passports,
  isOwner,
  companyId,
}: PassportsListProps) {
  const passportsWithAvatar = useMemo(() => {
    function getDisplayedPassports(allPassports: PassportDto[]) {
      return allPassports.filter((passport) => passport.show);
    }

    return getDisplayedPassports(passports).map((passport) => ({
      ...passport,
      image: passportDetail[passport.type.id].image,
      description: passportDetail[passport.type.id].description,
      clarification: passportDetail[passport.type.id].clarification,
    }));
  }, [passports]);

  return (
    <Grid2 container direction="column" spacing={2}>
      {passportsWithAvatar.map((passport) => (
        <Grid2 key={passport.type.id}>
          <PassportCard
            key={passport.id}
            passport={passport}
            title={passport.type.name}
            description={passport.description}
            clarification={passport.clarification}
            avatar={passport.image}
            isOwner={isOwner}
            passportId={passport.type.id}
            companyId={companyId}
          />
        </Grid2>
      ))}
    </Grid2>
  );
}
