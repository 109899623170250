import React from "react";

import { Menu, MenuItem } from "@mui/material";
import SvgIcon from "components/ui/SvgIcon";
import { classes, style } from "typestyle";
import { NestedCSSProperties } from "typestyle/lib/types";

import styles from "./WithKebabMenuStyles";

export type KebabAction = {
  text: string;
  onClick: () => void;
};

// adds an absolutely positioned kebab menu to component, you can set the location using the position prop
// you can change the class of the root html element with the className prop
// todo? add support for different sizes?
export default function WithKebabMenu({
  children,
  actions,
  position,
  className,
  svgIcon,
}: {
  children: React.ReactNode;
  actions: KebabAction[];
  position?: Pick<NestedCSSProperties, "right" | "left" | "top" | "bottom">;
  className?: string;
  svgIcon?: string;
}) {
  const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);
  const popoverOpen = Boolean(anchorEl);
  const handlePopoverOpenerClick = (
    event: React.MouseEvent<Element, MouseEvent>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={classes(styles.root, className)}>
      {children}
      <div className={classes(styles.kebabMenuContainer, style(position))}>
        <SvgIcon
          focusable
          src={svgIcon || "/icons/more_vert_icon.svg"}
          onClick={handlePopoverOpenerClick}
          className={styles.icon}
        />
        <Menu
          open={popoverOpen}
          anchorEl={anchorEl}
          onClose={handlePopoverClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          {actions.map((action) => {
            return (
              <MenuItem
                key={action.text}
                onClick={() => {
                  action.onClick();
                  handlePopoverClose();
                }}
              >
                {action.text}
              </MenuItem>
            );
          })}
        </Menu>
      </div>
    </div>
  );
}
