import React, { Key, MouseEvent, useMemo } from "react";
import { useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import queryKeys from "reactQuery/queryKeys";

import { Divider } from "@mui/material";
import { ROUTES } from "@pulsemarket/constants";
import { FeatureFlagEnum } from "@pulsemarket/constants";
import SvgIcon from "components/ui/SvgIcon";
import Tooltip, { purpleTooltipProps } from "components/ui/Tooltip";
import { usePublicVisitorContext } from "contexts/PublicVisitorContext/PublicVisitorContext";
import { useGetAuthToken } from "hooks/queries/useAuth";
import { DEFAULT_PAGESIZE } from "hooks/queries/useMySuppliers";
import useOwnCompany from "hooks/queries/useOwnCompany";
import useFeatureEnabled from "hooks/useFeatureEnabled";
import usePassportFeatureEnabled from "hooks/usePassportFeatureEnabled";
import { getAssetLabels, getMySuppliers } from "shared/client";
import { clickableClass } from "shared/styles/classes";
import { classes } from "typestyle";

import styles from "./SideMenuStyles";

export interface SideMenuProps {
  isOpen: boolean;
  toggleMenu: () => void;
  closeMenu: () => void;
}

enum MenuItemGroup {
  Resources = "Resources",
  Settings = "Settings",
}

type MenuItem = JSX.IntrinsicElements["div"] & {
  group: MenuItemGroup;
  label: string;
  icon?: JSX.Element;
  command?: () => void;
  className?: string;
  showLabel?: boolean;
  hidden?: boolean;
};

function FullWidthDivider({ index }: { index: number }) {
  return (
    <>
      <Divider variant="fullWidth" className={styles.divider} />
    </>
  );
}

const getMenuItem = ({ showLabel, command, ...item }: MenuItem, index: Key) => {
  return !item.hidden ? (
    <Tooltip
      key={item.label + index}
      title={item.label}
      placement="right"
      disableHoverListener={showLabel}
      {...purpleTooltipProps}
      hideOnBlur={false}
    >
      <div
        {...item}
        onClick={command}
        className={classes(styles.menuItemClass, item.className)}
      >
        <div className={styles.iconContainerClass}>{item.icon}</div>

        {showLabel && (
          <span className={styles.itemLabelClass}>{item.label}</span>
        )}
      </div>
    </Tooltip>
  ) : (
    <React.Fragment key={item.label + index} />
  );
};

export default function SideMenu({
  isOpen,
  toggleMenu,
  closeMenu,
}: SideMenuProps) {
  const history = useHistory();
  const {
    data: ownCompany,
    isLoading: companyLoading,
    isError: companyError,
  } = useOwnCompany();
  const { data: dataRoomEnabled } = useFeatureEnabled({
    featureName: FeatureFlagEnum.dataroom,
  });
  const { data: dataManagementEnabled } = useFeatureEnabled({
    featureName: FeatureFlagEnum.dataManagement,
  });
  const { data: suppliersEnabled } = useFeatureEnabled({
    featureName: FeatureFlagEnum.mySuppliers,
  });
  const passportEnabled = usePassportFeatureEnabled();

  const { data: csrdEnabled } = useFeatureEnabled({
    featureName: FeatureFlagEnum.csrd,
  });

  const client = useQueryClient();

  const { viewedDataRoomId, viewedCompanyId: viewedDataRoomCompanyId } =
    usePublicVisitorContext();

  const { data, isLoading } = useGetAuthToken();

  const displayItems: JSX.Element[] = useMemo(() => {
    if (isLoading) {
      return [];
    }
    const privateItems: MenuItem[] = [
      {
        group: MenuItemGroup.Resources,
        label: "Dashboard",
        showLabel: isOpen,
        icon: (
          <SvgIcon
            src="/icons/dashboard_icon.svg"
            className={styles.itemIconClass}
          />
        ),
        command: () => history.push("/"),
      },
      ...((csrdEnabled
        ? [
            {
              group: MenuItemGroup.Resources,
              label: "CSRD",
              showLabel: isOpen,
              icon: (
                <SvgIcon
                  src="/icons/certifications_icon.svg"
                  className={styles.itemIconClass}
                />
              ),
              command: () => history.push(ROUTES.CSRD),
            },
          ]
        : []) as MenuItem[]),
      ...((suppliersEnabled
        ? [
            {
              group: MenuItemGroup.Resources,
              label: "Supplier Profiling",
              showLabel: isOpen,
              icon: (
                <SvgIcon
                  src="/icons/supplier_management_icon.svg"
                  className={styles.itemIconClass}
                />
              ),
              onMouseEnter: () => {
                client.prefetchQuery(
                  queryKeys.mySuppliers(ownCompany!.id),
                  () => getMySuppliers({ limit: DEFAULT_PAGESIZE, offset: 0 })
                );
              },
              command: () => history.push(ROUTES.MY_SUPPLIERS),
            },
          ]
        : []) as MenuItem[]),
      {
        group: MenuItemGroup.Resources,
        label: "Value Chain",
        showLabel: isOpen,
        icon: (
          <SvgIcon
            src="/icons/proposals_icon.svg"
            className={styles.itemIconClass}
          />
        ),
        command: () => history.push(ROUTES.RFP_MANAGEMENT_FOR_BUYERS),
      },
      ...((passportEnabled
        ? [
            {
              group: MenuItemGroup.Resources,
              label: "Pulse Passport",
              showLabel: isOpen,
              icon: (
                <SvgIcon
                  src="/icons/certifications_icon.svg"
                  className={styles.itemIconClass}
                />
              ),
              command: () => history.push(ROUTES.PASSPORT_MANAGEMENT),
            },
          ]
        : []) as MenuItem[]),
      {
        group: MenuItemGroup.Resources,
        label: "Assets",
        showLabel: isOpen,
        icon: (
          <SvgIcon
            src="/icons/assets_icon.svg"
            className={styles.itemIconClass}
          />
        ),
        command: () => history.push(`/assets/${ownCompany?.assetRepositoryId}`),
        onMouseEnter: () => {
          client.prefetchQuery(queryKeys.assetLabels, () =>
            getAssetLabels(ownCompany!.id)
          );
        },
      },
      ...((dataRoomEnabled
        ? [
            {
              group: MenuItemGroup.Resources,
              label: "Data Room",
              showLabel: isOpen,
              icon: (
                <SvgIcon
                  src="/icons/data-room.svg"
                  className={styles.itemIconClass}
                />
              ),
              command: () => history.push("/datarooms"),
            },
          ]
        : []) as MenuItem[]),
      {
        group: MenuItemGroup.Resources,
        label: "Supplier Search",
        showLabel: isOpen,
        icon: (
          <SvgIcon
            src="/icons/vendor_search_icon.svg"
            className={styles.itemIconClass}
          />
        ),
        command: () => history.push("/vendorsearch/suppliersearch"),
        hidden: true,
      },
      {
        group: MenuItemGroup.Resources,
        hidden: !ownCompany || !!companyLoading || !!companyError,
        label: "Pulse Page",
        showLabel: isOpen,
        icon: (
          <SvgIcon
            src="/icons/pulse_page_icon.svg"
            className={styles.itemIconClass}
          />
        ),
        command: () => history.push(`/company/${ownCompany!.id}`),
      },
      ...((dataManagementEnabled
        ? [
            {
              group: MenuItemGroup.Resources,
              hidden: !ownCompany || !!companyLoading || !!companyError,
              label: "Data Management",
              showLabel: isOpen,
              icon: (
                <SvgIcon
                  src="/icons/data_management.svg"
                  className={styles.itemIconClass}
                />
              ),
              command: () => history.push(ROUTES.DATA_MANAGEMENT.my_data()),
            },
          ]
        : []) as MenuItem[]),
      {
        group: MenuItemGroup.Settings,
        label: "Company Account",
        showLabel: isOpen,
        icon: (
          <SvgIcon
            src="/icons/company_account.svg"
            className={styles.itemIconClass}
          />
        ),
        command: () => history.push(`/company/edit/info`),
      },
      {
        group: MenuItemGroup.Settings,
        label: "My Account",
        showLabel: isOpen,
        icon: (
          <SvgIcon
            src="/icons/personal_profile.svg"
            className={styles.itemIconClass}
          />
        ),
        command: () => history.push(`/profile/edit/info`),
      },
    ];

    const publicItems: MenuItem[] = [
      ...((dataRoomEnabled && viewedDataRoomId
        ? [
            {
              group: MenuItemGroup.Resources,
              label: "Data Room",
              showLabel: isOpen,
              icon: (
                <SvgIcon
                  src="/icons/data-room.svg"
                  className={styles.itemIconClass}
                />
              ),
              command: () => history.push(`/datarooms/${viewedDataRoomId}`),
            },
          ]
        : []) as MenuItem[]),
      {
        group: MenuItemGroup.Resources,
        label: "Pulse Page",
        showLabel: isOpen,
        icon: (
          <SvgIcon
            src="/icons/pulse_page_icon.svg"
            className={styles.itemIconClass}
          />
        ),
        command: () => history.push(`/company/${viewedDataRoomCompanyId}`),
      },
    ];

    const items: MenuItem[] = !!data?.userId ? privateItems : publicItems;

    // /company/:companyId
    return Object.keys(MenuItemGroup).map((g, i) => {
      return (
        <div
          key={g + i}
          className={styles.groupContainer}
          style={
            g === MenuItemGroup.Settings
              ? { marginTop: "auto", height: "auto" }
              : {}
          }
        >
          <React.Fragment key={g + i}>
            <FullWidthDivider index={i} />

            {items.filter((i) => i !== null && i.group === g).map(getMenuItem)}
          </React.Fragment>
        </div>
      );
    });
  }, [
    isLoading,
    isOpen,
    suppliersEnabled,
    passportEnabled,
    dataRoomEnabled,
    ownCompany,
    companyLoading,
    companyError,
    dataManagementEnabled,
    viewedDataRoomId,
    data?.userId,
    history,
    client,
    viewedDataRoomCompanyId,
    csrdEnabled,
  ]);

  const menuItemSelected = (e: MouseEvent) => {
    e.stopPropagation();
    closeMenu();
  };

  const burgerMenuSelected = (e: MouseEvent) => {
    e.stopPropagation();
    toggleMenu();
  };
  return (
    <div
      className={classes(
        styles.sideMenuClass,
        isOpen
          ? styles.sideMenuStatusClassOpen
          : styles.sideMenuStatusClassClose
      )}
      onClick={toggleMenu}
    >
      <div
        className={
          isOpen ? styles.menuWrapperClassOpen : styles.menuWrapperClassClose
        }
        onClick={menuItemSelected}
      >
        {isOpen ? (
          <div
            onClick={() => history.push("/")}
            className={classes(styles.menuPulseIconClass, styles.headerClass)}
          >
            <div className={styles.pulseIconContainerClass}>
              <img
                className={styles.pulseIcon}
                src="/img/sidemenu_pulse_logo_light.png"
                alt="pulse icon"
              />
            </div>
            <div
              className={classes(
                clickableClass,
                isOpen
                  ? styles.closeIconContainerClassOpen
                  : styles.closeIconContainerClassClose
              )}
              onClick={menuItemSelected}
            >
              <SvgIcon
                src="/icons/back_icon.svg"
                className={classes(styles.itemIconClass, styles.fixedIconSize)}
              />
            </div>
          </div>
        ) : (
          <div
            onClick={burgerMenuSelected}
            className={styles.burgerButtonContainer}
          >
            <div className={styles.burgerButton}>
              <SvgIcon
                src="/icons/menu_icon.svg"
                className={styles.itemIconClass}
              />
            </div>
          </div>
        )}
        {displayItems}
      </div>
    </div>
  );
}
