import React from "react";

import SvgIcon from "components/ui/SvgIcon";
import type { TooltipProps } from "components/ui/Tooltip";
import Tooltip, { TooltipWrapper } from "components/ui/Tooltip";
import { classes } from "typestyle";

import styles from "./InformationIconStyles";

export type InformationIconProps = JSX.IntrinsicAttributes &
  Omit<TooltipProps, "children"> & {
    iconClassName?: string;
    wrapperClassName?: string;
    isWarning?: boolean;
  };

export default function InformationIcon({
  iconClassName,
  wrapperClassName,
  isWarning = false,
  ref,
  ...props
}: InformationIconProps) {
  return (
    <Tooltip {...props}>
      <TooltipWrapper className={wrapperClassName}>
        <SvgIcon
          src={
            isWarning ? "/icons/alert_icon.svg" : "/icons/information_icon.svg"
          }
          className={classes(
            styles.icon,
            isWarning ? styles.warningIcon : styles.informationIcon,
            iconClassName
          )}
        />
      </TooltipWrapper>
    </Tooltip>
  );
}
