import React from "react";

import { DialogTitle, Grid2, IconButton } from "@mui/material";
import SvgIcon from "components/ui/SvgIcon";
import { classes } from "typestyle";

import styles from "./ModalHeader.styles";

export const dialogTitleId = "dialog-title";

type ModalHeaderProps = {
  children?: React.ReactNode;
  onClose?: () => void;
  className?: string;
  hideCloseButton?: boolean;
};

// todo delete old ModalHeader

export default function ModalHeader({
  children,
  onClose = () => {},
  className,
  hideCloseButton,
}: ModalHeaderProps) {
  return (
    <DialogTitle className={classes(styles.titleContainer, className)}>
      <Grid2
        container
        wrap="nowrap"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid2>
          {children && (
            <h6 className={styles.title} id={dialogTitleId}>
              {children}
            </h6>
          )}
        </Grid2>
        {!hideCloseButton && (
          <Grid2>
            <IconButton onClick={onClose} size="small">
              <SvgIcon
                src="/icons/close_icon.svg"
                className={styles.closeIcon}
              />
            </IconButton>
          </Grid2>
        )}
      </Grid2>
    </DialogTitle>
  );
}
