import React from "react";
import { Link, LinkProps } from "react-router-dom";

import { Breadcrumbs } from "@mui/material";
import SvgIcon from "components/ui/SvgIcon";
import { Pair } from "shared/model";
import { classes, style } from "typestyle";

import styles from "./BreadcrumbStyles";

interface BreadcrumbProps {
  path: LinkProps["to"];
  data: (Pair & { onClick?: () => void })[];
  disableNavigation?: boolean;
  icon?: {
    src: string;
    linkTo: LinkProps["to"];
    onClick?: () => void;
    color?: string;
  };
}

export default function PulseBreadcrumb({
  path,
  data,
  disableNavigation,
  icon,
}: BreadcrumbProps) {
  const links = data.map((item, index) => (
    <Link
      {...(index === data.length - 1 && { style: { fontWeight: 600 } })}
      key={item.id}
      to={typeof path === "string" ? `${path}/${item.id}` : path}
      onClick={(e) => {
        if (disableNavigation) {
          e.preventDefault();
        }
        item.onClick?.();
      }}
    >
      {item.name}
    </Link>
  ));
  if (icon) {
    links.unshift(
      <Link
        key={"svgIcon"}
        to={icon.linkTo}
        onClick={(e) => {
          if (disableNavigation) {
            e.preventDefault();
          }
          icon.onClick?.();
        }}
      >
        <SvgIcon
          src={icon.src}
          className={classes(style({ color: icon.color }), styles.svgIcon)}
        />
      </Link>
    );
  }
  return (
    <div className={styles.Breadcrumb}>
      <Breadcrumbs
        separator={
          <SvgIcon src="/icons/arrow_right.svg" className={styles.rightArrow} />
        }
      >
        {links}
      </Breadcrumbs>
    </div>
  );
}
