import { ReactNode } from "react";

import { Checkbox } from "@mui/material";
import { Identifiable } from "components/AssetRepository/types";
import Button, { ButtonProps } from "components/ui/Button";
import KebabMenu, { KebabMenuAction } from "components/ui/KebabMenu";
import { SelectableComponentProps } from "shared/utils/componentTypes";
import { classes } from "typestyle";

import styles from "./Card.styles";

type Props<T> = {
  className?: string;
  avatar?: string;
  backgroundImage?: string;
  defaultAvatar: string;
  title: string;
  children: ReactNode;
  buttons?: (ButtonProps & Identifiable)[];
  kebabMenuActions?: KebabMenuAction[];
} & SelectableComponentProps<T>;

function Card<T>({
  avatar,
  backgroundImage,
  defaultAvatar,
  title,
  className,
  children,
  buttons = [],
  onToggleSelect,
  disabled,
  kebabMenuActions,
  selected,
}: Props<T>) {
  return (
    <div className={classes(styles.root, className)}>
      {onToggleSelect && (
        <Checkbox
          size="small"
          className={styles.checkbox}
          disabled={typeof disabled === "function" ? disabled() : disabled}
          checked={Boolean(selected)}
          onChange={() => onToggleSelect()}
        />
      )}
      {kebabMenuActions && (
        <KebabMenu
          className={classes(styles.kebab, className)}
          actions={kebabMenuActions}
        />
      )}

      <div>
        {backgroundImage ? (
          <img
            className={styles.backgroundImage}
            src={backgroundImage}
            alt={`${title}_bakcground`}
          />
        ) : (
          <svg
            className={styles.illustration}
            width="100%"
            height="80"
            viewBox="0 0 275 80"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M275.015 -0.218746L-1.07149 -0.218734L-1.07153 47.2101C59.5877 90.4514 109 84.5983 166.76 60.848C214.09 77.1489 237.768 78.7452 275.015 60.848L275.015 -0.218746Z"
              fill="#F1F3F5"
            />
            <path
              d="M275.015 -10.541L-0.851562 -10.541L-0.851233 15.5405C59.808 58.7817 109.001 52.9287 166.761 29.1784C214.09 45.4793 237.769 47.0756 275.015 29.1784L275.015 -10.541Z"
              fill="#92D3D7"
            />
          </svg>
        )}
        <div className={styles.avatarContainer}>
          <img
            className={styles.avatar}
            src={avatar || defaultAvatar}
            alt="avatar"
          />
        </div>
      </div>

      <div className={styles.content}>
        <h3 className={classes(styles.title, disabled && styles.disabled)}>
          {title}
        </h3>

        {children}

        <div className={classes(styles.buttonContainer)}>
          {buttons.map((buttonProps) => (
            <Button
              key={buttonProps.id}
              size="small"
              fullWidth
              {...buttonProps}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default Card;
