import React from "react";

import { Grid2 } from "@mui/material";
import getField from "components/form/Field";
import Form from "components/form/Form";
import Button, { ButtonProps } from "components/ui/Button";
// import Select from "components/ui/FormSelect/FormSelect";
import { FormikConfig, FormikHelpers, useFormik } from "formik";
import { emailValidatorFn } from "shared/validators";
import { style } from "typestyle";

import sharedFormStyles from "components/CompanyInfo/forms/sharedStyles";

// import { PassportShareConfig } from "shared/model";

type PassportMetadata = {
  ownerName: string;
  jobTitle: string;
  email: string;
  // shareConfig?: PassportShareConfig;
};

type PassportMetadataFields = {
  ownerName?: string;
  jobTitle?: string;
  email?: string;
  // shareConfig?: PassportShareConfig;
};

type PassportFormProps = {
  disabled?: boolean;
  data: PassportMetadataFields;
  onSubmit: FormikConfig<PassportMetadata>["onSubmit"];
  /** https://www.figma.com/file/4lzs5MQRCeY8kZ1oqOrsix/Passport?node-id=29%3A49811&t=YbijlvkEbpK4d74h-0 */
  backButton?: ButtonProps;
  /** "Next" or "Save changes"  */
  saveButton: Omit<ButtonProps, "type">;
};

// const passportShareConfigOptions = Object.values(PassportShareConfig).map(
//   (config) => ({ id: config, name: config })
// );

const Field = getField<PassportMetadata>();

/**
 * Form to be used for filling metadata for a passport.
 */
export default function PassportForm({
  data,
  onSubmit,
  disabled,
  backButton,
  saveButton,
}: PassportFormProps) {
  const formik = useFormik<PassportMetadataFields>({
    initialValues: data,
    enableReinitialize: true,
    async onSubmit(values, helpers) {
      await onSubmit(
        values as PassportMetadata,
        helpers as FormikHelpers<PassportMetadata>
      );
    },
    validateOnMount: true, // because data is pre-filled
  });

  return (
    <Form formik={formik}>
      <Grid2 container spacing={3} className={sharedFormStyles.inputRow}>
        <Grid2 size={{ xs: 6 }}>
          <Field
            fullWidth
            name="ownerName"
            label="Response owner"
            disabled={disabled}
            required
          />
        </Grid2>
        <Grid2 size={{ xs: 6 }}>
          <Field
            fullWidth
            name="jobTitle"
            label="Job title"
            disabled={disabled}
            required
          />
        </Grid2>
      </Grid2>
      <Grid2 container className={sharedFormStyles.inputRow}>
        <Grid2 size={{ xs: 12 }}>
          <Field
            fullWidth
            name="email"
            label="Email"
            type="email"
            validate={(val) =>
              (!emailValidatorFn(val) && "Please provide a valid email") ||
              undefined
            }
            disabled={disabled}
            required
          />
        </Grid2>
      </Grid2>
      {/* <Grid2 container>
        <Field name="shareConfig" disabled={disabled} required>
          <Select
            options={passportShareConfigOptions}
            placeholder="Who would you like to share answers with?"
          />
        </Field>
      </Grid2> */}
      <Grid2
        container
        justifyContent="space-between"
        className={style({ marginTop: "44px" })}
      >
        <Grid2>{backButton && <Button {...backButton} />}</Grid2>
        <Grid2>
          {/* @ts-expect-error TS 5 upgrade todo */}
          <Button
            {...saveButton}
            disabled={!formik.isValid || formik.isSubmitting || disabled}
            isLoading={formik.isSubmitting}
            type="submit"
          />
        </Grid2>
      </Grid2>
    </Form>
  );
}
