import React from "react";

import { OutlinedTextFieldProps, TextField } from "@mui/material";
import { AppColors } from "@pulsemarket/constants";
import { classes, style } from "typestyle";

import styles from "./Input.styles";

type StringWithAutocomplete<T> = T | (string & Record<never, never>);
type AnyEnumKeysAsStrings<TEnumType> = keyof TEnumType;

export type InputProps = Omit<OutlinedTextFieldProps, "variant" | "color"> & {
  color?: StringWithAutocomplete<AnyEnumKeysAsStrings<typeof AppColors>>;
  helperTextExpectedLines?: number;
};

function getForcedColorClasses({
  value,
  color,
  required,
}: Pick<InputProps, "value" | "color" | "required">) {
  const hasValue = typeof value !== "undefined";
  const shouldBeColored = color && (hasValue || (required && !hasValue));

  const forcedColorClass =
    shouldBeColored &&
    style({
      $nest: {
        "&&&": {
          color: color,
        },
      },
    });

  const forcedBorderColorClass =
    shouldBeColored &&
    style({
      $nest: {
        "&&& .MuiOutlinedInput-notchedOutline": {
          borderColor: color,
        },
      },
    });

  return { forcedColorClass, forcedBorderColorClass };
}

/** Wrapper around Mui's Textfield "outline" variant.
 * https://www.figma.com/file/acH57GN2MTV9IYEgNbXzrw/Pulse-Library?type=design&node-id=11569-68456&mode=dev
 */
export default function Input({
  value,
  error,
  required,
  color,
  className,
  InputProps,
  InputLabelProps,
  helperTextExpectedLines = 1,
  ...props
}: InputProps) {
  const { forcedBorderColorClass, forcedColorClass } = getForcedColorClasses({
    value,
    color,
    required,
  });

  const helperTextPlaceholder = (
    <>
      {Array.apply(null, Array(helperTextExpectedLines)).map((_, line) => (
        <React.Fragment key={line}>
          &nbsp;
          <br />
        </React.Fragment>
      ))}
    </>
  );

  return (
    <TextField
      {...props}
      variant="outlined"
      value={value}
      required={required}
      error={error}
      className={classes(className)}
      helperText={
        typeof props.helperText !== "undefined"
          ? props.helperText
          : helperTextPlaceholder
      }
      classes={{
        ...props.classes,
        root: classes(forcedBorderColorClass, InputProps?.classes?.root),
      }}
      InputLabelProps={{
        ...InputLabelProps,
        classes: {
          ...InputLabelProps?.classes,
          shrink: classes(styles.labelShrink, InputLabelProps?.classes?.shrink),
          outlined: classes(
            styles.outlinedLabel,
            InputLabelProps?.classes?.outlined
          ),
          root: classes(
            styles.labelRoot,
            forcedColorClass,
            InputLabelProps?.classes?.root
          ),
          error: classes(styles.labelError, InputLabelProps?.classes?.error),
          focused: classes(
            styles.labelFocused,
            InputLabelProps?.classes?.focused
          ),
        },
      }}
      FormHelperTextProps={{
        ...props.FormHelperTextProps,
        classes: {
          ...props.FormHelperTextProps?.classes,
          root: classes(
            styles.helperText,
            props.FormHelperTextProps?.classes?.root
          ),
        },
      }}
      InputProps={{
        ...InputProps,
        // Disable increasing/decreasing numeric field value by scroll
        // https://stackoverflow.com/questions/63224459/disable-scrolling-on-input-type-number-in-react
        onWheel: (e) => (document.activeElement as HTMLInputElement)?.blur(),
        classes: {
          ...InputProps?.classes,
          // disabled: classes(
          //   styles.disabledInput,
          //   InputProps?.classes?.disabled
          // ),
          input: classes(
            styles.input,
            forcedColorClass,
            InputProps?.classes?.input
          ),
        },
        className: classes(forcedColorClass, InputProps?.className),
      }}
    />
  );
}
