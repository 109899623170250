import React, { FocusEventHandler, useMemo, useRef, useState } from "react";

import {
  Autocomplete,
  AutocompleteProps,
  Checkbox,
  IconButton,
  InputAdornment,
  Popper,
} from "@mui/material";
import InformationIcon from "components/ui/InformationIcon";
import Input, { InputProps } from "components/ui/Input/Input";
import SvgIcon from "components/ui/SvgIcon";
import { Pair } from "shared/model";
import { coloredDot } from "shared/styles/styles";
import { classes, style } from "typestyle";
import { simulateMouseClick } from "util/simulateClick";

import styles from "./FormSelect.styles";
import { SelectOption } from "./types";

export type SelectProps = Omit<
  // might have to be tweaked
  AutocompleteProps<any, any, any, any>,
  "size" | "renderInput" | "onSelect" | "onChange"
> & {
  options: SelectOption[];
  onChange?: (value: string) => void;
  onMultipleChange?: (value: string[]) => void;
  required?: boolean;
  /** The value needs to have Pair type because the "value"
  prop is used to render the selected value inside the text input
  and we must have reference to the name of that option */
  value?: string | string[];
  readOnlyInput?: boolean;
  className?: string;
  inputClassName?: string;
  placeholder?: string;
  disabled?: boolean;
  onFocus?: FocusEventHandler;
  disabledReason?: string;
  size?: "small" | "medium" | "large";
  label?: string;
  adornmentIconSrc?: string;
  renderedInputProps?: InputProps;
  multiple?: boolean;
  checkboxesHidden?: boolean;
  autoWidthPopper?: boolean;
};

export default function Select({
  options,
  value,
  onChange = () => {},
  onMultipleChange = () => {},
  readOnlyInput = false,
  className,
  inputClassName,
  placeholder,
  required = false,
  disabled,
  classes: classesProp,
  renderedInputProps,
  onFocus,
  disabledReason,
  size = "medium",
  label,
  adornmentIconSrc,
  multiple,
  checkboxesHidden,
  autoWidthPopper,
  ...props
}: SelectProps) {
  const inputRef = useRef<HTMLInputElement>(null);

  const [innerReadOnly, setInnerReadOnly] = useState<boolean>(false);
  const [validated, setValidated] = useState<boolean>(false);

  const handleValueChange = (option: Pair) => {
    onChange(option?.id);
  };
  const handleMultipleValueChange = (options: Pair[]) => {
    onMultipleChange(options.map((option) => option.id));
  };

  const selectedOption = useMemo<SelectOption | null>(
    () => (!multiple && options.find((option) => option.id === value)) || null,
    [multiple, options, value]
  );
  const multipleSelectedOptions = useMemo<SelectOption[]>(
    () => multiple && options.filter((option) => value.includes(option.id)),
    [multiple, options, value]
  );

  const onFocusElement = (element: HTMLInputElement | HTMLTextAreaElement) => {
    if (validated) {
      setInnerReadOnly(false);
      setValidated(false);
      element.blur();
      return;
    }
    setInnerReadOnly(true);
  };

  return (
    <Autocomplete
      renderTags={(selectedOptions: SelectOption[]) => {
        return selectedOptions
          .map((selectedOption) => selectedOption.name)
          .join(", ");
      }}
      {...props}
      disableCloseOnSelect={multiple}
      onFocus={onFocus}
      openOnFocus
      className={classes(className)}
      options={options}
      multiple={multiple}
      onChange={(_, newValue) => {
        multiple
          ? handleMultipleValueChange(newValue)
          : handleValueChange(newValue);
      }}
      // todo customize renderoptions
      disabled={disabled}
      renderOption={(
        _props,
        { name, color, info, disabled, child, icon },
        _state
      ) => (
        <li {..._props}>
          {multiple && !checkboxesHidden && (
            <Checkbox
              checked={_state.selected}
              tabIndex={-1}
              disabled={disabled}
            />
          )}
          {color && (
            <div
              className={classes(
                style(coloredDot({ size: "10px", color, marginRight: "12px" }))
              )}
            />
          )}
          <div className={styles.optionWrapper}>
            <span className={styles.iconWrapper}>{icon}</span>
            <span className={styles.optionText}>{child || name}</span>
            {disabledReason && disabled && (
              <span className={styles.disabledReason}>{disabledReason}</span>
            )}
          </div>
          {info && (
            <InformationIcon
              title={info}
              wrapperClassName={styles.informationIconWrapper}
              iconClassName={styles.informationIcon}
            />
          )}
        </li>
      )}
      value={multiple ? multipleSelectedOptions : selectedOption}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionDisabled={(option) =>
        typeof option.disabled === "function"
          ? !!option.disabled(option.id)
          : !!option.disabled
      }
      getOptionLabel={(option) => option.name}
      renderInput={(params) => {
        return (
          <Input
            inputRef={inputRef}
            {...params}
            {...renderedInputProps}
            label={label}
            size={size === "large" ? "medium" : size}
            className={classes(inputClassName, renderedInputProps?.className)}
            InputProps={{
              ...params.InputProps,
              classes: {
                ...renderedInputProps?.classes,
                disabled: classes(
                  styles.disabledInput,
                  renderedInputProps?.InputProps?.classes
                ),
              },
              onInvalid: () => {
                if (!readOnlyInput) {
                  return;
                }
                setValidated(true);
              },
              onFocus: (e: React.FocusEvent<HTMLInputElement>) => {
                if (!readOnlyInput) {
                  return;
                }
                onFocusElement(e.target);
              },
              onBlur: () => {
                if (!readOnlyInput) {
                  return;
                }
                setInnerReadOnly(false);
              },
              ...(selectedOption?.color && {
                startAdornment: (
                  <InputAdornment
                    position="start"
                    classes={{
                      positionStart: style({ marginRight: "2px" }),
                    }}
                  >
                    <div
                      className={classes(
                        style(
                          coloredDot({
                            size: "10px",
                            color: selectedOption.color,
                            flex: "0 0 10px",
                          })
                        )
                      )}
                    />
                  </InputAdornment>
                ),
              }),
              ...(selectedOption?.icon && {
                startAdornment: (
                  <InputAdornment
                    position="start"
                    classes={{
                      positionStart: style({ marginRight: "2px" }),
                    }}
                  >
                    <div className={styles.selectedOptionIconWrapper}>
                      {selectedOption.icon}
                    </div>
                  </InputAdornment>
                ),
              }),
              endAdornment: (
                <InputAdornment position="end" tabIndex={-1}>
                  <IconButton
                    tabIndex={-1}
                    size="small"
                    onPointerDown={() => {
                      if (!inputRef.current) {
                        return;
                      }
                      simulateMouseClick(inputRef.current);
                    }}
                    disabled={disabled}
                  >
                    <SvgIcon
                      src={adornmentIconSrc || "/icons/arrow_down.svg"}
                      className={styles.arrowIcon}
                    />
                  </IconButton>
                </InputAdornment>
              ),
              readOnly: innerReadOnly,
            }}
            placeholder={
              multiple
                ? !multipleSelectedOptions.length
                  ? placeholder
                  : undefined
                : placeholder
            }
            required={required}
            disabled={disabled}
          />
        );
      }}
      {...(autoWidthPopper && {
        PopperComponent: (popperProps) => (
          <Popper {...popperProps} style={{ width: "auto" }} />
        ),
      })}
      classes={{
        ...classesProp,
        root: classes(styles.root, classesProp?.root),
        option: classes(styles.listItem, classesProp?.option),
        input: classes(styles.input, classesProp?.input),
        listbox: classes(styles.list, classesProp?.listbox),
        inputRoot: classes(styles.inputRoot, classesProp?.inputRoot),
      }}
      disableClearable
      aria-required={required}
    />
  );
}
