import React from "react";

import { IconButton } from "@mui/material";
import { LabelColor } from "@pulsemarket/api-client";
import { classes, style } from "typestyle";

import SvgIcon from "../SvgIcon/SvgIcon";

import styles from "./LabelStyles";

export type LabelProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLSpanElement>,
  HTMLSpanElement
> & {
  children: React.ReactNode;
  color: LabelColor;
  onDelete?: () => void;
};

export default function Label({
  children,
  className,
  color,
  onDelete,
  ...props
}: LabelProps): JSX.Element {
  return (
    <span
      {...props}
      className={classes(
        styles.label,
        style({
          backgroundColor: color,
          $nest: {
            "&:hover": {
              backgroundColor: "unset",
              border: `1px solid ${color}`,
              color,
              $nest: {
                [`& .${styles.closeIcon}`]: { color },
              },
            },
          },
        }),
        className
      )}
    >
      <div>{children}</div>
      <div>
        <IconButton
          size="small"
          classes={{
            root: styles.closeIconWrapper,
          }}
          disableRipple
          onClick={onDelete}
        >
          <SvgIcon
            src="/icons/close_icon.svg"
            className={classes(styles.closeIcon)}
          />
        </IconButton>
      </div>
    </span>
  );
}
