import React from "react";
import { Link, useHistory, useLocation, useRouteMatch } from "react-router-dom";

import { Divider, IconButton } from "@mui/material";
import SvgIcon from "components/ui/SvgIcon";
import Tooltip from "components/ui/Tooltip";
import { classes, style } from "typestyle";

import styles from "./SideMenu.styles";

export type MenuItem = {
  id: string;
  text: string;
  historyPush: string;
  locked?: boolean;
  message?: string;
};

type SideMenuBaseProps = {
  icon?: string;
  title?: React.ReactNode;
  hideDividerUnderTitle?: boolean;
  /** When supplied a back arrow is shown before the title & the icon */
  backArrowUrl?: string;
  className?: string;
};

type SideMenuWithItemsProps = SideMenuBaseProps & {
  menuItems: MenuItem[];
};

type SideMenuWithCustomContent = SideMenuBaseProps & {
  children: React.ReactNode;
};

type SideMenuProps = SideMenuWithItemsProps | SideMenuWithCustomContent;

/**
 * Has 2 variants. When supplied `menuItems` array, it will be a list of menus. If however `children` are passed, only that custom content is rendered.
 */
export default function SideMenu({
  icon,
  title,
  backArrowUrl,
  hideDividerUnderTitle,
  className,
  ...variantProps
}: SideMenuProps) {
  const history = useHistory();
  const { url } = useRouteMatch();
  const location = useLocation();

  const showTitleContainer = title || icon || backArrowUrl;

  return (
    <div className={classes(styles.sideMenuRoot, className)}>
      {showTitleContainer && (
        <div className={styles.titleContainer}>
          {backArrowUrl && (
            <IconButton size="small" component={Link} to={backArrowUrl}>
              <SvgIcon
                src="/icons/arrow_back.svg"
                className={styles.leftArrow}
              />
            </IconButton>
          )}
          {icon && <img src={icon} className={styles.titleIcon} alt="" />}
          {title && <span className={styles.title}>{title}</span>}
        </div>
      )}
      {!hideDividerUnderTitle && <Divider />}
      {"menuItems" in variantProps && (
        <ul className={styles.menu}>
          {variantProps.menuItems
            ?.map((i) => ({ ...i, historyPush: `${url}${i.historyPush}` }))
            .map((item) => (
              <li
                key={item.id}
                className={classes(
                  styles.menuItem,
                  item.historyPush === location.pathname &&
                    styles.activeMenuItem,
                  item.locked && "locked"
                )}
                onClick={() => {
                  if (item.locked) {
                    return;
                  }
                  history.push(item.historyPush);
                }}
              >
                <span className={styles.menuItemText}>{item.text}</span>
                {item.locked && (
                  <Tooltip
                    title={item.message || ""}
                    className={style({ zIndex: 200 })}
                  >
                    <span>
                      <SvgIcon
                        className={styles.lockIcon}
                        src="/icons/lock_closed_icon.svg"
                      />
                    </span>
                  </Tooltip>
                )}
              </li>
            ))}
        </ul>
      )}
      {"children" in variantProps && variantProps.children}
    </div>
  );
}
