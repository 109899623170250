import React from "react";

import { Grid2 } from "@mui/material";
import { classes } from "typestyle";

import styles from "./ZoneBoxStyles";

export type ZoneBoxProps = {
  onClick?: () => void;
  // logo is expected to be an image element
  logo: React.ReactNode;
  name: string;
  description: string;
  className?: string;
};

export default function ZoneBox({
  onClick = () => {},
  logo,
  name,
  description,
  className,
}: ZoneBoxProps) {
  return (
    <Grid2
      className={classes(styles.root, className)}
      onClick={onClick}
      container
      direction="column"
      alignItems="center"
      spacing={1}
    >
      <Grid2 className={styles.logoContainer}>{logo}</Grid2>
      <Grid2>
        <h2 className={styles.title}>{name}</h2>
      </Grid2>
      <Grid2>
        <p className={styles.description}>{description}</p>
      </Grid2>
    </Grid2>
  );
}
