import React, { createContext, useContext } from "react";

import { AlertColor, Fade, FadeProps, Snackbar } from "@mui/material";
import PulseAlert, { PulseAlertProps } from "components/Alert/Alert";

type SnackBarContextActions = {
  showSnackBar: ({
    text,
    typeColor,
    action,
  }: {
    text: string;
    typeColor: AlertColor;
    action?: PulseAlertProps["action"];
  }) => void;
};
const SnackBarContext = createContext({} as SnackBarContextActions);
interface SnackBarContextProviderProps {
  children: React.ReactNode;
}

function Transition(props: FadeProps) {
  return <Fade {...props} />;
}

const SnackBarProvider: React.FC<SnackBarContextProviderProps> = ({
  children,
}) => {
  const [open, setOpen] = React.useState<boolean>(false);
  const [message, setMessage] = React.useState<string>("");
  const [action, setAction] = React.useState<
    PulseAlertProps["action"] | undefined
  >(undefined);
  const [typeColor, setTypeColor] = React.useState<AlertColor>("info");
  const showSnackBar = ({
    text,
    typeColor,
    action,
  }: {
    text: string;
    typeColor: AlertColor;
    action?: PulseAlertProps["action"];
  }) => {
    setMessage(text);
    setTypeColor(typeColor);
    setAction(action);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    // setTypeColor("info");
    setAction(undefined);
  };

  return (
    <SnackBarContext.Provider value={{ showSnackBar }}>
      <Snackbar
        open={open}
        autoHideDuration={action ? null : 5000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        {/* This div is needed because the PulseAlert component cannot be referenced and caused error.
          For more info see  https://stackoverflow.com/questions/74862197/typeerror-cannot-read-properties-of-null-reading-scrolltop-material-ui */}
        <div>
          <PulseAlert
            onClose={handleClose}
            severity={typeColor}
            action={action}
          >
            {message}
          </PulseAlert>
        </div>
      </Snackbar>
      {children}
    </SnackBarContext.Provider>
  );
};
const useSnackBar = (): SnackBarContextActions => {
  const context = useContext(SnackBarContext);
  if (!context) {
    throw new Error("useSnackBar must be used within an SnackBarProvider");
  }
  return context;
};
export { SnackBarProvider, useSnackBar };
