import React, { useCallback, useContext, useState } from "react";

import { DialogContent } from "@mui/material";
import { ModalAction } from "components/ui/Modal/Actions/ModalActions";
import ModalContext from "contexts/ModalContext";
import { classes, style } from "typestyle";

import ModalActions from "../Actions";
import ModalHeader from "../Header";

import styles from "./SimpleModalBodyStyles";

export type ModalTab = { title: string; position: number };

export type SimpleModalBodyProps = {
  title?: React.ReactNode;
  titleClassName?: string;
  subTitleClassName?: string;
  tabs?: ModalTab[];
  subTitle?: React.ReactNode;
  keepOpen?: boolean;
  header?: boolean;
  onClose?: () => void;
  hideCloseButton?: boolean;
  onTabChanged?: (tabPosition: number) => void;
  buttons?: ModalAction[];
  modalBodyClassName?: string;
  buttonContainerClassName?: string;
  children?: React.ReactNode;
};

/**
 * This component should only be used inside modals.
 */
export default function SimpleModalBody({
  title,
  titleClassName,
  subTitleClassName,
  tabs,
  subTitle,
  keepOpen = false,
  header = true,
  hideCloseButton,
  onTabChanged = (tabPosition) => {},
  onClose,
  children,
  buttons = [],
  modalBodyClassName,
  buttonContainerClassName,
}: SimpleModalBodyProps) {
  const [activeTitle, setactiveTitle] = useState(
    tabs && tabs?.length > 0
      ? Math.min(...tabs.map((t) => t.position))
      : title
      ? title
      : ""
  );

  const { closeModal } = useContext(ModalContext);

  const _onClose = useCallback(() => {
    if (onClose) {
      onClose();
    } else {
      closeModal();
    }
  }, [closeModal, onClose]);

  return (
    <>
      {header && (!tabs || tabs?.length === 0) && (
        <ModalHeader
          className={classes(styles.header, titleClassName)}
          onClose={_onClose}
          hideCloseButton={hideCloseButton}
        >
          {title}
        </ModalHeader>
      )}
      {header && tabs && (
        <ModalHeader
          className={classes(styles.header, titleClassName)}
          onClose={_onClose}
          hideCloseButton={hideCloseButton}
        >
          {tabs
            .sort((acc, curr) => acc.position - curr.position)
            .map((t) => {
              return (
                <label
                  key={t.title}
                  className={classes(
                    styles.multiTitle,
                    activeTitle === t.position
                      ? styles.activeTitle
                      : styles.inactiveTitle
                  )}
                  onClick={() => {
                    setactiveTitle(t.position);
                    onTabChanged(t.position);
                  }}
                >
                  {t.title}
                </label>
              );
            })}
        </ModalHeader>
      )}
      {subTitle && (
        <div className={classes(styles.header, subTitleClassName)}>
          {subTitle}
        </div>
      )}
      <DialogContent
        className={classes(
          styles.contentContainer,
          modalBodyClassName,
          !!buttons.length &&
            style({
              marginBottom: "16px",
            })
        )}
      >
        {children}
      </DialogContent>
      <ModalActions
        actions={buttons}
        onClose={() => {
          !keepOpen && _onClose();
        }}
        className={buttonContainerClassName}
      />
    </>
  );
}
