import React, { useState } from "react";

import {
  Fade,
  Grid2,
  IconButton,
  InputAdornment,
  Popover,
} from "@mui/material";
import Input, { InputProps } from "components/ui/Input/Input";
import SvgIcon from "components/ui/SvgIcon/SvgIcon";
import Tag from "components/ui/Tag/index";
import { Pair } from "shared/model";

import styles from "./TaggedMultiSelectStyles";

type SelectionGroup = {
  name: string; // has to be unique between groups
  values: Pair[];
};

type TaggedMultiSelectProps = Omit<InputProps, "value" | "multiline"> & {
  // ? should filtering be managed in the inside? - I think so for now
  placeholder?: string;
  // The component allows for different groups.
  // In the wireframes: inside company info under the "Industry/Sector you are servicing" field.
  // https://www.figma.com/file/acH57GN2MTV9IYEgNbXzrw/Pulse-Wireframe-Original?node-id=3253%3A88599
  // In most of the cases there will be only one though.
  selectionGroups?: SelectionGroup[];
  selectedIds: string[];
  onAdd?: (id: string) => void;
  onDelete?: (id: string) => void;
};

// Tag components
function SelectedTag({
  children,
  onCloseClick,
}: {
  children: string;
  onCloseClick: () => void;
}) {
  return (
    <Tag>
      {children}
      <SvgIcon
        onClick={onCloseClick}
        src="/icons/close_icon.svg"
        className={styles.selectedTagCloseIcon}
      />
    </Tag>
  );
}
function UnSelectedTag({
  children,
  onClick,
}: {
  children: string;
  onClick: () => void;
}) {
  return (
    <Tag className={styles.unSelectedTag} onClick={onClick}>
      {children}
    </Tag>
  );
}

function SearchIcon({ onClick = () => {} }: { onClick: () => void }) {
  return (
    <IconButton
      size="small"
      className={styles.searchIconButton}
      onClick={onClick}
    >
      <SvgIcon
        src="/icons/search_icon.svg"
        className={styles.searchIconButton}
      />
    </IconButton>
  );
}

export default function TaggedMultiSelect({
  placeholder,
  selectionGroups = [],
  selectedIds = [], // independent of groups
  onAdd = () => {},
  onDelete = () => {},
  ...props
}: TaggedMultiSelectProps) {
  const [searchValue, setSearchValue] = useState("");

  // Popover configuration
  const [popoverAnchorEl, setPopoverAnchorEl] =
    React.useState<HTMLElement | null>(null);

  const handleSettingPopoverAnchor = (
    event: React.MouseEvent<HTMLInputElement>
  ) => {
    setPopoverAnchorEl(event.currentTarget);
  };
  const handleClosePopover = () => {
    setPopoverAnchorEl(null);
  };

  const popoverOpen = Boolean(popoverAnchorEl);
  const inputWidth = popoverAnchorEl?.offsetWidth || 0;

  // Selection related
  const selectedTags = selectionGroups
    .map((group) =>
      group.values?.filter((element) =>
        selectedIds.some((id) => id === element.id)
      )
    )
    .flat();
  return (
    <div>
      <Input
        {...props}
        fullWidth
        placeholder={placeholder}
        value={searchValue}
        onClick={handleSettingPopoverAnchor}
        onKeyPress={() => {
          // todo add support for enter key press: Add tag, if there is only one element that has a matching name
          // structure needs to be updated first (0.75 hours estimation if done by me).
        }}
        helperTextExpectedLines={0}
        onChange={(e) => setSearchValue(e.target.value)}
        className={styles.textField}
        InputProps={{
          className: styles.input,
          endAdornment: !searchValue ? (
            <InputAdornment position="end">
              <SearchIcon onClick={() => {}} />
            </InputAdornment>
          ) : null,
        }}
      />

      <Grid2 wrap="wrap" spacing={2} container>
        {selectedTags.map((tag) => {
          return (
            <Grid2 key={tag.id}>
              <SelectedTag
                onCloseClick={() => {
                  onDelete(tag.id);
                }}
                key={tag.id}
              >
                {tag.name}
              </SelectedTag>
            </Grid2>
          );
        })}
      </Grid2>

      {/* POPOVER */}
      <Popover
        open={popoverOpen}
        TransitionComponent={Fade}
        TransitionProps={{ timeout: 0 }}
        disableAutoFocus={true}
        disableEnforceFocus={true}
        anchorEl={popoverAnchorEl}
        onClose={handleClosePopover}
        elevation={0}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div
          style={{
            width: `${inputWidth}px`,
          }}
          className={styles.popoverContainer}
        >
          <div className={styles.clickableSearchText}>
            <SearchIcon onClick={() => {}} />
            <span>{searchValue}</span>
            <span className={styles.grayText}>
              Press enter to select or select from the list
            </span>
          </div>
          {selectionGroups.map((group) => {
            const shownValues = group.values.filter((value) =>
              value.name?.toLowerCase().includes(searchValue.toLowerCase())
            );

            return (
              <div key={group.name}>
                <h3 className={styles.groupName}>{group.name}</h3>
                <Grid2 wrap="wrap" spacing={2} container>
                  {shownValues.map((tag) => {
                    return (
                      <Grid2 key={tag.id}>
                        {selectedIds.some((id) => id === tag.id) ? (
                          <SelectedTag
                            key={tag.id}
                            onCloseClick={() => {
                              onDelete(tag.id);
                            }}
                          >
                            {tag.name}
                          </SelectedTag>
                        ) : (
                          <UnSelectedTag
                            key={tag?.id}
                            onClick={() => onAdd(tag.id)}
                          >
                            {tag.name}
                          </UnSelectedTag>
                        )}
                      </Grid2>
                    );
                  })}
                </Grid2>
              </div>
            );
          })}
        </div>
      </Popover>
    </div>
  );
}
