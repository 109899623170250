import React, { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { RouterProps } from "react-router-dom";
import { useHistory } from "react-router-dom";

import { TooltipProps } from "@mui/material";
import { RFPType, ROUTES } from "@pulsemarket/constants";
import DocumentInvitationTipContent from "components/ui/TipProvider/DocumentInvitationTipContent";
import Tooltip from "components/ui/Tooltip";
import { useStore } from "store";
import TipStore, { closeTip, setNextTip, TipType } from "store/TipStore";

import TipContent from "./TipContent";

export type TipProviderProps = {
  type: TipType;
  children: React.ReactElement;
  className?: string;
  onAction?: Function;
  autoShow?: boolean;
  placement?: TooltipProps["placement"];
};

const TIPS: Record<
  TipType,
  (param: {
    history: RouterProps["history"];
    onAction?: Function;
  }) => React.ReactElement
> = {
  /**
   * https://www.figma.com/file/acH57GN2MTV9IYEgNbXzrw/Pulse-Library?node-id=11151%3A53846
   */
  [TipType.SupplierManagementDashboard]: ({ history }) => (
    <TipContent
      imageSrc="/illustrations/illustration_my_suppliers_tip.png"
      title="Supplier Profiling"
      text="Upload your supplier list onto our platform and you'll be able to manage your relationship from one place. From sending invitations to tender, adding key contacts, reviewing passports everything you need in one place."
      action="Get Started!"
      isNew={true}
      onSkip={() => {
        closeTip();
        setNextTip(TipType.ProcurementDashboard);
      }}
      onAction={() => {
        history.push(ROUTES.MY_SUPPLIERS);
        setNextTip(TipType.SupplierManagementUpload);
      }}
    />
  ),
  /**
   * https://www.figma.com/file/acH57GN2MTV9IYEgNbXzrw/Pulse-Library?node-id=11247%3A61313
   */
  [TipType.SupplierManagementUpload]: ({ onAction }) => (
    <TipContent
      imageSrc="/illustrations/illustration_add_suppliers.png"
      title="Add or import suppliers"
      text="Quickly add your suppliers by uploading a CSV instantly to the platform. You can always add individual suppliers one by one if required."
      action="Get Started!"
      isNew={true}
      onSkip={closeTip}
      onAction={() => {
        closeTip();
        if (onAction) {
          onAction();
          // This one will popup after adding a new supplier to the list
          setNextTip(TipType.SupplierManagementView);
        }
      }}
    />
  ),
  /**
   * https://www.figma.com/file/acH57GN2MTV9IYEgNbXzrw/Pulse-Library?node-id=11353%3A65339
   */
  [TipType.SupplierManagementView]: () => (
    <TipContent
      imageSrc="/illustrations/illustration_view_supplier.png"
      title="View Supplier"
      text="Click here to see your supplier's details, update your supplier's information, and more. Access their Pulse Passport, add contracts, keep up to current on response updates, and submit documents."
      action="Got it!"
      isNew={true}
      onAction={() => {
        closeTip();
        setNextTip(TipType.ProcurementDashboard);
      }}
    />
  ),
  /**
   * https://www.figma.com/file/acH57GN2MTV9IYEgNbXzrw/Pulse-Library?node-id=8212%3A120011
   */
  [TipType.ProcurementDashboard]: ({ history }) => (
    <TipContent
      imageSrc="/illustrations/illustration_rfp_ddq_tip.png"
      title="Manage your Procurement Process"
      text="With our RFI, RFP and DDQ workspace you can build and manage your proposals quickly by using our master templates and adding questions that can be stored for future proposals."
      action="Create New!"
      onSkip={closeTip}
      onAction={() => {
        history.push("/rfp");
      }}
    />
  ),
  /**
   * https://www.figma.com/file/acH57GN2MTV9IYEgNbXzrw/Pulse-Library?node-id=11252%3A64978
   */
  [TipType.ProcurementCreateRFP]: () => (
    <TipContent
      imageSrc="/illustrations/rfp.png"
      title="Create a new RFP"
      text="Start writing your first Request For Proposal with this easy to build and manage procurement process."
      action="Got it!"
      onAction={closeTip}
    />
  ),
  [TipType.ProcurementCreateRFI]: () => (
    <TipContent
      imageSrc="/illustrations/rfi.png"
      title="Create a new RFI"
      text="Finding out if your suppliers can or wish to be involved in an RFP is essential and stop you wasting time and effort. With and RFI you can take the first step to understand who can and who is interested in going forward to the deeper RFP process."
      action="Got it!"
      onAction={closeTip}
    />
  ),
  [TipType.ProcurementCreateDDQ]: () => (
    <TipContent
      imageSrc="/illustrations/ddq.png"
      title="Create a new DDQ"
      text="Set up your first Due Diligence Questionnaire to gather high quality feedback from your suppliers, allowing you to act quickly and responsibly."
      action="Got it!"
      onAction={closeTip}
    />
  ),
  [TipType.ProcurementCreateQTP]: () => (
    <TipContent
      imageSrc="/illustrations/qtp.png"
      title="Create a new QTP"
      text="Want to purchase a service quickly but still show you have done the due diligence, use the Quick to Procure process and manage your purchasing to the same high standards as an RFP, but with less effort."
      action="Got it!"
      onSkip={closeTip}
      onAction={closeTip}
    />
  ),
  [TipType.ProcurementDraftInfo]: () => (
    <TipContent
      imageSrc="/illustrations/illustration_edit_assessment.png"
      title="Edit Info"
      text="When your document is in Draft mode, you may change and delete the Information."
      action="Next"
      steps={{
        current: 1,
        total: 3,
      }}
      onSkip={closeTip}
      onAction={() => {
        setNextTip(TipType.ProcurementDraftAddModule);
      }}
    />
  ),
  [TipType.ProcurementDraftAddModule]: () => (
    <TipContent
      imageSrc="/icons/modules_icon.svg"
      title="Add Modules"
      text="Modules containing questionnaires and questions can be added to your document."
      action="Next"
      steps={{
        current: 2,
        total: 3,
      }}
      onSkip={closeTip}
      onAction={() => {
        setNextTip(TipType.ProcurementDraftSendToReview);
      }}
    />
  ),
  [TipType.ProcurementDraftSendToReview]: () => (
    <TipContent
      imageSrc="/illustrations/illustration_visualization.png"
      title="Send to Review"
      text="You can send your document to review before you send it to suppliers."
      action="Got it!"
      steps={{
        current: 3,
        total: 3,
      }}
      onAction={closeTip}
    />
  ),

  [TipType.ProcurementAddRecipients]: () => (
    <TipContent
      imageSrc="/illustrations/illustration_add_suppliers.png"
      title="Add recipients"
      text="Add suppliers to receive this document by choosing them from your supplier search list or bulk email."
      action="Next"
      steps={{
        current: 1,
        total: 2,
      }}
      onSkip={closeTip}
      onAction={() => {
        setNextTip(TipType.ProcurementSend);
      }}
    />
  ),
  [TipType.ProcurementSend]: () => (
    <TipContent
      imageSrc="/icons/send_icon.svg"
      title="Send this document"
      text="When you have completed your document, you can send it to the suppliers from your recipient list."
      action="Got it!"
      steps={{
        current: 2,
        total: 2,
      }}
      onAction={closeTip}
    />
  ),

  [TipType.ProcurementInvitationTable]: () => (
    <TipContent
      imageSrc="/illustrations/illustration_list_suppliers.png"
      title="List of invitations"
      text="This is the list of providers to whom your document was sent. When they reply, their status changes to responded, and you may check their responses on the Responses tab."
      action="Got it!"
      onAction={closeTip}
    />
  ),
  [TipType.ProcurementResponseTable]: () => (
    <TipContent
      imageSrc="/icons/proposals_icon.svg"
      title="List of Responses"
      text="You have got a response; click to begin evaluating."
      action="Got it!"
      onAction={closeTip}
    />
  ),

  [TipType.ESGGetPassport]: () => (
    <TipContent
      imageSrc="/illustrations/ESG_certificate_passport.svg"
      title="ESG Passport"
      text="The easiest way to start your companies ESG journey. Take 15mins to gain your ESG Passport."
      action="Got it!"
      onAction={closeTip}
    />
  ),
  [TipType.ESGEditSelfAssessment]: () => (
    <TipContent
      imageSrc="/illustrations/edit_assessment.svg"
      title="Self-assessment"
      text="The Self-assessment will be always available. You can access and edit it at any time."
      action="Got it!"
      onAction={closeTip}
    />
  ),
  /**
   * https://www.figma.com/file/jNcVZ1cGik4UVFYARAAsQr/Version-2.0?node-id=10633%3A499561
   */
  [TipType.AcceptRFPInvitation]: () => (
    <DocumentInvitationTipContent rfpType={RFPType.RFP} />
  ),
  [TipType.AcceptDDQInvitation]: () => (
    <DocumentInvitationTipContent rfpType={RFPType.DDQ} />
  ),
  [TipType.AcceptRFIInvitation]: () => (
    <DocumentInvitationTipContent rfpType={RFPType.RFI} />
  ),
  [TipType.AcceptQTPInvitation]: () => (
    <DocumentInvitationTipContent rfpType={RFPType.QTP} />
  ),
  [TipType.ProcurementResponseReview]: () => (
    <TipContent
      imageSrc="/illustrations/illustration_visualization.png"
      title="Send to Review"
      text="You can send your document to review before you send it to the buyer."
      action="Got it!"
      steps={{
        current: 2,
        total: 2,
      }}
      onAction={closeTip}
    />
  ),
  [TipType.ProcurementResponseSubmit]: () => (
    <TipContent
      imageSrc="/icons/send_icon.svg"
      title="Submit response"
      text="When you're finished, click here to submit your response. After that, the buyer will evaluate it and contact you; best of luck."
      action="Got it!"
      onAction={closeTip}
    />
  ),
  [TipType.PassportResponseSubmit]: () => (
    <TipContent
      imageSrc="/icons/send_icon_blue.svg"
      title="You're now in the 'In Review' status"
      text="Feel free to submit your assessment when you're satisfied with your responses. Remember, once submitted, changes can't be made."
      action="Got it!"
      onAction={closeTip}
    />
  ),
  /**
   *   https://www.figma.com/file/cptZCjqKqdPrpUDlUe5BJu/Procurement?node-id=72%3A70770&viewport=1592%2C2044%2C0.25&t=b5GDm2x11yrFdya0-0
   */
  [TipType.SendRfpMessage]: () => (
    <TipContent
      imageSrc="/icons/send_icon.svg"
      title="Send Message"
      text="Your message is saved and you can send it later or in bulk if you create more messages by selecting the module or questionnaire."
      action="Ok, Got it!"
      onAction={closeTip}
    />
  ),
  /**
   *
   */
  [TipType.ReplyRfpMessages]: () => (
    <TipContent
      imageSrc="/illustrations/illustration_message.png"
      title="Reply new messages"
      text="You can reply a message individually by clicking here or in bulk by selecting the module or questionnaire."
      action="Ok, Got it!"
      onAction={closeTip}
    />
  ),
};

const TipProvider = React.forwardRef<HTMLElement, TipProviderProps>(
  function TipProvider(
    { className, type, children, onAction, autoShow, placement = "right" },
    ref
  ) {
    const history = useHistory();

    const state = useStore(TipStore);

    useEffect(() => {
      if (autoShow) {
        setNextTip(type);
      }
    }, [autoShow, type]);

    const selectedType = useMemo(() => {
      let selectedType = type === state.type ? type : null;

      if (!selectedType) {
        return false;
      }

      if (state.tipsSeen.includes(selectedType)) {
        // Do not show the same tip more then once!
        return false;
      }

      return selectedType;
    }, [state.type, state.tipsSeen, type]);

    const [content, setContent] = useState<false | React.ReactElement>(false);

    useLayoutEffect(() => {
      let timeoutId: any;

      if (selectedType) {
        timeoutId = setTimeout(
          () => setContent(TIPS[selectedType]({ history, onAction })),
          300
        );
      } else {
        setContent(false);
      }

      return () => {
        clearTimeout(timeoutId);
      };
    }, [selectedType, history, onAction]);

    return (
      <Tooltip
        className={className}
        title={content}
        open={!!content}
        placement={placement}
        noPadding
        maxWidth={300}
        backdrop
        onBackdropClick={() => {
          // Empty function needed to prevent backdrop click
        }}
      >
        {React.cloneElement(children, {
          ref,
          // style: content
          //   ? { ...children.props.style, zIndex: 10000 }
          //   : children.props.style,
        })}
      </Tooltip>
    );
  }
);

export default TipProvider;

export function MaybeTipProvider(
  props: Omit<TipProviderProps, "type"> & { type?: TipType }
) {
  if (props.type) {
    return <TipProvider {...props} type={props.type} />;
  }

  return props.children;
}
