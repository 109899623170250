import React from "react";

import { Grid2 } from "@mui/material";
import { utilityColors } from "@pulsemarket/constants";
import Button from "components/ui/Button";
import MultiSelect from "components/ui/MultiSelect/MultiSelect";
import { MultiSelectProps } from "components/ui/MultiSelect/MultiSelect";
import SvgIcon from "components/ui/SvgIcon";
import {
  useVendorSearchContext,
  VendorFilterContextType,
} from "components/VendorSearch/VendorFilterProvider";
import useVendorSearchOptions from "hooks/queries/useVendorSearchOptions";
import { style } from "typestyle";

import styles from "./FilterBarStyles";

type SelectableVendorFilters = Omit<
  VendorFilterContextType["filters"],
  "companyName" | "companyListId"
>;

function getMultiSelectFilterProps(
  setFilters: VendorFilterContextType["setFilters"],
  filters: VendorFilterContextType["filters"],
  key: keyof SelectableVendorFilters
): Pick<MultiSelectProps, "onChange" | "selectedIds"> {
  return {
    onChange: (selectedValues) => {
      const newFilters = {
        ...filters,
        [key]: selectedValues.length ? selectedValues : undefined,
      };
      setFilters(newFilters);
    },
    selectedIds: filters[key] || [],
  };
}

type FilterBarProps = {
  onClear?: () => void;
};

export default function FilterBar({ onClear = () => {} }: FilterBarProps) {
  const {
    loading,
    employeeNumbers,
    zones,
    countries,
    industries,
    // sectors,
    // specialities,
    passports,
  } = useVendorSearchOptions();
  const { filters, setFilters } = useVendorSearchContext();

  if (loading) {
    return null;
  }

  return (
    <Grid2
      container
      className={styles.root}
      justifyContent="space-between"
      alignItems="center"
      wrap="nowrap"
    >
      <Grid2 className={styles.part} size={{ xs: 2 }}>
        <Grid2
          container
          wrap="nowrap"
          alignItems="center"
          spacing={2}
          justifyContent="flex-start"
          className={styles.titleContainer}
        >
          <Grid2 className={styles.titleIconContainer}>
            <SvgIcon
              src="/icons/filter_filled_icon.svg"
              className={style({
                height: "100%",
                width: "28px",
                color: utilityColors.ORANGE,
              })}
            />
          </Grid2>
        </Grid2>
      </Grid2>

      <Grid2 className={styles.part} size={{ xs: 8 }}>
        <Grid2
          container
          wrap="wrap"
          spacing={3}
          // TODO: MUI classes is not available in Grid2 mui v6
          // classes={{
          //   "spacing-xs-3": styles.selectContainer,
          // }}
          alignItems="center"
          className={styles.middleContainer}
        >
          <Grid2 size={{ xs: 6, lg: 4, xl: 3 }}>
            <MultiSelect
              className={styles.filter}
              label="Zones"
              options={zones}
              {...getMultiSelectFilterProps(setFilters, filters, "zones")}
            />
          </Grid2>

          <Grid2 size={{ xs: 6, lg: 4, xl: 3 }}>
            <MultiSelect
              label="Pulse Passport"
              options={passports}
              {...getMultiSelectFilterProps(setFilters, filters, "passports")}
            />
          </Grid2>

          <Grid2 size={{ xs: 6, lg: 4, xl: 3 }}>
            <MultiSelect
              label="Location"
              options={countries}
              {...getMultiSelectFilterProps(setFilters, filters, "country")}
            />
          </Grid2>

          <Grid2 size={{ xs: 6, lg: 4, xl: 3 }}>
            <MultiSelect
              label="Industry"
              options={industries}
              {...getMultiSelectFilterProps(setFilters, filters, "industry")}
            />
          </Grid2>

          {/*   <Grid2  xs={6} lg={4} xl={3}>
            <MultiSelect
              label="Sector"
              options={sectors}
              {...getMultiSelectFilterProps(setFilters, filters, "sectors")}
            />
          </Grid2> */}
          {/*   */}

          <Grid2 size={{ xs: 6, lg: 4, xl: 3 }}>
            <MultiSelect
              label="Employee Number"
              options={employeeNumbers}
              {...getMultiSelectFilterProps(setFilters, filters, "employees")}
            />
          </Grid2>
          {/*    */}

          {/*  <Grid2  xs={6} lg={4} xl={3}>
            <MultiSelect
              label="Speciality"
              options={specialities}
              {...getMultiSelectFilterProps(
                setFilters,
                filters,
                "specialities"
              )}
            />
          </Grid2> */}
        </Grid2>
      </Grid2>

      <Grid2 className={styles.part} size={{ xs: 2 }}>
        <Grid2
          container
          wrap="nowrap"
          alignItems="center"
          justifyContent="flex-end"
          spacing={2}
          className={styles.rightContainer}
        >
          {/* <Grid2 >
            <SvgIcon
              src="/icons/chevron_left.svg"
              className={classes(
                style({ height: "100%", width: "25px" }),
                styles.arrowIcon
              )}
            ></SvgIcon>
          </Grid2>
          <Grid2 >
            <SvgIcon
              src="/icons/arrow_right.svg"
              className={classes(
                style({ height: "100%", width: "25px" }),
                styles.arrowIcon
              )}
            ></SvgIcon>
          </Grid2> */}
          <Grid2>
            <Button
              className={style({ height: "100%" })}
              variant="transparent"
              color="orange"
              onClick={() => {
                setFilters({ companyName: filters.companyName });
                onClear();
              }}
            >
              <SvgIcon
                src="/icons/close_icon.svg"
                className={style({
                  width: "15px",
                  height: "15px",
                  marginRight: "4px",
                })}
              />
              Clear
            </Button>
          </Grid2>
        </Grid2>
      </Grid2>
    </Grid2>
  );
}
