import { useRef } from "react";

import { Grid2 } from "@mui/material";
import { AssetLabelDto } from "@pulsemarket/api-client";
import { AssetRepositoryItem } from "components/AssetRepository/AssetRepositoryBody/AssetRepositoryBody";
import Label from "components/ui/Label";
import SvgIcon from "components/ui/SvgIcon";
import Tooltip from "components/ui/Tooltip";
import useRemoveLabelFromAssets from "hooks/queries/useRemoveLabelFromAssets";
import useFittingFlexItemsCount from "hooks/useFittingFlexItemsCount";
import { classes, style } from "typestyle";

import styles from "./LabelCell.styles";

type LabelCellProps = { row: Pick<AssetRepositoryItem, "id"> } & {
  labels?: AssetLabelDto[];
  className?: string;
};

export default function LabelCell({
  labels = [],
  className,
  row,
}: LabelCellProps) {
  const labelContainer = useRef<HTMLDivElement>(null);

  const {
    hasDeterminedHiddenElements,
    fittingElementCount,
    hiddenElementCount,
  } = useFittingFlexItemsCount({
    flexContainer: labelContainer,
    items: labels,
  });

  const { mutateAsync: removeLabelFromAssets } = useRemoveLabelFromAssets();

  return (
    <Grid2
      container
      className={classes(styles.root, className)}
      spacing={1}
      alignItems="center"
      wrap="nowrap"
      justifyContent="space-between"
    >
      <Grid2>
        <Grid2
          container
          className={classes(
            styles.labels,
            !hasDeterminedHiddenElements &&
              style({ visibility: "hidden", opacity: 0 })
          )}
          wrap={hasDeterminedHiddenElements ? "nowrap" : "wrap"}
          ref={labelContainer}
          spacing={1}
        >
          {labels.map((label, i) => (
            <Grid2
              key={i}
              className={classes(
                hasDeterminedHiddenElements &&
                  i + 1 > fittingElementCount &&
                  styles.hidden
              )}
            >
              <Label
                color={label.color}
                onDelete={() => {
                  removeLabelFromAssets({
                    labelId: label.id,
                    assetIds: [row.id],
                    folderIds: [row.id],
                  });
                }}
              >
                {label.name}
              </Label>
            </Grid2>
          ))}
        </Grid2>
      </Grid2>

      {(hiddenElementCount > 0 || !hasDeterminedHiddenElements) && (
        <Tooltip
          title={
            <Grid2 container spacing={1} className={styles.tooltip}>
              {labels.map((label, i) => {
                return (
                  <Grid2 key={i}>
                    <Label
                      color={label.color}
                      onDelete={() => {
                        removeLabelFromAssets({
                          labelId: label.id,
                          assetIds: [row.id],
                          folderIds: [row.id],
                        });
                      }}
                    >
                      {label.name}
                    </Label>
                  </Grid2>
                );
              })}
            </Grid2>
          }
        >
          <Grid2>
            <Grid2
              container
              alignItems="center"
              wrap="nowrap"
              spacing={1}
              className={styles.dropdown}
            >
              <Grid2>
                <span>+{hiddenElementCount}</span>
              </Grid2>
              <Grid2 className={styles.arrowDown}>
                <SvgIcon
                  className={styles.arrowDown}
                  src="/icons/arrow_down.svg"
                />
              </Grid2>
            </Grid2>
          </Grid2>
        </Tooltip>
      )}
    </Grid2>
  );
}
