import React from "react";

import { Grid2 } from "@mui/material";
import CompanyCard from "components/CompanyCard";
import { CompanyCardProps } from "components/CompanyCard/CompanyCard";

import styles from "./CompanyCardsRowStyles";

type CompanyCardsRowProps = {
  cards: CompanyCardProps[];
};

export default function CompanyCardsRow({ cards }: CompanyCardsRowProps) {
  return (
    <Grid2 className={styles.container} container spacing={2} wrap="nowrap">
      {cards.map((card) => (
        <Grid2 key={card.company.id}>
          <CompanyCard {...card} />
        </Grid2>
      ))}
    </Grid2>
  );
}
