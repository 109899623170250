import { useContext, useEffect, useMemo } from "react";
import { useQueryClient } from "react-query";
import { useLocation } from "react-router-dom";
import queryKeys from "reactQuery/queryKeys";

import {
  QuestionDto,
  RfpDto,
  RfpResponseAnalysisStatus,
  RfpResponseDto,
  RfpResponseDtoStatus,
  RfpResponseEvaluationStatus,
} from "@pulsemarket/api-client";
import { FeatureFlagEnum, QuestionType, RFPType } from "@pulsemarket/constants";
import { utilityColors } from "@pulsemarket/constants";
import { TabItem } from "components/ui/layout/TabbedPage/TabbedPage";
import SimpleModalBody from "components/ui/Modal/SimpleModalBody/SimpleModalBody";
import ModalContext from "contexts/ModalContext/ModalContext";
import useRFPresponses from "hooks/queries/useRFPResponses";
import useFeatureEnabled from "hooks/useFeatureEnabled";
import { EditorAction } from "pages/RFPEditorPage/useEditorContext";
import { getMessageDetails } from "shared/client";
import {
  DynamicRfpResponseStatus,
  RfpInvitationStatus,
  RFPStatus,
} from "shared/model";
import { style } from "typestyle";

export enum ViewerType {
  Buyer = 0,
  Supplier = 1,
}

export enum RFPEditorTab {
  // The empty string will be used in the URL
  general = "",
  /** Tab for managing invitations to a RFP */
  invitations = "invitations",
  /** Tab for managing evaluation statuses of invitations */
  evaluation = "evaluation",
  comparison = "comparison",
  analytical_review = "analytical_review",
  completed = "completed",
}

// todo remove these constatns and use the enum
export const TAB_INVITATIONS = RFPEditorTab.invitations;
export const TAB_EVALUATION = RFPEditorTab.evaluation;
export const TAB_COMPARISON = RFPEditorTab.comparison;
export const TAB_ANALYTICAL_REVIEW = RFPEditorTab.analytical_review;
export const TAB_COMPLETED = RFPEditorTab.completed;

/**
 * todo Just a local type (?)
 */
export type RfpInvitationInfo = {
  id?: string;
  invitedCompanyId?: string;
  invitedCompanyName?: string;
  contactName?: string;
  contactJobTitle?: string;
  contactEmail?: string;
  status: RfpInvitationStatus;
  evaluationStatus?: RfpResponseEvaluationStatus;
  analysisStatus?: RfpResponseAnalysisStatus;
  invitationId?: string;
  responseId?: string;
};

type ScrollTarget =
  | "module"
  | "questionnaire"
  | "question"
  | "answer"
  | "module-card";

function defaultOrValue<T>(defaultValue: T, value?: T): T {
  return typeof value !== "undefined" ? value : defaultValue;
}

export const scrollTo = (
  target: ScrollTarget,
  id: string,
  scrollOptions?: Exclude<
    Parameters<HTMLElement["scrollIntoView"]>[0],
    boolean
  > & {
    forced?: boolean;
  }
) => {
  const node = document.getElementById(`${target}-${id}`);
  const parent = document.getElementById(`editor-body`);

  if (target === "module") {
    scrollTo("module-card", id, { ...scrollOptions, forced: true });
  }

  if (node && ((parent && !isVisible(node, parent)) || scrollOptions?.forced)) {
    node.scrollIntoView({
      ...scrollOptions,
      behavior: defaultOrValue(
        target === "module-card" ? "instant" : "smooth",
        scrollOptions?.behavior
      ),
      block: defaultOrValue("start", scrollOptions?.block),
      inline: defaultOrValue("start", scrollOptions?.inline),
    });
  }
};

const isVisible = function (ele: Element, container: Element) {
  const { bottom, height, top } = ele.getBoundingClientRect();
  const containerRect = container.getBoundingClientRect();

  return top <= containerRect.top + 60
    ? containerRect.top - top <= height
    : bottom - containerRect.bottom <= height;
};

export const tabsWithTables: RFPEditorTab[] = [
  RFPEditorTab.invitations,
  RFPEditorTab.evaluation,
  RFPEditorTab.analytical_review,
  RFPEditorTab.completed,
];

export function useTabs({
  isResponse,
  rfp,
  rfpResponseData,
  rfpType,
}: {
  isResponse: boolean;
  rfpType: RFPType;
  rfpResponseData?: RfpResponseDto;
  rfp?: RfpDto;
}) {
  const { data: evaluationFeatureEnabled } = useFeatureEnabled({
    featureName: FeatureFlagEnum.evaluationSettings,
  });

  const { data: { responses } = {} } = useRFPresponses({
    rfpId: rfp?.id,
  });

  return useMemo<TabItem[]>(() => {
    return [
      {
        id: RFPEditorTab.general,
        text: !isResponse ? rfpType : rfpResponseData?.status ?? "New",
      },
      {
        id: TAB_INVITATIONS,
        text: "Invitations",
        disabled:
          !rfp?.status ||
          ![
            RFPStatus.Distributed,
            RFPStatus.AnalyticalReview,
            RFPStatus.Completed,
          ].includes(rfp?.status),
        hidden: isResponse,
      },
      {
        id: TAB_EVALUATION,
        text: "Evaluation",
        disabled:
          !rfp?.status ||
          !responses?.length ||
          ![
            RFPStatus.Distributed,
            RFPStatus.AnalyticalReview,
            RFPStatus.Completed,
          ].includes(rfp?.status),
        hidden: isResponse || !evaluationFeatureEnabled,
      },
      {
        id: TAB_COMPARISON,
        text: "Comparison",
        disabled:
          !rfp?.status ||
          !responses?.length ||
          ![
            RFPStatus.Distributed,
            RFPStatus.AnalyticalReview,
            RFPStatus.Completed,
          ].includes(rfp?.status),
        hidden: isResponse,
      },
      {
        id: TAB_ANALYTICAL_REVIEW,
        text: "Analytical Review",
        disabled:
          !rfp?.status ||
          ![RFPStatus.AnalyticalReview, RFPStatus.Completed].includes(
            rfp?.status
          ),
        hidden: isResponse,
      },
      {
        id: TAB_COMPLETED,
        text: "Completed",
        disabled: RFPStatus.Completed !== rfp?.status,
        hidden: isResponse,
      },
    ];
  }, [
    isResponse,
    rfpType,
    rfpResponseData?.status,
    rfp?.status,
    evaluationFeatureEnabled,
    responses?.length,
  ]);
}

export type PropertyCardTabName =
  | "Message"
  | "Score"
  | "Overview"
  | "Supporting Info";

export function useUrlHashBasedNavigation({
  dispatch,
}: {
  dispatch: React.Dispatch<EditorAction>;
}) {
  const location = useLocation();
  const client = useQueryClient();

  useEffect(() => {
    async function manageNavigationToMessage(messageId: string) {
      const messageDetails = await client.fetchQuery(
        queryKeys.messageDetails(messageId),
        () => {
          return getMessageDetails(messageId);
        }
      );
      dispatch({ type: "selectPropertyCardTab", payload: { tab: "Message" } });
      dispatch({
        type: "selectModuleId",
        payload: { moduleId: messageDetails.moduleId },
      });
      scrollTo("module", messageDetails.moduleId);
    }

    function manageNavigationToQuestion(questionId: string) {
      dispatch({
        type: "selectQuestionId",
        payload: { questionId },
      });
      scrollTo("question", questionId, { forced: true, behavior: "instant" });
    }

    if (location.hash.startsWith("#message-")) {
      const messageId = location.hash.split("-")[1];
      manageNavigationToMessage(messageId);
    }

    if (location.hash.startsWith("#question-")) {
      const questionId = location.hash.split("-")[1];
      manageNavigationToQuestion(questionId);
    }
  }, [client, dispatch, location.hash]);
}

export function useInformDueDatePassedForResponse({
  isSupplierView,
  isExpired,
  responseStatus,
}: {
  isSupplierView: boolean;
  isExpired: boolean;
  responseStatus?: RfpResponseDtoStatus;
}) {
  const { openModal } = useContext(ModalContext);
  useEffect(() => {
    if (
      isSupplierView &&
      isExpired &&
      responseStatus !== DynamicRfpResponseStatus.Submitted
    ) {
      openModal(
        <SimpleModalBody
          title="Submission deadline passed"
          buttons={[
            { children: "Ok, thanks", variant: "normal", color: "orange" },
          ]}
        >
          Unfortunately, the due date for adding your answers has passed and
          submissions are no longer accepted for this period. If you wish to
          request an extension, please contact{" "}
          <a
            className={style({ color: utilityColors.ORANGE })}
            href="mailto:clientservices@pulsemarket.com"
          >
            clientservices@pulsemarket.com
          </a>
        </SimpleModalBody>
      );
    }
  }, [isSupplierView, isExpired, responseStatus, openModal]);
}

export function isQuestionSingleOrMultiChoice(
  question: Pick<QuestionDto, "type">
) {
  return (
    question.type === QuestionType.SINGLE_CHOICE ||
    question.type === QuestionType.MULTI_CHOICE
  );
}
