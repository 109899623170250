import { neutralColors, primaryColors } from "@pulsemarket/constants";
import { px } from "csx";
import { clickable } from "shared/styles/classes";
import { typography } from "shared/styles/typography";
import { stylesheet } from "typestyle";

const styles = stylesheet({
  multiSelectList: {
    display: "flex",
    overflow: "hidden",
    flexDirection: "column",
    alignItems: "stretch",
  },
  listWrapper: {
    borderRadius: px(8),
    background: neutralColors.GRAY0,
    boxSizing: "border-box",
  },
  listHeader: {
    display: "flex",
    alignItems: "center",
    backgroundColor: primaryColors.WHITE,
    height: "40px",
    border: `${px(1)} solid ${neutralColors.GRAY3}`,
    borderRadius: `${px(8)} ${px(8)} ${px(0)} ${px(0)}`,
    boxSizing: "border-box",
    color: neutralColors.GRAY5,
    justifyContent: "space-between",
    ...clickable,
  },
  closedHeader: {
    borderRadius: "8px",
  },
  listHeaderText: {
    margin: `${px(0)} ${px(15)}`,
    color: neutralColors.GRAY4,
  },
  list: {
    border: `${px(1)} solid ${neutralColors.GRAY3}`,
    flex: 1,
    overflow: "auto",
    maxHeight: px(126),
    overflowX: "hidden",
  },
  actions: {
    flexDirection: "row-reverse",
    paddingTop: "12px",
    paddingBottom: "12px",
    paddingLeft: "8px",
    paddingRight: "8px",
  },
  buttonLabel: {
    margin: `${px(0)} ${px(8)}`,
  },
  closeIcon: {
    margin: px(8),
    width: px(13),
    color: neutralColors.GRAY5,
    textAlign: "center",
  },
  avatar: {
    width: "27px",
    height: "27px",
    padding: "2px",
    marginRight: "16px",
    border: `1px solid ${neutralColors.GRAY3}`,
  },
  disabledReason: {
    ...typography.CAPTION3,
    color: neutralColors.GRAY3,
  },
  arrowIcon: {
    margin: px(8),
    width: px(13),
    color: neutralColors.GRAY5,
    textAlign: "center",
    marginLeft: "auto",
    marginRight: "0px",
  },
});

export default styles;
