import { AssessmentAccessType } from "@pulsemarket/api-client";
import { AttributeModelType, RFPType, TokenType } from "@pulsemarket/constants";
import {
  AssetSearchOptions,
  AttributeSchemaQuery,
  CompanyFilter,
  ContainerType,
  DataRoomFilters,
  GroupFilesFilters,
  PassportInvitationFilter,
  PostFilters,
  QuestionFilter,
  ReceivedResponseFilters,
  RfpFilters,
  RfpInvitationFilters,
  RfpResponseFilters,
  SupplierGroupFilter,
} from "shared/model";

const queryKeys = {
  user: ["user"] as const,
  accessToken: ["accessToken"] as const,
  verifyToken: (token: string, type: TokenType) =>
    ["verifyToken", token, type] as const,
  verifyEmail: (token: string) => ["verifyEmail", token] as const,
  stripeKey: ["stripeKey"] as const,
  ownCompany: ["ownCompany"] as const,
  inviterCompany: (companyId?: string) =>
    ["inviterCompany", companyId] as const,
  company: (companyId?: string) => ["company", companyId] as const,
  mySuppliers: (companyId: string) => ["mySuppliers", companyId] as const,
  supplierDetails: (supplierId: string) =>
    ["supplierDetails", supplierId] as const,
  supplierAttachments: (supplierId: string) =>
    ["supplierAttachments", supplierId] as const,
  supplierContracts: (supplierId: string) =>
    ["supplierContracts", supplierId] as const,
  supplierProducts: (supplierId: string) =>
    ["supplierProducts", supplierId] as const,
  supplierGroups: (filters: SupplierGroupFilter) =>
    ["supplierGroups", filters] as const,
  supplierContract: (contractId?: string) =>
    ["supplierContract", contractId] as const,
  supplierProduct: (productId?: string) =>
    ["supplierProduct", productId] as const,
  companyMemberInvitation: (invitationToken: string) =>
    ["companyMemberInvitation", invitationToken] as const,
  industries: ["industries"] as const,
  employeeNumbers: ["employeeNumbers"] as const,
  zones: ["zones"] as const,
  roles: ["roles"] as const,
  passports: ["passports"] as const,
  ownPassports: ["ownPassports"] as const,
  sentPassportInvitations: (filters?: PassportInvitationFilter) =>
    ["sentPassportInvitations", filters] as const,
  countries: ["countries"] as const,
  specialities: ["specialities"] as const,
  sectors: ["sectors"] as const,
  standardizedQuestionnaires: (type: RFPType | null) =>
    ["standardizedQuestionnaires", type] as const,
  passportPdf: (passportBlobId: string) =>
    ["passportPdf", passportBlobId] as const,
  companies: (filters: CompanyFilter) => ["companies", filters] as const,
  companyList: (listId: string) => ["companyList", listId] as const,
  allFolders: ["allFolders"] as const,
  assetLabels: ["assetLabels"] as const,
  dataRooms: (filters?: DataRoomFilters) => ["dataRooms", filters] as const,
  dataRoom: (dataRoomId?: string | null) => ["dataRoom", dataRoomId] as const,
  dataRoomVideos: (dataRoomId?: string | null) =>
    ["dataRoomVideos", dataRoomId] as const,
  folderPath: (folderId: string) => ["folderPath", folderId] as const,
  companyMembers: (companyId: string) => ["companyMembers", companyId] as const,
  dataRoomInvitations: (dataRoomId: string) =>
    ["dataRoomInvitations", dataRoomId] as const,
  newAnswers: (responseId?: string) => ["answers", responseId],
  answer: (answerId?: string | null) => ["answer", answerId],
  answersByRfxId: (rfxId: string) => ["answersByRfxId", rfxId] as const,
  answerAttachments: (folderId: string) =>
    ["answerAttachments", folderId] as const,
  blobUrl: (assetId: string) => ["blobUrl", assetId] as const,
  blobUrls: (assetIds: string[]) => ["blobUrls", assetIds] as const,
  assetRepositorySearch: (searchOptions: AssetSearchOptions) =>
    ["assetRepositorySearch", searchOptions] as const,
  youTubeVideo: (id: string) => ["youTubeVideo", id] as const,
  rfps: (searchTerm?: RfpFilters) => ["rfps", searchTerm] as const,
  rfpsByInvitation: (filters?: RfpResponseFilters) =>
    ["rfpsByInvitation", filters] as const,
  supplierRfps: (filters?: RfpResponseFilters) =>
    ["supplierRfps", filters] as const,
  invitationsByRfp: (rfpId?: string, filters?: RfpInvitationFilters) =>
    ["invitationsByRfp", rfpId, filters] as const,
  invitationByRfpId: (rfpId?: string) => ["invitationByRfpId", rfpId] as const,
  rfpInvitation: (invitationId?: string) =>
    ["invitation", invitationId] as const,
  rfp: (rfpId?: string) => ["rfp", rfpId] as const,
  rfpWeights: (rfpId?: string) => ["rfpWeights", rfpId] as const,
  rfpScoreSettings: (rfpId: string) => ["rfpScoreSettings", rfpId] as const,
  rfpScoreSettingGroups: (rfpId: string) =>
    ["rfpScoreSettingGroups", rfpId] as const,
  role: (companyId?: string, userId?: string) =>
    ["role", companyId, userId] as const,
  companyRoles: (companyId: string) => ["companyRoles", companyId] as const,
  rfpResponse: (rfpResponseId?: string | null) =>
    ["rfpResponse", rfpResponseId] as const,
  rfpResponseEvaluationDetails: (rfpResponseId: string) =>
    ["rfpResponseEvaluationDetails", rfpResponseId] as const,
  rfpResponses: (companyId: string, searchTerm?: string) =>
    ["rfpResponses", companyId, searchTerm] as const,
  rfpResponsesByRfpId: (
    rfpId?: string,
    filters: ReceivedResponseFilters = {}
  ) => ["rfpResponsesByRfpId", rfpId, filters] as const,
  rfpModules: (rfpId: string) => ["rfpModules", rfpId] as const,
  answers: (companyId: string, questionIds: string[]) =>
    ["answers", companyId, questionIds] as const,
  fileItem: (
    companyId?: string,
    blobId?: string,
    containerType?: ContainerType
  ) => ["fileItem", companyId, blobId, containerType] as const,
  rfpRecipients: (rfpId: string) => ["rfpRecipients", rfpId] as const,
  userRelatedCompanies: ["userRelatedCompanies"] as const,
  features: (featureFlagName: string) => ["features", featureFlagName] as const,
  ownGroups: (companyId: string) => ["ownGroups", companyId] as const,
  groupsImMember: () => ["groupsImMember"] as const,
  group: (groupId: string) => ["group", groupId] as const,
  groupFiles: (groupId?: string, filters?: GroupFilesFilters) =>
    ["groupFiles", groupId, filters] as const,
  groups: ["groups"] as const,
  requestGroup: (groupId: string) => ["requestGroup", groupId] as const,
  groupInvitations: (groupId?: string) =>
    ["groupInvitations", groupId] as const,
  notifications: (companyId?: string) => ["notifications", companyId] as const,
  recentNotifications: (companyId?: string) =>
    ["recentNotifications", companyId] as const,
  posts: (groupId?: string, filters?: PostFilters) =>
    ["posts", groupId, filters] as const,
  comments: (postId?: string) => ["comments", postId] as const,
  messageDetails: (messageId?: string) =>
    ["messageDetails", messageId] as const,
  userPreviews: (companyId?: string) => ["userPreviews", companyId] as const,
  postReactions: (postId?: string) => ["postReactions", postId] as const,
  attributeSchema: (
    companyId: string,
    modelType: AttributeModelType,
    filters?: AttributeSchemaQuery
  ) => ["attributeSchema", companyId, modelType, filters] as const,
  lookupTableGroups: (companyId: string, modelType: AttributeModelType) =>
    ["attributeSchema", companyId, modelType] as const,
  lookupTable: (tableId: string) => ["lookupTable", tableId] as const,
  attributeType: (attributeTypeId: string) =>
    ["attributeType", attributeTypeId] as const,
  certificate: (assetId: string) => ["certificate", assetId] as const,
  matrices: (companyId: string) => ["matrices", companyId] as const,
  matrix: (matrixId?: string) => ["matrix", matrixId] as const,
  matrixDataByAnswerId: (answerId?: string) =>
    ["matrixDataByAnswerId", answerId] as const,
  matrixDataByMatrixId: (matrixId?: string) =>
    ["matrixDataByMatrixId", matrixId] as const,
  csrdImpactRows: ["csrdImpactRows"] as const,
  csrdDocuments: (assessmentAccessType: AssessmentAccessType) =>
    ["csrdDocuments", assessmentAccessType] as const,
  csrdFolders: ["csrdFolders"] as const,
  questions: (filters: QuestionFilter) => ["questions", filters] as const,
  questionLabels: ["questionLabels"] as const,
  questionLabel: (labelId?: string) => ["questionLabel", labelId] as const,
  dashboardItems: ["dashboardItems"] as const,
};

export default queryKeys;
