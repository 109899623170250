import React from "react";

import { Checkbox } from "@mui/material";
import cardStyles from "components/ui/Card/Card.styles";
import { SelectableComponentProps } from "shared/utils/componentTypes";
import { classes } from "typestyle";

import styles from "./TallRow.styles";

/*
  Based on: 
  - https://www.figma.com/file/jNcVZ1cGik4UVFYARAAsQr/Version-2.0?node-id=2033%3A107970&viewport=241%2C48%2C0.25
  - https://www.figma.com/file/jNcVZ1cGik4UVFYARAAsQr/Version-2.0?node-id=4499%3A179340&viewport=241%2C48%2C0.25
  - https://www.figma.com/file/jNcVZ1cGik4UVFYARAAsQr/Version-2.0?node-id=2%3A21624&viewport=241%2C48%2C0.66


  Requirements:
  - Full width
  - white background
  - the content is very dynamic
  - Selectable
*/

type TallRowProps<T> = {
  className?: string;
  children?: React.ReactNode;
} & SelectableComponentProps<T>;

export default function TallRow<T>({
  className,
  children,
  onToggleSelect,
  selected,
  disabled,
}: TallRowProps<T>) {
  return (
    <div className={classes(styles.root, className)}>
      {onToggleSelect && (
        <Checkbox
          size="small"
          className={classes(cardStyles.checkbox, styles.checkboxPosition)}
          checked={Boolean(selected)}
          onChange={(e) => onToggleSelect(e.target.checked)}
          disabled={typeof disabled === "function" ? disabled() : disabled}
        />
      )}
      {children}
    </div>
  );
}
