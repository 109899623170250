import React from "react";

import {
  IconButton as MuiIconButton,
  IconButtonProps as MuiIconButtonProps,
} from "@mui/material";
import SvgIcon from "components/ui/SvgIcon";
import Tooltip, {
  purpleTooltipProps,
  TooltipWrapper,
} from "components/ui/Tooltip";
import { classes } from "typestyle";

import styles, {
  largeVariantStyles,
  smallVariantStyles,
} from "./IconButton.styles";

type IconButtonColor = "black" | "orange" | "purple" | "green" | "gray";

export type IconButtonProps = Omit<MuiIconButtonProps, "color" | "size"> & {
  tooltip: string;
  src: string;
  iconClassname?: string;
  size?: "small" | "medium" | "large";
  color?: IconButtonColor;
};

const colorStyles: Record<IconButtonColor, string> = {
  black: styles.black,
  orange: styles.orange,
  gray: styles.gray,
  green: styles.green,
  purple: styles.purple,
};

export default function IconButton({
  className,
  src,
  tooltip,
  iconClassname,
  size,
  color,
  ...props
}: IconButtonProps) {
  const isLarge = size === "large";
  const muiSize: MuiIconButtonProps["size"] = isLarge ? undefined : size;

  return (
    <Tooltip title={tooltip} {...purpleTooltipProps}>
      {/* Wrapper added so that the Tooltip appears when button is disabled */}
      <TooltipWrapper>
        <MuiIconButton
          {...props}
          size={muiSize}
          classes={{
            ...props.classes,
            sizeSmall: classes(
              smallVariantStyles.button,
              props.classes?.sizeSmall
            ),
          }}
          className={classes(
            styles.button,
            isLarge && largeVariantStyles.button,
            colorStyles[color || "black"],
            className
          )}
        >
          <SvgIcon src={src} className={classes(styles.icon, iconClassname)} />
        </MuiIconButton>
      </TooltipWrapper>
    </Tooltip>
  );
}
