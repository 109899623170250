import React from "react";
import { Link } from "react-router-dom";

import { Divider } from "@mui/material";
import { Loading } from "components/FullScreenLoading";
import CompanyListItem from "components/VendorSearch/Sidebar/CompanyListItem";
import { useVendorSearchContext } from "components/VendorSearch/VendorFilterProvider";
import { SUPPLIER_SEARCH_URL_PATH } from "components/VendorSearch/VendorSearch";
import useOwnCompany from "hooks/queries/useOwnCompany";
import { classes } from "typestyle";

import styles from "./SidebarStyles";

export enum VendorSearchSideMenuItem {
  Vendors = "Vendors",
  CompanyLists = "CompanyLists",
  Groups = "Groups",
  News = "News", //To be implemented later
}

export const GROUP_DISCOVER_URL_PATH = "/discovergroups";

export default function VendorSearchSidebar({
  activeTab,
}: {
  activeTab: VendorSearchSideMenuItem;
}) {
  const { companyListId, filters, setFilters, hiddenFeatures } =
    useVendorSearchContext();
  const { data: ownCompany, isLoading: loading } = useOwnCompany();

  return (
    <div className={styles.root}>
      <div>
        <Link
          to={SUPPLIER_SEARCH_URL_PATH}
          onClick={() => {
            setFilters({ ...filters, companyListId: undefined });
          }}
        >
          <div
            className={classes(
              styles.menuItem,
              activeTab === VendorSearchSideMenuItem.Vendors &&
                styles.selectedMenuItem
            )}
          >
            <h2 className={styles.title}>Supplier Search</h2>
          </div>
        </Link>
        {!hiddenFeatures.includes("discover_groups") && (
          <Link
            to={GROUP_DISCOVER_URL_PATH}
            onClick={() => {
              setFilters({});
            }}
          >
            <div
              className={classes(
                styles.menuItem,
                activeTab === VendorSearchSideMenuItem.Groups &&
                  styles.selectedMenuItem
              )}
            >
              <h2 className={styles.title}>Discover Groups</h2>
            </div>
          </Link>
        )}
      </div>
      <Divider className={styles.divider} />
      <div className={styles.lists}>
        {ownCompany!.companyLists.length > 0 ? (
          <div className={classes(styles.menuItem)}>
            <h2 className={styles.title}>My Lists</h2>
          </div>
        ) : (
          <Link
            to="/vendorsearch/companylist"
            onClick={() => setFilters({ ...filters, companyListId: undefined })}
          >
            <div
              className={classes(
                styles.menuItem,
                styles.menuItemClickable,
                activeTab !== VendorSearchSideMenuItem.Groups &&
                  !companyListId &&
                  styles.selectedMenuItem
              )}
            >
              <h2 className={styles.title}>My Lists</h2>
            </div>
          </Link>
        )}
        {loading ? (
          <Loading />
        ) : (
          <div className={styles.ownLists}>
            {ownCompany!.companyLists.map((list) => {
              return (
                <div
                  className={classes(styles.menuItem, styles.menuItemClickable)}
                  key={list.id}
                >
                  <CompanyListItem list={list} />
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}
