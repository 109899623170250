import { useEffect } from "react";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";

import { ROUTES } from "@pulsemarket/constants";
import { Loading } from "components/FullScreenLoading";
import StripeCallbackScreen from "components/Stripe";
import { useGetAuthToken } from "hooks/queries/useAuth";
import { useIsComingFromInvitationRedirect } from "hooks/useIsComingFromInvitationRedirect";
import { SearchParamEnum } from "hooks/utils";
import AppRoutes from "layout/AppRoutes";
import Layout from "layout/Layout";
import ForgotPasswordPage from "pages/ForgotPassword/ForgotPassword";
import Login from "pages/Login";
import RegisterPage from "pages/Register/Register";
import ResetPasswordPage from "pages/ResetPassword";
import VerifyEmailPage from "pages/VerifyEmail/VerifyEmailPage";

import "./index.css";
import "draft-js/dist/Draft.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

// Hook to verify if the user is authenticated. If not, it will redirect to the login page.
function useCheckAuthToken() {
  const { data: token, isLoading: isTokenLoading, isError } = useGetAuthToken();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (isTokenLoading) {
      return;
    }
    if (isError || !token?.userId || !token?.companyId) {
      // User not authenticated and visits a page that requires authentication
      if (
        ![
          ROUTES.LOGIN,
          ROUTES.REGISTER,
          ROUTES.FORGOT_PASSWORD,
          ROUTES.RESET_PASSWORD,
          ROUTES.VERIFY_EMAIL,
        ].includes(location.pathname)
      ) {
        history.push(ROUTES.LOGIN);
      }
    } else {
      // If user is authenticated and visits a page that doesn't require authentication

      // If redirect url is saved to local storage, redirect to that url
      if (localStorage.getItem(SearchParamEnum.REDIRECT_PAGE_PARAM)) {
        history.replace(
          localStorage.getItem(SearchParamEnum.REDIRECT_PAGE_PARAM) || "/"
        );
        localStorage.removeItem(SearchParamEnum.REDIRECT_PAGE_PARAM);
      } else if (
        [
          ROUTES.LOGIN,
          ROUTES.REGISTER,
          ROUTES.FORGOT_PASSWORD,
          ROUTES.RESET_PASSWORD,
          ROUTES.VERIFY_EMAIL,
        ].includes(location.pathname)
      ) {
        history.push("/");
      }
    }
  }, [history, isError, isTokenLoading, token, location.pathname]);
}

export function App() {
  const { isLoading } = useIsComingFromInvitationRedirect();

  useCheckAuthToken();

  if (isLoading) {
    return <Loading isWindowed />;
  }

  return (
    <Switch>
      <Route path="/stripe/:status" exact component={StripeCallbackScreen} />

      <Route path={ROUTES.LOGIN} exact>
        <Login />
      </Route>
      <Route path={ROUTES.REGISTER} exact>
        <RegisterPage />
      </Route>
      <Route path={ROUTES.FORGOT_PASSWORD} exact>
        <ForgotPasswordPage />
      </Route>
      <Route path={ROUTES.RESET_PASSWORD} exact>
        <ResetPasswordPage />
      </Route>

      <Route path={ROUTES.VERIFY_EMAIL} exact>
        <VerifyEmailPage />
      </Route>

      <Layout>
        <AppRoutes />
      </Layout>
    </Switch>
  );
}
