import { useQuery } from "react-query";
import queryKeys from "reactQuery/queryKeys";
import { longCachedQueryOptions } from "reactQuery/util";

import useRoles from "hooks/queries/useRoles";
import useUserData from "hooks/queries/useUserData";
import { ApiError, getCompany, getCompanyRolesByUserId } from "shared/client";

export function useUserCompanies() {
  const { data: userData } = useUserData();
  const { data: roles } = useRoles();

  return useQuery(
    queryKeys.userRelatedCompanies,
    async () => {
      const userCompanyRoles = await getCompanyRolesByUserId(userData!.id);

      const companies = await Promise.all(
        userCompanyRoles.map(async (curr) => {
          const company = await getCompany(curr.companyId);
          return {
            ...company,
            roleId: curr.roleId,
            roleName: roles![curr.roleId].name,
          };
        })
      );

      return companies;
    },
    {
      retry: (_, error) => {
        console.log(error);
        if (error instanceof ApiError) {
          if (error.status === 404) {
            return false;
          }
        }
        return true;
      },
      enabled: !!userData?.id && !!roles,
      ...longCachedQueryOptions,
    }
  );
}
