import React, { useEffect } from "react";

import { Tab, Tabs } from "@mui/material";

import TabPanel from "./TabPanel";

import styles from "./TabsStyles";

interface TabsProps {
  tabs: string[];
  children: React.ReactNode[];
  openTab?: number;
}

export default function OldTabs({ children, tabs, openTab }: TabsProps) {
  const [value, setValue] = React.useState(openTab ? openTab : 0);

  useEffect(() => {
    if (openTab) {
      setValue(openTab);
    }
  }, [openTab]);
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      <div className={styles.mainRoot}>
        <div>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="pulse-page-tab"
            classes={{
              root: styles.tabsRoot,
              indicator: styles.indicator,
              scroller: styles.scroller,
            }}
          >
            {tabs.map((tab, index) => (
              <Tab
                classes={{
                  root: styles.tabRoot,
                  selected: styles.selectedTab,
                }}
                disableRipple
                label={tab}
                key={index}
              />
            ))}
          </Tabs>
        </div>
      </div>
      {children.map((child: React.ReactNode, index: number) => (
        <TabPanel key={index} value={value} index={index}>
          {child}
        </TabPanel>
      ))}
    </>
  );
}
