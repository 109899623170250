import { useMutation, useQuery, useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import mutationKeys from "reactQuery/mutationKeys";
import queryKeys from "reactQuery/queryKeys";

import { ROUTES, TokenType } from "@pulsemarket/constants";
import { useSnackBar } from "contexts/Snackbar/SnackbarProvider";
import { LocalStorageKey } from "hooks/utils";
import {
  forgotPassword,
  getInviterCompany,
  logIn,
  logOut,
  register,
  renewToken,
  resendVerifyEmail,
  resetPassword,
  verifyEmail,
  verifyToken,
} from "shared/client";
import { ACTING_COMPANY_ID } from "shared/constants/localstorage";
import { RegistrationInput } from "shared/model";

// todo rename to distribute and move to rfphooks + add onError
export function useLogIn() {
  const client = useQueryClient();

  return useMutation(
    ({ email, password }: { email: string; password: string }) => {
      return logIn({ params: { email, password } });
    },
    {
      mutationKey: mutationKeys.login,
      onSettled: async (data, _error, _variables) => {
        if (data) {
          localStorage.setItem(ACTING_COMPANY_ID, data.companyId);

          await client.invalidateQueries(queryKeys.user);
          await client.invalidateQueries(queryKeys.ownCompany);
        }
      },
    }
  );
}

export function useLogOut() {
  const history = useHistory();
  const client = useQueryClient();

  return useMutation(
    () => {
      return logOut();
    },
    {
      mutationKey: mutationKeys.logout,
      onSettled: async () => {
        localStorage.removeItem(ACTING_COMPANY_ID);

        client.removeQueries();
        history.push(ROUTES.LOGIN);
      },
    }
  );
}

export function useGetAuthToken() {
  const client = useQueryClient();
  return useQuery(queryKeys.accessToken, () => renewToken(), {
    retry: false,
    refetchInterval: 1000 * 60 * 15, // 15 minutes
    onSuccess: async (data) => {
      if (data) {
        localStorage.setItem(ACTING_COMPANY_ID, data.companyId);
        await client.invalidateQueries(queryKeys.user);
        await client.invalidateQueries(queryKeys.ownCompany);
      }
    },
  });
}

export function useRegister() {
  return useMutation(
    (params: RegistrationInput) => {
      return register(params);
    },
    {
      mutationKey: mutationKeys.register,
    }
  );
}

export function useForgotPassword() {
  return useMutation(
    (email: string) => {
      return forgotPassword(email);
    },
    {
      mutationKey: mutationKeys.forgotPassword,
    }
  );
}

export function useResetPassword() {
  return useMutation(
    ({ password, token }: { password: string; token: string }) => {
      return resetPassword({ password, token });
    },
    {
      mutationKey: mutationKeys.resetPassword,
    }
  );
}

export function useResendVerifyEmail() {
  return useMutation(
    (email: string) => {
      return resendVerifyEmail(email);
    },
    {
      mutationKey: mutationKeys.resendVerifyEmail,
    }
  );
}

export function useVerifyToken({
  token,
  type,
  enabled,
}: {
  token?: string;
  type: TokenType;
  enabled: boolean;
}) {
  return useQuery(
    queryKeys.verifyToken(token || "", type),
    () => verifyToken({ token: token || "", type }),
    {
      enabled: !!token && (typeof enabled === "boolean" ? enabled : true),
      retry: false,
    }
  );
}

export function useVerifyEmail({
  token,
  enabled,
}: {
  token?: string;
  enabled: boolean;
}) {
  const history = useHistory();
  const client = useQueryClient();
  return useQuery(
    queryKeys.verifyEmail(token || ""),
    () => verifyEmail({ token: token || "" }),
    {
      enabled: !!token && (typeof enabled === "boolean" ? enabled : true),
      retry: false,
      onSettled(data, error) {
        // Do not touch cache in callback if we need to show the Invite Team Members Page after email verification
        if (data) {
          history.push("/");
          localStorage.setItem(ACTING_COMPANY_ID, data.companyId);
          client.invalidateQueries(queryKeys.user);
          client.invalidateQueries(queryKeys.ownCompany);
        }
      },
    }
  );
}

export function useGetInviterCompany() {
  const { showSnackBar } = useSnackBar();
  const companyMemberInvitationToken = localStorage.getItem(
    LocalStorageKey.COMPANY_MEMBER_INVITATION_TOKEN
  );
  const inviterCompanyId =
    (!!companyMemberInvitationToken &&
      localStorage.getItem(LocalStorageKey.INVITER_COMPANY_ID)) ||
    "";

  return useQuery(
    queryKeys.inviterCompany(inviterCompanyId),
    () => getInviterCompany(companyMemberInvitationToken!),
    {
      enabled: !!inviterCompanyId,
      retry: false,
      onError: () => {
        showSnackBar({
          text: "The invitation link is invalid. Please contact the person who invited you.",
          typeColor: "error",
        });
        localStorage.removeItem(
          LocalStorageKey.COMPANY_MEMBER_INVITATION_TOKEN
        );
        localStorage.removeItem(LocalStorageKey.INVITER_COMPANY_ID);
      },
    }
  );
}
