import React from "react";

import { Grid2 } from "@mui/material";
import { GroupDto } from "@pulsemarket/api-client";
import CompanyCard from "components/CompanyCard";
import { CompanyCardProps } from "components/CompanyCard/CompanyCard";
import { DisplayType } from "components/DisplaySelector";
import JoinableGroupCard from "components/PulsePage/Groups/GroupCard/Cards/JoinableGroupCard";
import CompanyRow from "components/VendorSearch/CompanyRow";

import styles from "./CompaniesGridOrList.styles";

/** todo improve types - would simplyfy the component much too */
type CompaniesGridOrListProps = {
  type: "Company" | "Group";
  cards: CompanyCardProps[] | GroupDto[];
  display: DisplayType;
};

export default function CompaniesGridOrList({
  type = "Company",
  cards,
  display,
}: CompaniesGridOrListProps) {
  return (
    <Grid2
      className={styles.container}
      container
      spacing={display === DisplayType.ROWS ? 1 : 2}
      direction={display === DisplayType.ROWS ? "column" : undefined}
    >
      {cards.map((card) => {
        const isCards = display === DisplayType.CARDS;
        // ? configure custom breakpoints for cards
        return (
          <Grid2
            size={{
              xs: isCards ? 12 : undefined,
              lg: isCards ? 4 : undefined,
              xl: isCards ? (type === "Company" ? 3 : 4) : undefined,
            }}
            key={
              type === "Company"
                ? (card as CompanyCardProps).company.id
                : (card as GroupDto).id
            }
          >
            {isCards ? (
              type === "Company" ? (
                <CompanyCard {...(card as CompanyCardProps)} />
              ) : (
                <JoinableGroupCard group={card as GroupDto} />
              )
            ) : (
              <CompanyRow {...(card as CompanyCardProps)} />
            )}
          </Grid2>
        );
      })}
    </Grid2>
  );
}
