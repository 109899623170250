import React, { useRef } from "react";

import { ClickAwayListener, Popover } from "@mui/material";
import Button, { ButtonProps } from "components/ui/Button";
import { SelectOption } from "components/ui/FormSelect";
import SvgIcon from "components/ui/SvgIcon";
import useMultiSelect from "hooks/useMultiSelect";
import { getExpandableIconSource } from "shared/constants/icons";
import { Pair } from "shared/model";
import { classes, style } from "typestyle";

import Body from "./Body";

import styles from "./MultiSelectStyles";

export type MultiSelectProps = {
  label?: string;
  options: SelectOption[];
  selectedIds: string[];
  onChange?: (selectedIds: string[]) => void;
  onClose?: (selectedIds: string[]) => void;
  className?: string;
  isLoading?: boolean;
  disableSearch?: boolean;
  bodyClassName?: string;
  fullWidthList?: boolean;
  iconButton?: React.ReactNode;
  isSelectAllAllowed?: boolean;
  countHidden?: boolean;
  buttonClassName?: string;
  isFullWidhtButton?: boolean;
};

export interface CheckablePair extends Pair {
  checked: boolean;
}

export default function MultiSelect({
  label,
  options,
  selectedIds,
  onChange = () => {},
  onClose = () => {},
  className,
  isLoading,
  disableSearch,
  bodyClassName,
  fullWidthList,
  iconButton,
  isSelectAllAllowed,
  countHidden,
  buttonClassName,
  isFullWidhtButton = true,
}: MultiSelectProps) {
  const [popoverAnchorEl, setPopoverAnchorEl] =
    React.useState<HTMLElement | null>(null);

  const {
    setSearch,
    filteredItems,
    selectedItemsCount,
    items,
    clearChecked,
    toggleChecked,
    toggleAllChecked,
  } = useMultiSelect({
    onChange,
    options,
    selectedIds,
  });

  function openDropDown(e: React.MouseEvent<HTMLInputElement>) {
    setPopoverAnchorEl(e.currentTarget);
  }

  function closeDropDown() {
    setSearch("");
    setPopoverAnchorEl(null);
  }

  const containerEl = useRef<HTMLDivElement>(null);
  const containerWidth = containerEl?.current?.offsetWidth || 0;

  const popoverOpen = Boolean(popoverAnchorEl);

  return (
    <div
      onClick={openDropDown}
      className={classes(
        styles.MultiSelect,
        isFullWidhtButton && styles.fullWidth,
        className
      )}
    >
      <Button
        variant="transparent"
        color="gray"
        fullWidth
        className={classes(styles.button, buttonClassName)}
        ref={containerEl}
      >
        {(label || !countHidden) && (
          <div className={styles.text}>
            {label && <div className={styles.name}>{label}</div>}
            {selectedItemsCount > 0 && !countHidden && (
              <span className={styles.filterCount}>{selectedItemsCount}</span>
            )}
          </div>
        )}
        {iconButton || (
          <SvgIcon
            className={styles.arrowDown}
            src={getExpandableIconSource(!!popoverAnchorEl)}
          />
        )}
      </Button>
      {popoverOpen && (
        <Popover
          anchorEl={popoverAnchorEl}
          open={popoverOpen}
          elevation={0}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          onClose={() =>
            onClose(items.filter((item) => item.checked).map((item) => item.id))
          }
        >
          <ClickAwayListener onClickAway={closeDropDown}>
            <Body
              className={classes(
                bodyClassName,
                ...(fullWidthList ? style({ width: containerWidth }) : [])
              )}
              disableSearch={disableSearch}
              setSearch={setSearch}
              filteredItems={filteredItems}
              toggleChecked={toggleChecked}
              isLoading={isLoading}
              buttons={
                isSelectAllAllowed
                  ? [
                      {
                        variant: "transparent",
                        color: "orange",
                        onClick: () => {
                          if (selectedItemsCount === items.length) {
                            clearChecked();
                          } else {
                            toggleAllChecked();
                          }
                        },
                        children: <span>Select all</span>,
                      } as ButtonProps,
                    ]
                  : [
                      {
                        variant: "transparent",
                        className: styles.clearButton,
                        onClick: clearChecked,
                        children: (
                          <>
                            <SvgIcon
                              className={styles.closeIcon}
                              src="/icons/close_icon.svg"
                            />
                            <span className={styles.clearButton}>Clear</span>
                          </>
                        ),
                      } as ButtonProps,
                    ]
              }
            />
          </ClickAwayListener>
        </Popover>
      )}
    </div>
  );
}
